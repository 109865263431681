// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_Int from "rescript/lib/es6/belt_Int.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";

var ParseError = /* @__PURE__ */Caml_exceptions.create("ImportParserTypes.ParseError");

var ParseFormatError = /* @__PURE__ */Caml_exceptions.create("ImportParserTypes.ParseFormatError");

var ParseWarning = /* @__PURE__ */Caml_exceptions.create("ImportParserTypes.ParseWarning");

var getColumnValue = Belt_Array.getExn;

function getOptionalColumnValue(row, columnIndex) {
  return Belt_Option.flatMap(columnIndex, (function (param) {
                return Belt_Array.get(row, param);
              }));
}

var getColumnIndex = Belt_MapString.getExn;

var getOptionalColumnIndex = Belt_MapString.get;

function getOptionalRegexColumnNameAndIndex(columnIndexes, columnNameRegex) {
  var columnNames = Belt_MapString.keysToArray(columnIndexes);
  var regex = new RegExp(columnNameRegex);
  var matchingColumnName = Belt_Array.getBy(columnNames, (function (columnName) {
          return regex.test(columnName);
        }));
  return Belt_Option.flatMap(matchingColumnName, (function (columnName) {
                return Belt_Option.map(Belt_MapString.get(columnIndexes, columnName), (function (index) {
                              return [
                                      columnName,
                                      index
                                    ];
                            }));
              }));
}

function getColumnPresence(t) {
  switch (t.TAG | 0) {
    case /* Map */0 :
        return {
                NAME: "requiredColumnNames",
                VAL: [t._0]
              };
    case /* MapWithDefault */1 :
        return {
                NAME: "optionalColumnNames",
                VAL: [t._0]
              };
    case /* RegexMapWithDefault */2 :
        return {
                NAME: "optionalColumnNameRegex",
                VAL: [t._0]
              };
    case /* SkipWithDefault */3 :
        return "skip";
    
  }
}

function parse(row, _rowIndex, columnIndexes, operation) {
  switch (operation.TAG | 0) {
    case /* Map */0 :
        return Curry._1(operation._1, Belt_Array.getExn(row, Belt_MapString.getExn(columnIndexes, operation._0)));
    case /* MapWithDefault */1 :
        var map = operation._2;
        var defaultValue = operation._1;
        var columnIndex = Belt_MapString.get(columnIndexes, operation._0);
        return Belt_Option.mapWithDefault(Belt_Option.flatMap(columnIndex, (function (param) {
                          return Belt_Array.get(row, param);
                        })), [
                    defaultValue,
                    undefined
                  ], (function (value) {
                      if (value === "") {
                        return [
                                defaultValue,
                                undefined
                              ];
                      } else {
                        return Curry._1(map, value);
                      }
                    }));
    case /* RegexMapWithDefault */2 :
        var map$1 = operation._2;
        var defaultValue$1 = operation._1;
        var maybeColumnNameAndIndex = getOptionalRegexColumnNameAndIndex(columnIndexes, operation._0);
        if (maybeColumnNameAndIndex === undefined) {
          return [
                  defaultValue$1,
                  undefined
                ];
        }
        var columnName = maybeColumnNameAndIndex[0];
        return Belt_Option.mapWithDefault(Belt_Option.flatMap(maybeColumnNameAndIndex[1], (function (param) {
                          return Belt_Array.get(row, param);
                        })), [
                    defaultValue$1,
                    undefined
                  ], (function (value) {
                      if (value === "") {
                        return [
                                defaultValue$1,
                                undefined
                              ];
                      } else {
                        return Curry._2(map$1, columnName, value);
                      }
                    }));
    case /* SkipWithDefault */3 :
        return [
                operation._0,
                undefined
              ];
    
  }
}

var StringTupleOp = {
  getColumnPresence: getColumnPresence,
  parse: parse
};

function getColumnPresence$1(t) {
  switch (t.TAG | 0) {
    case /* ReadFromOptionalColumn */1 :
        return {
                NAME: "optionalColumnNames",
                VAL: [t._0]
              };
    case /* ReadAndConcat */2 :
        return {
                NAME: "requiredColumnNames",
                VAL: t._0
              };
    case /* SkipWithDefault */5 :
        return "skip";
    case /* ReadRequiredWithAnotherColumnConditional */6 :
        return {
                NAME: "requiredColumnNames",
                VAL: [
                  t._0,
                  t._1
                ]
              };
    case /* ReadOneOf */7 :
    case /* ReadFromOptionalColumnOneOf */8 :
        return {
                NAME: "optionalColumnNames",
                VAL: t._0
              };
    default:
      return {
              NAME: "requiredColumnNames",
              VAL: [t._0]
            };
  }
}

function parse$1(row, rowIndex, columnIndexes, operation) {
  switch (operation.TAG | 0) {
    case /* Read */0 :
        return Belt_Array.getExn(row, Belt_MapString.getExn(columnIndexes, operation._0));
    case /* ReadFromOptionalColumn */1 :
        var columnIndex = Belt_MapString.get(columnIndexes, operation._0);
        return Belt_Option.getWithDefault(Belt_Option.flatMap(columnIndex, (function (param) {
                          return Belt_Array.get(row, param);
                        })), "");
    case /* ReadAndConcat */2 :
        return Belt_Array.keepMap(operation._0, (function (columnName) {
                        var value = Belt_Array.getExn(row, Belt_MapString.getExn(columnIndexes, columnName));
                        if (value.trim() !== "") {
                          return columnName + (": " + (Belt_Array.getExn(row, Belt_MapString.getExn(columnIndexes, columnName)) + "\n"));
                        }
                        
                      })).join("\n");
    case /* ReadRequired */3 :
        var columnName = operation._0;
        var value = Belt_Array.getExn(row, Belt_MapString.getExn(columnIndexes, columnName));
        if (value === "") {
          throw {
                RE_EXN_ID: ParseWarning,
                _1: "" + columnName + " is required but is not provided in row " + String(rowIndex) + "",
                Error: new Error()
              };
        }
        return value;
    case /* Map */4 :
        return Curry._1(operation._1, Belt_Array.getExn(row, Belt_MapString.getExn(columnIndexes, operation._0)));
    case /* SkipWithDefault */5 :
        return operation._0;
    case /* ReadRequiredWithAnotherColumnConditional */6 :
        var columnName$1 = operation._0;
        var conditionalValue = Belt_Array.getExn(row, Belt_MapString.getExn(columnIndexes, operation._1));
        if (Curry._1(operation._2, conditionalValue)) {
          var value$1 = Belt_Array.getExn(row, Belt_MapString.getExn(columnIndexes, columnName$1));
          if (value$1 === "") {
            throw {
                  RE_EXN_ID: ParseWarning,
                  _1: "" + columnName$1 + " is required but is not provided in row " + String(rowIndex) + "",
                  Error: new Error()
                };
          }
          return value$1;
        }
        throw {
              RE_EXN_ID: ParseWarning,
              _1: "" + conditionalValue + " is not correct in row " + String(rowIndex) + "",
              Error: new Error()
            };
    case /* ReadOneOf */7 :
        var columnNames = operation._0;
        var maybeValue = Belt_Array.get(Belt_Array.keepMap(columnNames, (function (columnName) {
                    var columnIndex = Belt_MapString.get(columnIndexes, columnName);
                    return Belt_Option.flatMap(columnIndex, (function (param) {
                                  return Belt_Array.get(row, param);
                                }));
                  })), 0);
        if (maybeValue !== undefined) {
          return maybeValue;
        }
        throw {
              RE_EXN_ID: ParseWarning,
              _1: "one of [" + columnNames.join(", ") + "] is required but is not provided in row " + String(rowIndex) + "",
              Error: new Error()
            };
    case /* ReadFromOptionalColumnOneOf */8 :
        return Belt_Option.getWithDefault(Belt_Array.get(Belt_Array.keepMap(operation._0, (function (columnName) {
                              var columnIndex = Belt_MapString.get(columnIndexes, columnName);
                              return Belt_Option.flatMap(columnIndex, (function (param) {
                                            return Belt_Array.get(row, param);
                                          }));
                            })), 0), "");
    
  }
}

var StringOp = {
  getColumnPresence: getColumnPresence$1,
  parse: parse$1
};

function getColumnPresence$2(t) {
  switch (t.TAG | 0) {
    case /* MapBoolean */0 :
        return {
                NAME: "requiredColumnNames",
                VAL: [t._0]
              };
    case /* MapBooleanOneOf */1 :
        return {
                NAME: "optionalColumnNames",
                VAL: t._0
              };
    case /* SkipBoolean */2 :
        return "skip";
    
  }
}

function parse$2(row, rowIndex, columnIndexes, operation) {
  switch (operation.TAG | 0) {
    case /* MapBoolean */0 :
        return Curry._1(operation._1, Belt_Array.getExn(row, Belt_MapString.getExn(columnIndexes, operation._0)));
    case /* MapBooleanOneOf */1 :
        var columnNames = operation._0;
        var maybeValue = Belt_Array.get(Belt_Array.keepMap(columnNames, (function (columnName) {
                    var columnIndex = Belt_MapString.get(columnIndexes, columnName);
                    return Belt_Option.flatMap(columnIndex, (function (param) {
                                  return Belt_Array.get(row, param);
                                }));
                  })), 0);
        if (maybeValue !== undefined) {
          return Curry._1(operation._1, maybeValue);
        }
        throw {
              RE_EXN_ID: ParseWarning,
              _1: "one of [" + columnNames.join(", ") + "] is required but is not provided in row " + String(rowIndex) + "",
              Error: new Error()
            };
    case /* SkipBoolean */2 :
        return operation._0;
    
  }
}

var BoolOp = {
  getColumnPresence: getColumnPresence$2,
  parse: parse$2
};

function getColumnPresence$3(t) {
  switch (t.TAG | 0) {
    case /* Read */0 :
        return {
                NAME: "requiredColumnNames",
                VAL: [t._0]
              };
    case /* SkipWithDefault */1 :
        return "skip";
    case /* ReadWithDefault */2 :
        return {
                NAME: "optionalColumnNames",
                VAL: [t._0]
              };
    
  }
}

function parse$3(row, _rowIndex, columnIndexes, operation) {
  switch (operation.TAG | 0) {
    case /* Read */0 :
        return Belt_Option.getWithDefault(Belt_Int.fromString(Belt_Array.getExn(row, Belt_MapString.getExn(columnIndexes, operation._0))), 0);
    case /* SkipWithDefault */1 :
        return operation._0;
    case /* ReadWithDefault */2 :
        var columnIndex = Belt_MapString.get(columnIndexes, operation._0);
        return Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Option.flatMap(columnIndex, (function (param) {
                              return Belt_Array.get(row, param);
                            })), Belt_Int.fromString), operation._1);
    
  }
}

var IntOp = {
  getColumnPresence: getColumnPresence$3,
  parse: parse$3
};

function getColumnPresence$4(t) {
  switch (t.TAG | 0) {
    case /* MapStringArray */0 :
        return {
                NAME: "requiredColumnNames",
                VAL: [t._0]
              };
    case /* MapStringArrayFromOptionalColumn */1 :
        return {
                NAME: "optionalColumnNames",
                VAL: [t._0]
              };
    case /* MapStringArrayOneOf */2 :
        return {
                NAME: "optionalColumnNames",
                VAL: t._0
              };
    case /* SkipStringArray */3 :
        return "skip";
    
  }
}

function parse$4(row, rowIndex, columnIndexes, operation) {
  switch (operation.TAG | 0) {
    case /* MapStringArray */0 :
        return Curry._1(operation._1, Belt_Array.getExn(row, Belt_MapString.getExn(columnIndexes, operation._0)));
    case /* MapStringArrayFromOptionalColumn */1 :
        var columnIndex = Belt_MapString.get(columnIndexes, operation._0);
        return Belt_Option.mapWithDefault(Belt_Option.flatMap(columnIndex, (function (param) {
                          return Belt_Array.get(row, param);
                        })), [], operation._1);
    case /* MapStringArrayOneOf */2 :
        var columnNames = operation._0;
        var maybeValue = Belt_Array.get(Belt_Array.keepMap(columnNames, (function (columnName) {
                    var columnIndex = Belt_MapString.get(columnIndexes, columnName);
                    return Belt_Option.flatMap(columnIndex, (function (param) {
                                  return Belt_Array.get(row, param);
                                }));
                  })), 0);
        if (maybeValue !== undefined) {
          return Curry._1(operation._1, maybeValue);
        }
        throw {
              RE_EXN_ID: ParseWarning,
              _1: "one of [" + columnNames.join(", ") + "] is required but is not provided in row " + String(rowIndex) + "",
              Error: new Error()
            };
    case /* SkipStringArray */3 :
        return operation._0;
    
  }
}

var StringArrayOp = {
  getColumnPresence: getColumnPresence$4,
  parse: parse$4
};

function getColumnPresence$5(t) {
  switch (t.TAG | 0) {
    case /* MapSendAs */0 :
        return {
                NAME: "requiredColumnNames",
                VAL: [t._0]
              };
    case /* MapSendAsFromOptionalColumn */1 :
        return {
                NAME: "optionalColumnNames",
                VAL: [t._0]
              };
    case /* SkipSendAs */2 :
        return "skip";
    
  }
}

function parse$5(row, _rowIndex, columnIndexes, operation) {
  switch (operation.TAG | 0) {
    case /* MapSendAs */0 :
        return Curry._1(operation._1, Belt_Array.getExn(row, Belt_MapString.getExn(columnIndexes, operation._0)));
    case /* MapSendAsFromOptionalColumn */1 :
        var columnIndex = Belt_MapString.get(columnIndexes, operation._0);
        return Belt_Option.mapWithDefault(Belt_Option.flatMap(columnIndex, (function (param) {
                          return Belt_Array.get(row, param);
                        })), /* EventProperty */1, operation._1);
    case /* SkipSendAs */2 :
        return operation._0;
    
  }
}

var SendAsOp = {
  getColumnPresence: getColumnPresence$5,
  parse: parse$5
};

export {
  ParseError ,
  ParseFormatError ,
  ParseWarning ,
  getColumnValue ,
  getOptionalColumnValue ,
  getColumnIndex ,
  getOptionalColumnIndex ,
  getOptionalRegexColumnNameAndIndex ,
  StringTupleOp ,
  StringOp ,
  BoolOp ,
  IntOp ,
  StringArrayOp ,
  SendAsOp ,
}
/* No side effect */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Models from "../../app/src/Models.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function getApprovalActions(decodedBranchStatusActions) {
  if (!decodedBranchStatusActions) {
    return /* [] */0;
  }
  var match = decodedBranchStatusActions.hd[0];
  if (typeof match !== "object") {
    return /* [] */0;
  }
  if (match.NAME !== "SetBranchStatus") {
    return /* [] */0;
  }
  if (match.VAL[2] !== "Approved") {
    return /* [] */0;
  }
  var _maybeAllActions = decodedBranchStatusActions;
  var _approveActions = /* [] */0;
  while(true) {
    var approveActions = _approveActions;
    var maybeAllActions = _maybeAllActions;
    var allActions = Belt_Option.getWithDefault(maybeAllActions, /* [] */0);
    var match$1 = Belt_List.head(allActions);
    if (match$1 === undefined) {
      return approveActions;
    }
    var match$2 = match$1[0];
    if (typeof match$2 !== "object") {
      return approveActions;
    }
    if (match$2.NAME !== "SetBranchStatus") {
      return approveActions;
    }
    if (match$2.VAL[2] !== "Approved") {
      return approveActions;
    }
    _approveActions = Belt_List.add(approveActions, match$1[1]);
    _maybeAllActions = Belt_List.drop(allActions, 1);
    continue ;
  };
}

function get(openAction, maybeMergeAction, maybeCloseAction, setBranchStatusActions) {
  var decodedBranchStatusActions = Belt_List.map(setBranchStatusActions, (function (action) {
          return [
                  action.contents,
                  action
                ];
        }));
  if (maybeMergeAction !== undefined) {
    return {
            TAG: /* Merged */1,
            _0: Caml_option.valFromOption(maybeMergeAction)
          };
  }
  if (maybeCloseAction !== undefined) {
    return {
            TAG: /* Closed */2,
            _0: Caml_option.valFromOption(maybeCloseAction)
          };
  }
  if (!decodedBranchStatusActions) {
    return {
            TAG: /* Open */0,
            _0: openAction
          };
  }
  var match = decodedBranchStatusActions.hd;
  var match$1 = match[0];
  if (typeof match$1 !== "object") {
    return {
            TAG: /* Open */0,
            _0: openAction
          };
  }
  if (match$1.NAME !== "SetBranchStatus") {
    return {
            TAG: /* Open */0,
            _0: openAction
          };
  }
  var match$2 = match$1.VAL[2];
  if (match$2 === "ChangesRequested") {
    return {
            TAG: /* ChangesRequested */5,
            _0: match[1]
          };
  } else if (match$2 === "Approved") {
    return {
            TAG: /* Approved */6,
            _0: getApprovalActions(decodedBranchStatusActions)
          };
  } else if (match$2 === "Draft") {
    return {
            TAG: /* Draft */3,
            _0: match[1]
          };
  } else {
    return {
            TAG: /* ReadyForReview */4,
            _0: match[1]
          };
  }
}

function branchApprovalsCount(branchStatus) {
  if (typeof branchStatus === "number" || branchStatus.TAG !== /* Approved */6) {
    return ;
  } else {
    return Belt_List.size(branchStatus._0);
  }
}

function getBranchMergeOrCloseTimestamp(maybeMergeAction, maybeCloseAction) {
  if (maybeMergeAction !== undefined) {
    return Models.toDateOption(Models.Action.getCreatedAt(Caml_option.valFromOption(maybeMergeAction)));
  } else if (maybeCloseAction !== undefined) {
    return Models.toDateOption(Models.Action.getCreatedAt(Caml_option.valFromOption(maybeCloseAction)));
  } else {
    return ;
  }
}

function branchIsMergedOrMain(branchStatus) {
  if (typeof branchStatus === "number" || branchStatus.TAG === /* Merged */1) {
    return true;
  } else {
    return false;
  }
}

export {
  getApprovalActions ,
  get ,
  branchApprovalsCount ,
  getBranchMergeOrCloseTimestamp ,
  branchIsMergedOrMain ,
}
/* Models Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Icons from "../Icons.mjs";
import * as React from "react";
import * as IconWebhookIntegration from "../IconWebhookIntegration.mjs";

function IntegrationIcon$ByType(Props) {
  var integration = Props.integration;
  var size = Props.size;
  if (integration === "Protocols") {
    return React.createElement(Icons.Destinations.Segment.make, {
                size: size
              });
  } else if (integration === "SnowplowDataStructures") {
    return React.createElement(Icons.Destinations.Snowplow.make, {
                size: size
              });
  } else if (integration === "Lexicon") {
    return React.createElement(Icons.Destinations.Mixpanel.make, {
                size: size
              });
  } else if (integration === "MParticleDataMaster") {
    return React.createElement(Icons.Destinations.MParticle.make, {
                size: size
              });
  } else if (integration === "AdobeExperiencePlatform") {
    return React.createElement(Icons.Destinations.AdobeAnalytics.make, {
                size: size
              });
  } else if (integration === "Taxonomy") {
    return React.createElement(Icons.Destinations.Amplitude.make, {
                size: size
              });
  } else if (integration === "Webhook") {
    return React.createElement(IconWebhookIntegration.make, {
                size: size
              });
  } else {
    return React.createElement(Icons.Destinations.Rudderstack.make, {
                size: size
              });
  }
}

var ByType = {
  make: IntegrationIcon$ByType
};

function IntegrationIcon(Props) {
  var config = Props.config;
  var size = Props.size;
  if (config === undefined) {
    return React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.width(Css.px(size)),
                      tl: /* [] */0
                    })
              });
  }
  switch (config.TAG | 0) {
    case /* Protocols */0 :
        return React.createElement(Icons.Destinations.Segment.make, {
                    size: size
                  });
    case /* Taxonomy */1 :
        return React.createElement(Icons.Destinations.Amplitude.make, {
                    size: size
                  });
    case /* Lexicon */2 :
        return React.createElement(Icons.Destinations.Mixpanel.make, {
                    size: size
                  });
    case /* Webhook */3 :
        return React.createElement(IconWebhookIntegration.make, {
                    size: size
                  });
    case /* SnowplowDataStructures */4 :
        return React.createElement(Icons.Destinations.Snowplow.make, {
                    size: size
                  });
    case /* MParticleDataMaster */5 :
        return React.createElement(Icons.Destinations.MParticle.make, {
                    size: size
                  });
    case /* RudderstackTrackingPlans */6 :
    case /* RudderstackDataCatalog */7 :
        break;
    case /* AdobeExperiencePlatform */8 :
        return React.createElement(Icons.Destinations.AdobeAnalytics.make, {
                    size: size
                  });
    
  }
  return React.createElement(Icons.Destinations.Rudderstack.make, {
              size: size
            });
}

var make = IntegrationIcon;

export {
  ByType ,
  make ,
}
/* Css Not a pure module */

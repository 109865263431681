// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Errors from "../../../shared/utils/Errors.mjs";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";

function getErrorMessageOptional(error) {
  if (error.RE_EXN_ID === $$Promise.JsError) {
    return error._1.message;
  } else if (error.RE_EXN_ID === Json_decode.DecodeError || error.RE_EXN_ID === "Failure" || error.RE_EXN_ID === "Invalid_argument") {
    return error._1;
  } else if (error.RE_EXN_ID === "Division_by_zero") {
    return "Division by zero";
  } else {
    return ;
  }
}

function getErrorMessage(error) {
  if (error.RE_EXN_ID === Errors.AvoError) {
    return error._1;
  } else if (error.RE_EXN_ID === $$Promise.JsError) {
    return Belt_Option.getWithDefault(error._1.message, "No message");
  } else if (error.RE_EXN_ID === Json_decode.DecodeError || error.RE_EXN_ID === "Failure" || error.RE_EXN_ID === "Invalid_argument") {
    return error._1;
  } else if (error.RE_EXN_ID === "Division_by_zero") {
    return "Division by zero";
  } else {
    return Belt_Option.getWithDefault(error.message, "Internal Error");
  }
}

function getErrorCodeOptional(error) {
  if (error.RE_EXN_ID === $$Promise.JsError) {
    return error._1.code;
  }
  
}

function resultFlatMap(promise, f) {
  return promise.then(function (result) {
              if (result.TAG === /* Ok */0) {
                return Curry._1(f, result._0);
              } else {
                return Promise.resolve(result);
              }
            });
}

function timeout(cb, timeout$1) {
  var timeoutPromise = new Promise((function (param, reject) {
          setTimeout((function (param) {
                  reject(new Error("Promise timed out"));
                }), timeout$1);
        }));
  return Promise.race([
              cb,
              timeoutPromise
            ]);
}

function wait(waitMs) {
  return new Promise((function (resolve, _reject) {
                setTimeout((function (param) {
                        resolve(undefined);
                      }), waitMs);
              }));
}

function waitFloat(waitMs) {
  return wait(waitMs | 0);
}

export {
  getErrorMessageOptional ,
  getErrorMessage ,
  getErrorCodeOptional ,
  resultFlatMap ,
  timeout ,
  wait ,
  waitFloat ,
}
/* No side effect */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "../Hooks.mjs";
import * as React from "react";
import * as Router from "../Router.mjs";
import * as Styles from "../styles.mjs";
import * as Mantine from "../Mantine.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ModelStore from "../ModelStore.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Core from "@mantine/core";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as SelectBranchPopup from "../SelectBranchPopup.mjs";

function rootStyles(hasSelected) {
  return Curry._1(Css.merge, {
              hd: Curry._1(Css.style, Styles.buttonReset),
              tl: {
                hd: Curry._1(Css.style, {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.flexDirection("row"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.border(Css.px(1), "dashed", Styles.Color.light04),
                            tl: {
                              hd: Css.borderRadius(Styles.Border.radius),
                              tl: {
                                hd: Css.paddingTop(Css.px(4)),
                                tl: {
                                  hd: Css.paddingRight(Css.px(8)),
                                  tl: {
                                    hd: Css.paddingBottom(Css.px(4)),
                                    tl: {
                                      hd: Css.paddingLeft(Css.px(8)),
                                      tl: {
                                        hd: Css.color(hasSelected ? Styles.Color.green : Styles.Color.darkBlue),
                                        tl: {
                                          hd: Css.hover({
                                                hd: Css.borderColor(Styles.Color.light07),
                                                tl: {
                                                  hd: Css.color(hasSelected ? Styles.Color.green : Styles.Color.deepBlue),
                                                  tl: /* [] */0
                                                }
                                              }),
                                          tl: {
                                            hd: Css.focus({
                                                  hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), undefined, Styles.Color.light07)),
                                                  tl: /* [] */0
                                                }),
                                            tl: {
                                              hd: Css.outlineStyle("none"),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }),
                tl: /* [] */0
              }
            });
}

function CopyChangesToBranchModal__BranchPicker(Props) {
  var selectedBranch = Props.selectedBranch;
  var setSelectedBranch = Props.setSelectedBranch;
  var mainModel = ModelStore.useMainModel(undefined);
  var currentBranchId = Router.Schema.getBranchId(undefined);
  var openBranches = Belt_List.keep(mainModel.openBranches, (function (param) {
          return param[0] !== currentBranchId;
        }));
  var match = Hooks.useDisclosure(false);
  var branchPopupActions = match[1];
  return React.createElement(Mantine.Popover.make, {
              dropdownStyles: {
                hd: Css.transform({
                      NAME: "translateY",
                      VAL: Css.px(3)
                    }),
                tl: /* [] */0
              },
              offset: 0,
              onChange: (function (param) {
                  Curry._1(branchPopupActions.toggle, undefined);
                }),
              opened: match[0],
              position: "right-start",
              children: null
            }, React.createElement(Core.Popover.Target, {
                  children: React.createElement("button", {
                        className: rootStyles(Belt_Option.isSome(selectedBranch)),
                        onClick: (function (param) {
                            Curry._1(branchPopupActions.toggle, undefined);
                          })
                      }, React.createElement($$Text.make, {
                            size: "Small",
                            weight: "Semi",
                            children: selectedBranch !== undefined ? (
                                typeof selectedBranch === "object" ? (
                                    selectedBranch.NAME === "Create" ? selectedBranch.VAL : selectedBranch.VAL.name
                                  ) : "main"
                              ) : "Choose a branch"
                          }))
                }), React.createElement(Mantine.Popover.Dropdown.make, {
                  children: React.createElement(SelectBranchPopup.make, {
                        canSelectExistingBranches: true,
                        canSelectMain: false,
                        onClose: (function (param) {
                            Curry._1(branchPopupActions.close, undefined);
                          }),
                        onSelect: (function (selectedBranch) {
                            Curry._1(setSelectedBranch, (function (param) {
                                    return selectedBranch;
                                  }));
                          }),
                        openBranches: openBranches
                      })
                }));
}

var make = CopyChangesToBranchModal__BranchPicker;

export {
  rootStyles ,
  make ,
}
/* Css Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Issues from "../../../shared/models/Issues.mjs";
import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Router from "../Router.mjs";
import * as Nanoid from "nanoid";
import * as Belt_Id from "rescript/lib/es6/belt_Id.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Belt_Set from "rescript/lib/es6/belt_Set.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "../ModelStore.mjs";
import * as ModelUtils from "../ModelUtils.mjs";
import * as AnalyticsRe from "../analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.mjs";
import * as ImportParser from "./ImportParser.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as InspectorViewApi from "../inspector/InspectorViewApi.mjs";
import * as InspectorEventShape from "../../../shared/models/InspectorEventShape.mjs";
import * as Belt_MutableSetString from "rescript/lib/es6/belt_MutableSetString.js";
import * as InspectorPropertyType from "../../../shared/models/InspectorPropertyType.mjs";
import * as TrackingPlanMappedModel from "../../../model/src/TrackingPlanMappedModel.mjs";
import * as InspectorIssuesFilterUtils from "../../../shared/utils/InspectorIssuesFilterUtils.mjs";
import * as GetAllPropertyValuesUseCase from "../../../model/src/eventSpecificPropertyValues/GetAllPropertyValuesUseCase.mjs";

var EmptyImportException = /* @__PURE__ */Caml_exceptions.create("ImportReview__V2__Utils.EmptyImportException");

var NoEventsToImportException = /* @__PURE__ */Caml_exceptions.create("ImportReview__V2__Utils.NoEventsToImportException");

var NoVariationsException = /* @__PURE__ */Caml_exceptions.create("ImportReview__V2__Utils.NoVariationsException");

function cmp(param, param$1) {
  var c = Caml_obj.compare(param[0], param$1[0]);
  if (c !== 0) {
    return c;
  } else {
    return Caml_obj.compare(param[1], param$1[1]);
  }
}

var StringTupleComparator = Belt_Id.MakeComparable({
      cmp: cmp
    });

function getEventNames(appVersions, eventsByName, issues) {
  return Belt_SetString.toArray(Belt_SetString.fromArray(Curry._2(Issues.keepMap, InspectorIssuesFilterUtils.filterIssueViewModels(undefined, appVersions, issues, [{
                            NAME: "issueType",
                            VAL: {
                              NAME: "new",
                              VAL: "eventNotInTrackingPlanV2"
                            }
                          }]), (function (param) {
                        var source = param.source;
                        var eventName = param.eventName;
                        var match = Belt_MapString.get(eventsByName, eventName);
                        if (match !== undefined && !Belt_List.everyU(match.includeSources, (function (param) {
                                  return source.id !== param.id;
                                }))) {
                          return ;
                        } else {
                          return eventName;
                        }
                      }))));
}

function stringifyTypes(types) {
  return JSON.stringify(Json_encode.array(InspectorEventShape.PropertyType.encode, types));
}

function choosePropertiesBasedOnModelType(eventId, mappedModelProperties, model, properties) {
  return Belt_Array.mapU(properties, (function (property) {
                var modelProperty = Curry._2(TrackingPlanMappedModel.Properties.getBy, mappedModelProperties, (function (param) {
                        return param.name === property.propertyName;
                      }));
                var match;
                if (modelProperty !== undefined) {
                  var type_ = modelProperty.type_;
                  var allowedValues = GetAllPropertyValuesUseCase.getWithLegacyFallback(ModelUtils.hasMigrated(model, "EventSpecificAllowedPropertyValues"), modelProperty);
                  var a = property.propertyTypes;
                  var tmp;
                  var exit = 0;
                  if (a.length !== 1) {
                    exit = 1;
                  } else {
                    var t = a[0];
                    if (Caml_obj.equal(InspectorPropertyType.fromString(type_), t.type_)) {
                      tmp = undefined;
                    } else {
                      exit = 1;
                    }
                  }
                  if (exit === 1) {
                    tmp = "" + eventId + ": " + stringifyTypes(a) + " [types]";
                  }
                  match = [
                    type_,
                    modelProperty.description,
                    tmp,
                    allowedValues,
                    modelProperty.list,
                    modelProperty.sendAs
                  ];
                } else {
                  var types = Belt_Array.mapU(property.propertyTypes, (function (param) {
                          return InspectorPropertyType.toString(param.type_);
                        }));
                  var l = Belt_List.fromArray(types);
                  var typeWarning = l ? (
                      Caml_obj.equal(InspectorPropertyType.fromString(l.hd), /* Number */4) ? "" + eventId + ": " + stringifyTypes(property.propertyTypes) + " [types]" : (
                          Belt_List.size(l) > 1 ? "" + eventId + ": " + stringifyTypes(property.propertyTypes) + " [types]" : undefined
                        )
                    ) : "" + eventId + ": [] [types]";
                  var presenceWarning = Belt_Array.someU(Js_dict.values(property.presence.presenceBySource), (function (param) {
                          return param.percentagePresent < 1.0;
                        })) ? "" + eventId + ": " + JSON.stringify(Json_encode.dict(InspectorEventShape.SourcePresence.encode, property.presence.presenceBySource)) + " [presence]" : undefined;
                  var warnings = typeWarning !== undefined ? (
                      presenceWarning !== undefined ? "" + typeWarning + "||" + presenceWarning + "" : typeWarning
                    ) : (
                      presenceWarning !== undefined ? presenceWarning : undefined
                    );
                  var isList = Belt_Option.mapWithDefault(Belt_Array.get(property.propertyTypes, 0), false, (function (param) {
                          return param.isList;
                        }));
                  var l$1 = Belt_List.fromArray(types);
                  match = [
                    l$1 ? (
                        Caml_obj.equal(InspectorPropertyType.fromString(l$1.hd), /* Number */4) ? InspectorPropertyType.toString(/* Float */2) : Belt_List.headExn(l$1)
                      ) : InspectorPropertyType.toString(/* String */0),
                    "",
                    warnings,
                    [],
                    isList,
                    /* EventProperty */1
                  ];
                }
                return {
                        name: property.propertyName,
                        description: match[1],
                        type_: match[0],
                        typeWarning: match[2],
                        required: true,
                        list: match[4],
                        matches: match[3],
                        sendAs: match[5],
                        propertyBundle: {
                          name: "",
                          description: ""
                        },
                        propertyEvents: [],
                        nameMapping: undefined,
                        pinnedValue: undefined
                      };
              }));
}

function toImportEvent(generateId, mappedModelProperties, mappedModelSources, model) {
  return function ($$event) {
    var id = Curry._1(generateId, undefined);
    return {
            id: id,
            name: $$event.eventName,
            description: "",
            categories: [],
            tags: [],
            properties: choosePropertiesBasedOnModelType(id, mappedModelProperties, model, $$event.properties),
            propertyBundleNames: [],
            sources: Belt_Array.mapU(Object.keys($$event.presenceBySource), (function (id) {
                    return Belt_Option.getWithDefault(TrackingPlanMappedModel.Sources.getName(mappedModelSources, id), "Untitled Source");
                  })),
            stakeholderDomains: [],
            nameMapping: [],
            version: 0
          };
  };
}

function importModelIsEmpty(importModel) {
  if (Caml_obj.equal(importModel.newEvents, []) && Caml_obj.equal(importModel.updatedEvents, []) && Caml_obj.equal(importModel.newProperties, [])) {
    return Caml_obj.equal(importModel.updatedProperties, []);
  } else {
    return false;
  }
}

function toImportModel(generateId, model, mappedModelProperties, mappedModelSources, eventVariationShapes, importMethod, domainEvents) {
  var rawOutput_events = Belt_Array.mapU(eventVariationShapes, toImportEvent(generateId, mappedModelProperties, mappedModelSources, model));
  var rawOutput_propertiesWithoutEvent = [];
  var rawOutput_warnings = [];
  var rawOutput_propertyBundles = [];
  var rawOutput = {
    formatName: "Inspector Issues Import",
    events: rawOutput_events,
    propertiesWithoutEvent: rawOutput_propertiesWithoutEvent,
    warnings: rawOutput_warnings,
    propertyBundles: rawOutput_propertyBundles
  };
  var parseOutputWithFlattenedProperties = ImportParser.processRawOutput(generateId, model, rawOutput, importMethod);
  var importModel = ImportParser.filterParsedModelBasedOnAvoModel(model, parseOutputWithFlattenedProperties, "AddOnly", domainEvents);
  if (importModelIsEmpty(importModel)) {
    throw {
          RE_EXN_ID: EmptyImportException,
          Error: new Error()
        };
  }
  return importModel;
}

function handlePrepareImport(eventNames, domainEvents) {
  if (Caml_obj.equal(eventNames, [])) {
    throw {
          RE_EXN_ID: NoEventsToImportException,
          Error: new Error()
        };
  }
  return InspectorViewApi.getEventsShapes(eventNames).then(function (eventVariations) {
              if (Caml_obj.equal(eventVariations, [])) {
                throw {
                      RE_EXN_ID: NoVariationsException,
                      Error: new Error()
                    };
              }
              var modelStore = Curry._1(ModelStore.ZustandStore.getState, ModelStore.store);
              var modelHelper = function (getItem) {
                var match = modelStore.currentBranch.model;
                var match$1 = modelStore.currentBranch.mappedModel;
                if (match !== undefined && match$1 !== undefined) {
                  return Curry._2(getItem, match, match$1);
                }
                return Curry._2(getItem, modelStore.main.model, modelStore.main.mappedModel);
              };
              var model = modelHelper(function (model, param) {
                    return model;
                  });
              var mappedProperties = modelHelper(function (param, param$1) {
                    return param$1.properties;
                  });
              var mappedSources = modelHelper(function (param, param$1) {
                    return param$1.sources;
                  });
              return toImportModel((function (prim) {
                            return Nanoid.nanoid();
                          }), model, mappedProperties, mappedSources, eventVariations, "AddOnly", domainEvents);
            });
}

function updatePropertyType(onSuccess, setWorkingModel, propertyId, type_) {
  var updateProperty = function (properties) {
    return Belt_Array.mapU(properties, (function (property) {
                  if (property.id === propertyId) {
                    return {
                            id: property.id,
                            name: property.name,
                            uniqueNameIndex: property.uniqueNameIndex,
                            description: property.description,
                            type_: InspectorPropertyType.toString(type_),
                            list: property.list,
                            matches: property.matches,
                            warnings: property.warnings,
                            presence: property.presence,
                            sendAs: property.sendAs,
                            nameMapping: property.nameMapping
                          };
                  } else {
                    return property;
                  }
                }));
  };
  Curry._1(setWorkingModel, (function (workingModel) {
          return {
                  formatName: workingModel.formatName,
                  newEvents: workingModel.newEvents,
                  updatedEvents: workingModel.updatedEvents,
                  warnings: workingModel.warnings,
                  newProperties: updateProperty(workingModel.newProperties),
                  updatedProperties: updateProperty(workingModel.updatedProperties),
                  allParsedProperties: updateProperty(workingModel.allParsedProperties),
                  sources: workingModel.sources,
                  allStakeholderDomains: workingModel.allStakeholderDomains,
                  propertyBundles: workingModel.propertyBundles
                };
        }));
  Belt_Option.forEach(onSuccess, (function (fn) {
          Curry._1(fn, undefined);
        }));
}

function updatePropertyPresence(onSuccess, setWorkingModel, eventId, propertyId, presence) {
  var updateProperty = function (properties) {
    return Belt_Array.mapU(properties, (function (property) {
                  if (property.id === propertyId) {
                    return {
                            id: property.id,
                            name: property.name,
                            uniqueNameIndex: property.uniqueNameIndex,
                            description: property.description,
                            type_: property.type_,
                            list: property.list,
                            matches: property.matches,
                            warnings: property.warnings,
                            presence: Belt_MapString.update(property.presence, eventId, (function (param) {
                                    return presence;
                                  })),
                            sendAs: property.sendAs,
                            nameMapping: property.nameMapping
                          };
                  } else {
                    return property;
                  }
                }));
  };
  Curry._1(setWorkingModel, (function (workingModel) {
          return {
                  formatName: workingModel.formatName,
                  newEvents: workingModel.newEvents,
                  updatedEvents: workingModel.updatedEvents,
                  warnings: workingModel.warnings,
                  newProperties: updateProperty(workingModel.newProperties),
                  updatedProperties: updateProperty(workingModel.updatedProperties),
                  allParsedProperties: updateProperty(workingModel.allParsedProperties),
                  sources: workingModel.sources,
                  allStakeholderDomains: workingModel.allStakeholderDomains,
                  propertyBundles: workingModel.propertyBundles
                };
        }));
  Belt_Option.forEach(onSuccess, (function (fn) {
          Curry._1(fn, undefined);
        }));
}

function filterPropertiesBeforeImport(excludedEventPropertyIds, excludedUserPropertyIds, excludeAllUserProperties, selectedEvents, properties) {
  var newPropertyIdsInEvents = Belt_SetString.fromArray(Belt_Array.concatMany(Belt_Array.mapU(selectedEvents, (function (param) {
                  var eventId = param.id;
                  return Belt_Array.keepMapU(param.propertyIds, (function (param) {
                                var propertyId = param[0];
                                if (Belt_Set.has(excludedEventPropertyIds, [
                                        eventId,
                                        propertyId
                                      ])) {
                                  return ;
                                } else {
                                  return propertyId;
                                }
                              }));
                }))));
  var newProperties = Belt_Array.keepU(properties, (function (param) {
          return Belt_SetString.has(newPropertyIdsInEvents, param.id);
        }));
  var updatedPropertyIdsInEvents = Belt_SetString.fromArray(Belt_Array.concatMany(Belt_Array.mapU(selectedEvents, (function (param) {
                  var eventId = param.id;
                  return Belt_Array.keepMapU(param.updatedPropertyIds, (function (param) {
                                var propertyId = param[0];
                                if (Belt_Set.has(excludedEventPropertyIds, [
                                        eventId,
                                        propertyId
                                      ])) {
                                  return ;
                                } else {
                                  return propertyId;
                                }
                              }));
                }))));
  var updatedProperties = Belt_Array.keepU(properties, (function (param) {
          var sendAs = param.sendAs;
          var id = param.id;
          if (typeof sendAs === "number" && sendAs >= 2) {
            if (excludeAllUserProperties || !Belt_SetString.has(updatedPropertyIdsInEvents, id)) {
              return false;
            } else {
              return !Belt_SetString.has(excludedUserPropertyIds, id);
            }
          } else {
            return Belt_SetString.has(updatedPropertyIdsInEvents, id);
          }
        }));
  return Belt_Array.concat(newProperties, updatedProperties);
}

function filterEventsAndSourcesBeforeImport(excludedEventPropertyIds, excludedEventSources, includedNewEvents, includedUpdatedEvents) {
  var sourcesInEvents = Belt_MutableSetString.make(undefined);
  var updateEvents = function (events) {
    return Belt_Array.mapU(events, (function ($$event) {
                  return {
                          id: $$event.id,
                          name: $$event.name,
                          uniqueNameIndex: $$event.uniqueNameIndex,
                          description: $$event.description,
                          categories: $$event.categories,
                          tags: $$event.tags,
                          propertyIds: Belt_Array.keepU($$event.propertyIds, (function (param) {
                                  return !Belt_Set.has(excludedEventPropertyIds, [
                                              $$event.id,
                                              param[0]
                                            ]);
                                })),
                          updatedPropertyIds: $$event.updatedPropertyIds,
                          propertyBundleIds: $$event.propertyBundleIds,
                          sources: Belt_Array.keepU($$event.sources, (function (sourceId) {
                                  if (Belt_Set.has(excludedEventSources, [
                                          $$event.id,
                                          sourceId
                                        ])) {
                                    return false;
                                  } else {
                                    Belt_MutableSetString.add(sourcesInEvents, sourceId);
                                    return true;
                                  }
                                })),
                          stakeholderDomains: $$event.stakeholderDomains,
                          warnings: $$event.warnings,
                          nameMapping: $$event.nameMapping
                        };
                }));
  };
  var newEvents = updateEvents(includedNewEvents);
  var updatedEvents = updateEvents(includedUpdatedEvents);
  return [
          newEvents,
          updatedEvents,
          Belt_MutableSetString.toArray(sourcesInEvents)
        ];
}

function handleExceptions(e, fn) {
  if (e.RE_EXN_ID === NoVariationsException) {
    return Curry._2(fn, "Not enough data to create import", "info");
  } else if (e.RE_EXN_ID === EmptyImportException) {
    return Curry._2(fn, "No data to import", "info");
  } else if (e.RE_EXN_ID === NoEventsToImportException) {
    return Curry._2(fn, "No events to import", "info");
  } else if (e.RE_EXN_ID === Js_exn.$$Error) {
    console.error("Inspector Import Review failed: ", e._1);
    return Curry._2(fn, "Unable to import events", "error");
  } else {
    console.error("Inspector Import Review failed: ", e);
    return Curry._2(fn, "Unable to import events", "error");
  }
}

function getIncludedEvents(excludedEventIds, events) {
  return Belt_Array.keepU(events, (function ($$event) {
                return !Belt_SetString.has(excludedEventIds, $$event.id);
              }));
}

function applyExclusionsBeforeImport(excludedEventIds, excludeAllUserProperties, excludedEventPropertyIds, excludedUserPropertyIds, excludedEventSources, workingModel) {
  var includedNewEvents = getIncludedEvents(excludedEventIds, workingModel.newEvents);
  var includedUpdatedEvents = getIncludedEvents(excludedEventIds, workingModel.updatedEvents);
  var partial_arg = Belt_Array.concat(includedNewEvents, includedUpdatedEvents);
  var filterPropertiesBeforeImport$1 = function (param) {
    return filterPropertiesBeforeImport(excludedEventPropertyIds, excludedUserPropertyIds, excludeAllUserProperties, partial_arg, param);
  };
  var newProperties = Curry._1(filterPropertiesBeforeImport$1, workingModel.newProperties);
  var updatedProperties = Curry._1(filterPropertiesBeforeImport$1, workingModel.updatedProperties);
  var match = filterEventsAndSourcesBeforeImport(excludedEventPropertyIds, excludedEventSources, includedNewEvents, includedUpdatedEvents);
  return {
          formatName: workingModel.formatName,
          newEvents: match[0],
          updatedEvents: match[1],
          warnings: workingModel.warnings,
          newProperties: newProperties,
          updatedProperties: updatedProperties,
          allParsedProperties: workingModel.allParsedProperties,
          sources: match[2],
          allStakeholderDomains: workingModel.allStakeholderDomains,
          propertyBundles: workingModel.propertyBundles
        };
}

var importStartedLocation = "importStartedLocation";

var importSuccess = "importSuccess";

var resolvedIssuesCount = "resolvedIssuesCount";

var eventsImportedCount = "eventsImportedCount";

var propertiesImportedCount = "propertiesImportedCount";

var sourcesImportedCount = "sourcesImportedCount";

var newStakeholderDomainCount = "newStakeholderDomainCount";

var QueryParams = {
  importStartedLocation: importStartedLocation,
  importSuccess: importSuccess,
  resolvedIssuesCount: resolvedIssuesCount,
  eventsImportedCount: eventsImportedCount,
  propertiesImportedCount: propertiesImportedCount,
  sourcesImportedCount: sourcesImportedCount,
  newStakeholderDomainCount: newStakeholderDomainCount
};

function fromString(value) {
  return Belt_Option.getWithDefault(Belt_Option.flatMap(value, AnalyticsRe.importStartedLocationFromJs), "DirectLink");
}

var ImportStartedLocationQueryParam = Router.QueryParams.MakeFixedKey({
      toString: AnalyticsRe.importStartedLocationToJs,
      fromString: fromString,
      key: importStartedLocation
    });

function useImportStartedLocationQueryParam(param) {
  return Curry._1(ImportStartedLocationQueryParam.use, undefined);
}

function useImportSuccessQueryParam(param) {
  return Router.QueryParams.useBoolKey(importSuccess);
}

function useResolvedIssuesCountQueryParam(param) {
  return Router.QueryParams.useIntKey(resolvedIssuesCount);
}

function useEventsImportedCountQueryParam(param) {
  return Router.QueryParams.useIntKey(eventsImportedCount);
}

function usePropertiesImportedCountQueryParam(param) {
  return Router.QueryParams.useIntKey(propertiesImportedCount);
}

function useSourcesImportedCountQueryParam(param) {
  return Router.QueryParams.useIntKey(sourcesImportedCount);
}

function useNewStakeholderDomainCountQueryParam(param) {
  return Router.QueryParams.useIntKey(newStakeholderDomainCount);
}

function areSameTypes(a, b) {
  return Caml_obj.equal(InspectorPropertyType.fromString(a), InspectorPropertyType.fromString(b));
}

function eventHasSource($$event, issue) {
  return Belt_Array.someU($$event.sources, (function (sourceName) {
                return sourceName === issue.source.name;
              }));
}

function eventHasProperty($$event, propertyId) {
  return Belt_Array.someU(Belt_Array.concat($$event.propertyIds, $$event.updatedPropertyIds), (function (param) {
                return propertyId === param[0];
              }));
}

var IssueFilters = {
  areSameTypes: areSameTypes,
  eventHasSource: eventHasSource,
  eventHasProperty: eventHasProperty
};

function getRelatedIssues(eventNames, propertyNames, issues) {
  return Curry._2(Issues.keep, issues, (function (issue) {
                var match = issue.issueType;
                var match$1 = Belt_MapString.get(eventNames, issue.eventName);
                var match$2 = Belt_Option.flatMapU(issue.propertyName, (function (pn) {
                        return Belt_MapString.get(propertyNames, pn);
                      }));
                if (match$1 === undefined) {
                  return false;
                }
                if (typeof match === "number") {
                  if (match === /* EventNotInTrackingPlan */0) {
                    return true;
                  } else {
                    return eventHasSource(match$1, issue);
                  }
                }
                switch (match.TAG | 0) {
                  case /* MissingExpectedProperty */0 :
                      if (eventHasProperty(match$1, match._0.propertyId)) {
                        return eventHasSource(match$1, issue);
                      } else {
                        return false;
                      }
                  case /* PropertyTypeInconsistentWithTrackingPlan */1 :
                      if (match$2 !== undefined) {
                        return !areSameTypes(match$2.type_, match._0.expectedPropertyType);
                      } else {
                        return false;
                      }
                  case /* UnexpectedProperty */2 :
                      if (match$2 !== undefined && eventHasProperty(match$1, match$2.id)) {
                        return areSameTypes(match$2.type_, match._0.propertyType);
                      } else {
                        return false;
                      }
                  case /* InconsistentType */3 :
                      return false;
                  
                }
              }));
}

export {
  EmptyImportException ,
  NoEventsToImportException ,
  NoVariationsException ,
  StringTupleComparator ,
  getEventNames ,
  stringifyTypes ,
  choosePropertiesBasedOnModelType ,
  toImportEvent ,
  importModelIsEmpty ,
  toImportModel ,
  handlePrepareImport ,
  updatePropertyType ,
  updatePropertyPresence ,
  filterPropertiesBeforeImport ,
  filterEventsAndSourcesBeforeImport ,
  handleExceptions ,
  getIncludedEvents ,
  applyExclusionsBeforeImport ,
  QueryParams ,
  ImportStartedLocationQueryParam ,
  useImportStartedLocationQueryParam ,
  useImportSuccessQueryParam ,
  useResolvedIssuesCountQueryParam ,
  useEventsImportedCountQueryParam ,
  usePropertiesImportedCountQueryParam ,
  useSourcesImportedCountQueryParam ,
  useNewStakeholderDomainCountQueryParam ,
  IssueFilters ,
  getRelatedIssues ,
}
/* StringTupleComparator Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_Id from "rescript/lib/es6/belt_Id.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Belt_Set from "rescript/lib/es6/belt_Set.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AvoConfig from "./utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "../app/src/ModelUtils.mjs";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ActionsReducer from "../app/src/actionsReducer.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as TrackingPlanModel from "../model/src/TrackingPlanModel.mjs";
import * as BeltListExtensions from "../app/src/BeltListExtensions.mjs";
import * as TrackingPlanMappedModel from "../model/src/TrackingPlanMappedModel.mjs";

function diffList(fromList, toList, compare) {
  var adds = Belt_List.keepMapU(toList, (function (toItem) {
          var match = Belt_List.getByU(fromList, (function (fromItem) {
                  return Curry._2(compare, toItem, fromItem);
                }));
          if (match !== undefined) {
            return ;
          } else {
            return {
                    NAME: "Add",
                    VAL: toItem
                  };
          }
        }));
  var removes = Belt_List.keepMapU(fromList, (function (fromItem) {
          var match = Belt_List.getByU(toList, (function (toItem) {
                  return Curry._2(compare, fromItem, toItem);
                }));
          if (match !== undefined) {
            return ;
          } else {
            return {
                    NAME: "Remove",
                    VAL: fromItem
                  };
          }
        }));
  return Belt_List.concat(removes, adds);
}

function diffArray(fromArray, toArray, compare) {
  var adds = Belt_Array.keepMapU(toArray, (function (toItem) {
          var match = Belt_Array.getByU(fromArray, (function (fromItem) {
                  return Curry._2(compare, toItem, fromItem);
                }));
          if (match !== undefined) {
            return ;
          } else {
            return {
                    NAME: "Add",
                    VAL: toItem
                  };
          }
        }));
  var removes = Belt_Array.keepMapU(fromArray, (function (fromItem) {
          var match = Belt_Array.getByU(toArray, (function (toItem) {
                  return Curry._2(compare, fromItem, toItem);
                }));
          if (match !== undefined) {
            return ;
          } else {
            return {
                    NAME: "Remove",
                    VAL: fromItem
                  };
          }
        }));
  return Belt_Array.concat(removes, adds);
}

function diffStringArray(fromArray, toArray) {
  return diffArray(fromArray, toArray, Caml_obj.equal);
}

function diffListOrder(fromList, toList, compareOpt, getIndex, param) {
  var compare = compareOpt !== undefined ? compareOpt : Caml_obj.equal;
  return Belt_List.keepMapU(Belt_List.mapWithIndex(toList, (function (index, item) {
                    return [
                            index,
                            item
                          ];
                  })), (function (param) {
                var item = param[1];
                var fromListWithIndex = Belt_List.mapWithIndexU(fromList, (function (item, index) {
                        return [
                                item,
                                index
                              ];
                      }));
                var prevIndex = Curry._2(getIndex, fromListWithIndex, item);
                var prevPredecessor = Belt_Option.flatMapU(prevIndex, (function (prevIndex) {
                        return Belt_List.get(Belt_List.keepMapU(fromListWithIndex, (function (param) {
                                          if (param[0] === (prevIndex - 1 | 0)) {
                                            return Caml_option.some(param[1]);
                                          }
                                          
                                        })), 0);
                      }));
                var nextPredecessor = Belt_List.get(toList, param[0] - 1 | 0);
                if (Curry._2(compare, prevPredecessor, nextPredecessor)) {
                  return ;
                } else {
                  return {
                          NAME: "Reorder",
                          VAL: [
                            item,
                            nextPredecessor
                          ]
                        };
                }
              }));
}

function diffListOrderWithOldIds(fromList, toList, getIndex) {
  return Belt_List.keepMapU(Belt_List.mapWithIndex(toList, (function (index, item) {
                    return [
                            index,
                            item
                          ];
                  })), (function (param) {
                var item = param[1];
                var fromListWithIndex = Belt_List.mapWithIndexU(fromList, (function (item, index) {
                        return [
                                item,
                                index
                              ];
                      }));
                var prevIndex = Curry._2(getIndex, fromListWithIndex, item);
                var prevPredecessor = Belt_Option.flatMapU(prevIndex, (function (prevIndex) {
                        return Belt_List.get(Belt_List.keepMapU(fromListWithIndex, (function (param) {
                                          if (param[0] === (prevIndex - 1 | 0)) {
                                            return param[1];
                                          }
                                          
                                        })), 0);
                      }));
                var nextPredecessor = Belt_Option.map(Belt_List.get(toList, param[0] - 1 | 0), (function (item) {
                        return item.id;
                      }));
                if (Caml_obj.equal(prevPredecessor, nextPredecessor)) {
                  return ;
                } else {
                  return {
                          NAME: "Reorder",
                          VAL: [
                            item,
                            nextPredecessor
                          ]
                        };
                }
              }));
}

function diffStringList(fromList, toList) {
  return diffList(fromList, toList, Caml_obj.equal);
}

function diffListWithListOfItemsWithOldIds(fromList, toList, compare) {
  var adds = Belt_List.keepMapU(toList, (function (toItem) {
          var match = Belt_List.getByU(fromList, (function (fromItem) {
                  if (Curry._2(compare, fromItem, toItem.id)) {
                    return true;
                  } else {
                    return Belt_Option.mapWithDefault(toItem.oldId, false, (function (__x) {
                                  return Curry._2(compare, fromItem, __x);
                                }));
                  }
                }));
          if (match !== undefined) {
            return ;
          } else {
            return {
                    NAME: "Add",
                    VAL: toItem.id
                  };
          }
        }));
  var removes = Belt_List.keepMapU(fromList, (function (fromItem) {
          var match = Belt_List.getByU(toList, (function (toItem) {
                  if (Curry._2(compare, fromItem, toItem.id)) {
                    return true;
                  } else {
                    return Belt_Option.mapWithDefault(toItem.oldId, false, (function (__x) {
                                  return Curry._2(compare, fromItem, __x);
                                }));
                  }
                }));
          if (match !== undefined) {
            return ;
          } else {
            return {
                    NAME: "Remove",
                    VAL: fromItem
                  };
          }
        }));
  return Belt_List.concat(removes, adds);
}

function diffStringListWithListOfItemsWithOldIds(fromList, toList) {
  return diffListWithListOfItemsWithOldIds(fromList, toList, (function (prim0, prim1) {
                return prim0 === prim1;
              }));
}

function diffOption(from, to_) {
  if (from === undefined) {
    if (to_ !== undefined) {
      return {
              hd: {
                NAME: "Update",
                VAL: Caml_option.valFromOption(to_)
              },
              tl: /* [] */0
            };
    } else {
      return /* [] */0;
    }
  }
  if (to_ === undefined) {
    return /* [] */0;
  }
  var to_$1 = Caml_option.valFromOption(to_);
  if (Caml_obj.equal(Caml_option.valFromOption(from), to_$1)) {
    return /* [] */0;
  } else {
    return {
            hd: {
              NAME: "Update",
              VAL: to_$1
            },
            tl: /* [] */0
          };
  }
}

function diffMapString(from, to) {
  var adds = Belt_MapString.valuesToArray(Belt_MapString.mapWithKeyU(Belt_MapString.keepU(to, (function (key, _value) {
                  return !Belt_MapString.has(from, key);
                })), (function (key, value) {
              return {
                      NAME: "Add",
                      VAL: [
                        key,
                        value
                      ]
                    };
            })));
  var removes = Belt_MapString.valuesToArray(Belt_MapString.mapWithKeyU(Belt_MapString.keepU(from, (function (key, _value) {
                  return !Belt_MapString.has(to, key);
                })), (function (key, value) {
              return {
                      NAME: "Remove",
                      VAL: [
                        key,
                        value
                      ]
                    };
            })));
  var updates = Belt_MapString.valuesToArray(Belt_MapString.mapWithKeyU(Belt_MapString.keepU(to, (function (key, to) {
                  return Belt_Option.mapWithDefault(Belt_MapString.get(from, key), false, (function (from) {
                                return Caml_obj.notequal(from, to);
                              }));
                })), (function (key, to) {
              return {
                      NAME: "Update",
                      VAL: [
                        key,
                        Belt_MapString.getExn(from, key),
                        to
                      ]
                    };
            })));
  return Belt_Array.concatMany([
              adds,
              removes,
              updates
            ]);
}

function diffEventProperties(fromProperties, toProperties, toMappedProperties, eventId) {
  var addsAndConverts = Belt_List.keepMapU(toProperties, (function (toProperty) {
          var toPropertyId = ModelUtils.getPropertyId(toProperty);
          var toPropertyIds = Belt_Option.mapWithDefault(Curry._2(TrackingPlanMappedModel.Properties.get, toMappedProperties, toPropertyId), [toPropertyId], (function (property) {
                  return Belt_Array.concat([property.id], Belt_Option.mapWithDefault(property.globalRequirementsMetadata, [], (function (metadata) {
                                    return [metadata.fromLocalItemId];
                                  })));
                }));
          var match = Belt_List.getByU(fromProperties, (function (fromProperty) {
                  if (fromProperty.TAG === /* PropertyRef */0) {
                    return toPropertyIds.includes(fromProperty._0.id);
                  } else {
                    return false;
                  }
                }));
          if (match !== undefined) {
            return ;
          } else {
            return {
                    NAME: "Add",
                    VAL: toProperty
                  };
          }
        }));
  var removes = Belt_List.keepMapU(fromProperties, (function (fromProperty) {
          var match = Belt_List.getByU(toProperties, (function (toProperty) {
                  var toPropertyId = ModelUtils.getPropertyId(toProperty);
                  var toPropertyIds = Belt_Option.mapWithDefault(Curry._2(TrackingPlanMappedModel.Properties.get, toMappedProperties, toPropertyId), [toPropertyId], (function (property) {
                          return Belt_Array.concat([property.id], Belt_Option.mapWithDefault(property.globalRequirementsMetadata, [], (function (metadata) {
                                            return [metadata.fromLocalItemId];
                                          })));
                        }));
                  if (fromProperty.TAG === /* PropertyRef */0) {
                    return toPropertyIds.includes(fromProperty._0.id);
                  } else {
                    return false;
                  }
                }));
          if (match !== undefined) {
            return ;
          }
          var fromPropertyId;
          fromPropertyId = fromProperty.TAG === /* PropertyRef */0 ? fromProperty._0.id : undefined;
          var match$1 = Curry._2(TrackingPlanMappedModel.Properties.getBy, toMappedProperties, (function (param) {
                  var globalRequirementsMetadata = param.globalRequirementsMetadata;
                  if (globalRequirementsMetadata !== undefined && Caml_obj.equal(fromPropertyId, globalRequirementsMetadata.fromLocalItemId)) {
                    return true;
                  } else {
                    return false;
                  }
                }));
          if (match$1 !== undefined) {
            return ;
          } else {
            return {
                    NAME: "Remove",
                    VAL: fromProperty
                  };
          }
        }));
  var diffPropertyRefs = Belt_List.concat(removes, addsAndConverts);
  var addedAndRemovedRefActions = BeltListExtensions.flatMap(diffPropertyRefs, (function (change) {
          if (typeof change !== "object") {
            return /* [] */0;
          }
          var variant = change.NAME;
          if (variant === "Add") {
            var propertyRef = change.VAL;
            if (propertyRef.TAG !== /* PropertyRef */0) {
              return /* [] */0;
            }
            var propertyRef$1 = propertyRef._0;
            var pinnedValue = propertyRef$1.pinnedValue;
            return Belt_List.concat({
                        hd: {
                          NAME: "AddPropertyRef",
                          VAL: [
                            eventId,
                            propertyRef$1.id
                          ]
                        },
                        tl: /* [] */0
                      }, pinnedValue !== undefined ? ({
                            hd: {
                              NAME: "UpdatePropertyPinnedValue",
                              VAL: [
                                eventId,
                                propertyRef$1.id,
                                pinnedValue
                              ]
                            },
                            tl: /* [] */0
                          }) : /* [] */0);
          }
          if (variant !== "Remove") {
            return /* [] */0;
          }
          var propertyRef$2 = change.VAL;
          if (propertyRef$2.TAG === /* PropertyRef */0) {
            return {
                    hd: {
                      NAME: "RemovePropertyRefV3",
                      VAL: [
                        eventId,
                        propertyRef$2._0.id
                      ]
                    },
                    tl: /* [] */0
                  };
          } else {
            return /* [] */0;
          }
        }));
  var updatedRefActions = Belt_List.keepMap(toProperties, (function (toProperty) {
          if (toProperty.TAG !== /* PropertyRef */0) {
            return ;
          }
          var toPropertyRef = toProperty._0;
          var maybeFromPropertyRef = Belt_List.head(Belt_List.keepMap(fromProperties, (function (fromProperty) {
                      if (fromProperty.TAG !== /* PropertyRef */0) {
                        return ;
                      }
                      var fromPropertyRef = fromProperty._0;
                      if (fromPropertyRef.id === toPropertyRef.id) {
                        return fromPropertyRef;
                      }
                      var match = Curry._2(TrackingPlanMappedModel.Properties.get, toMappedProperties, toPropertyRef.id);
                      if (match === undefined) {
                        return ;
                      }
                      var match$1 = match.globalRequirementsMetadata;
                      if (match$1 !== undefined && fromPropertyRef.id === match$1.fromLocalItemId) {
                        return fromPropertyRef;
                      }
                      
                    })));
          return Belt_Option.flatMap(maybeFromPropertyRef, (function (fromPropertyRef) {
                        var match = fromPropertyRef.pinnedValue;
                        var match$1 = toPropertyRef.pinnedValue;
                        var toPinnedValue;
                        if (match !== undefined) {
                          if (match$1 === undefined) {
                            return {
                                    NAME: "RemovePropertyPinnedValue",
                                    VAL: [
                                      eventId,
                                      toPropertyRef.id
                                    ]
                                  };
                          }
                          if (Caml_obj.equal(match, match$1)) {
                            return ;
                          }
                          toPinnedValue = match$1;
                        } else {
                          if (match$1 === undefined) {
                            return ;
                          }
                          toPinnedValue = match$1;
                        }
                        return {
                                NAME: "UpdatePropertyPinnedValue",
                                VAL: [
                                  eventId,
                                  toPropertyRef.id,
                                  toPinnedValue
                                ]
                              };
                      }));
        }));
  return Belt_List.concat(addedAndRemovedRefActions, updatedRefActions);
}

function diffEventPropertyGroups(fromPropertyBundles, toPropertyBundles, toMappedPropertyBundles, eventId) {
  var addsAndConverts = Belt_List.keepMapU(toPropertyBundles, (function (toPropertyBundle) {
          var toPropertyBundleIds = Belt_Option.mapWithDefault(Curry._2(TrackingPlanMappedModel.PropertyBundles.get, toMappedPropertyBundles, toPropertyBundle.id), [toPropertyBundle.id], (function (propertyBundle) {
                  return Belt_Array.concat([propertyBundle.id], Belt_Option.mapWithDefault(propertyBundle.globalRequirementsMetadata, [], (function (metadata) {
                                    return [metadata.fromLocalItemId];
                                  })));
                }));
          var match = Belt_List.getByU(fromPropertyBundles, (function (fromPropertyBundle) {
                  return toPropertyBundleIds.includes(fromPropertyBundle.id);
                }));
          if (match !== undefined) {
            return ;
          } else {
            return {
                    NAME: "add",
                    VAL: toPropertyBundle
                  };
          }
        }));
  var removes = Belt_List.keepMapU(fromPropertyBundles, (function (fromPropertyBundle) {
          var match = Belt_List.getByU(toPropertyBundles, (function (toPropertyBundle) {
                  var toPropertyBundleIds = Belt_Option.mapWithDefault(Curry._2(TrackingPlanMappedModel.PropertyBundles.get, toMappedPropertyBundles, toPropertyBundle.id), [toPropertyBundle.id], (function (propertyBundle) {
                          return Belt_Array.concat([propertyBundle.id], Belt_Option.mapWithDefault(propertyBundle.globalRequirementsMetadata, [], (function (metadata) {
                                            return [metadata.fromLocalItemId];
                                          })));
                        }));
                  return toPropertyBundleIds.includes(fromPropertyBundle.id);
                }));
          if (match !== undefined) {
            return ;
          } else {
            return {
                    NAME: "remove",
                    VAL: fromPropertyBundle
                  };
          }
        }));
  var diffPropertyBundleRefs = Belt_List.concat(removes, addsAndConverts);
  return Belt_List.keepMapU(diffPropertyBundleRefs, (function (change) {
                if (change.NAME === "remove") {
                  return {
                          NAME: "RemovePropertyGroupFromEvent",
                          VAL: [
                            eventId,
                            change.VAL.id
                          ]
                        };
                } else {
                  return {
                          NAME: "AddPropertyGroupToEvent",
                          VAL: [
                            eventId,
                            change.VAL.id
                          ]
                        };
                }
              }));
}

function diffEventPropertyWhitelist(fromPropertyWhitelist, toPropertyWhitelist, eventId) {
  return Belt_List.keepMap(diffList(fromPropertyWhitelist, toPropertyWhitelist, Caml_obj.equal), (function (change) {
                if (typeof change !== "object") {
                  return ;
                }
                var variant = change.NAME;
                if (variant === "Add") {
                  var match = change.VAL;
                  return {
                          NAME: "AddPropertyToWhitelist",
                          VAL: [
                            eventId,
                            match[1],
                            match[0]
                          ]
                        };
                }
                if (variant !== "Remove") {
                  return ;
                }
                var match$1 = change.VAL;
                return {
                        NAME: "RemovePropertyFromWhitelist",
                        VAL: [
                          eventId,
                          match$1[1],
                          match$1[0]
                        ]
                      };
              }));
}

function diffEventGroupTypeIds(fromEventGroupTypeIds, toEventGroupTypeIds, eventId) {
  return Belt_List.fromArray(Belt_Array.keepMap(diffStringArray(fromEventGroupTypeIds, toEventGroupTypeIds), (function (change) {
                    if (typeof change !== "object") {
                      return ;
                    }
                    var variant = change.NAME;
                    if (variant === "Add") {
                      return {
                              NAME: "AddGroupTypeToLogEvent",
                              VAL: [
                                eventId,
                                change.VAL
                              ]
                            };
                    } else if (variant === "Remove") {
                      return {
                              NAME: "RemoveGroupTypeFromLogEvent",
                              VAL: [
                                eventId,
                                change.VAL
                              ]
                            };
                    } else {
                      return ;
                    }
                  })));
}

function diffUserGroupTypeIds(fromUserGroupTypeIdsWithArchive, toUserGroupTypeIdsWithArchive, eventId) {
  return Belt_List.fromArray(Belt_Array.keepMap(diffStringArray(fromUserGroupTypeIdsWithArchive, toUserGroupTypeIdsWithArchive), (function (change) {
                    if (typeof change !== "object") {
                      return ;
                    }
                    var variant = change.NAME;
                    if (variant === "Add") {
                      return {
                              NAME: "AddUserToGroup",
                              VAL: [
                                eventId,
                                change.VAL
                              ]
                            };
                    } else if (variant === "Remove") {
                      return {
                              NAME: "RemoveUserFromGroup",
                              VAL: [
                                eventId,
                                change.VAL
                              ]
                            };
                    } else {
                      return ;
                    }
                  })));
}

function diffEventTypes(fromEventTypes, toEventTypes, eventId) {
  return Belt_List.map(diffStringList(fromEventTypes, toEventTypes), (function (change) {
                if (change.NAME === "Remove") {
                  return {
                          NAME: "RemoveEventType",
                          VAL: [
                            eventId,
                            change.VAL
                          ]
                        };
                } else {
                  return {
                          NAME: "AddEventType",
                          VAL: [
                            eventId,
                            change.VAL
                          ]
                        };
                }
              }));
}

function diffEventTags(fromTags, toTags, eventId) {
  return Belt_List.map(diffStringList(fromTags, toTags), (function (change) {
                if (change.NAME === "Remove") {
                  return {
                          NAME: "RemoveTag",
                          VAL: [
                            eventId,
                            change.VAL
                          ]
                        };
                } else {
                  return {
                          NAME: "AddTag",
                          VAL: [
                            eventId,
                            change.VAL
                          ]
                        };
                }
              }));
}

function diffEventSources(fromSources, toSources, toDestinations, eventId) {
  var addedAndRemoved = Belt_List.reverse(Belt_List.map(diffList(fromSources, toSources, (function (a, b) {
                  return a.id === b.id;
                })), (function (change) {
              if (change.NAME === "Remove") {
                return {
                        NAME: "ExcludeEventFromSourceV2",
                        VAL: [
                          eventId,
                          change.VAL.id
                        ]
                      };
              }
              var addedIncludedSource = change.VAL;
              return {
                      NAME: "IncludeEventInSourceV2",
                      VAL: [
                        eventId,
                        addedIncludedSource.id,
                        Belt_List.flatten(Belt_List.keepMap(toDestinations, (function (includeDestinations) {
                                    var match = includeDestinations.VAL;
                                    if (match[0] === addedIncludedSource.id) {
                                      return match[1];
                                    }
                                    
                                  }))),
                        addedIncludedSource.includeInCodegen
                      ]
                    };
            })));
  var updatedIncludedIncodegen = Belt_List.mapU(Belt_List.keepU(toSources, (function (toSource) {
              return Belt_List.someU(fromSources, (function (fromSource) {
                            if (fromSource.id === toSource.id) {
                              return fromSource.includeInCodegen !== toSource.includeInCodegen;
                            } else {
                              return false;
                            }
                          }));
            })), (function (updatedSource) {
          return {
                  NAME: "ToggleIncludeEventInCodegenForSource",
                  VAL: [
                    eventId,
                    updatedSource.id,
                    updatedSource.includeInCodegen
                  ]
                };
        }));
  var updatedInspectorValidation = Belt_List.mapU(Belt_List.keepU(toSources, (function (toSource) {
              return Belt_List.someU(fromSources, (function (fromSource) {
                            if (fromSource.id === toSource.id) {
                              return Caml_obj.notequal(fromSource.inspectorValidation, toSource.inspectorValidation);
                            } else {
                              return false;
                            }
                          }));
            })), (function (updatedSource) {
          return {
                  NAME: "ConfigureInspectorValidation",
                  VAL: [
                    {
                      hd: eventId,
                      tl: /* [] */0
                    },
                    Belt_MapString.fromArray([[
                            updatedSource.id,
                            updatedSource.inspectorValidation
                          ]])
                  ]
                };
        }));
  return Belt_List.concatMany([
              addedAndRemoved,
              updatedIncludedIncodegen,
              updatedInspectorValidation
            ]);
}

function diffEventDestinations(fromDestinations, toDestinations, eventId) {
  var diff = Belt_List.map(fromDestinations, (function (fromDestination) {
          return [
                  fromDestination,
                  Belt_List.getBy(toDestinations, (function (toDestination) {
                          return Caml_obj.equal(fromDestination.VAL[0], toDestination.VAL[0]);
                        }))
                ];
        }));
  var diff$1 = Belt_List.concat(Belt_List.keepMap(toDestinations, (function (toDestination) {
              if (Belt_List.some(fromDestinations, (function (fromDestination) {
                        return Caml_obj.equal(fromDestination.VAL[0], toDestination.VAL[0]);
                      }))) {
                return ;
              } else {
                return [
                        undefined,
                        toDestination
                      ];
              }
            })), diff);
  var diff$2 = Belt_List.keepMap(diff$1, (function (param) {
          var maybeTo = param[1];
          var maybeFrom = param[0];
          if (maybeFrom !== undefined) {
            var match = maybeFrom.VAL;
            var fromDestinations = match[1];
            var sourceId = match[0];
            if (maybeTo !== undefined) {
              return [
                      sourceId,
                      diffStringList(fromDestinations, maybeTo.VAL[1])
                    ];
            } else {
              return [
                      sourceId,
                      diffStringList(fromDestinations, /* [] */0)
                    ];
            }
          }
          if (maybeTo === undefined) {
            return ;
          }
          var match$1 = maybeTo.VAL;
          return [
                  match$1[0],
                  diffStringList(/* [] */0, match$1[1])
                ];
        }));
  return BeltListExtensions.flatMap(diff$2, (function (param) {
                var sourceId = param[0];
                return Belt_List.map(param[1], (function (change) {
                              if (change.NAME === "Remove") {
                                return {
                                        NAME: "ExcludeDestinationFromEventSource",
                                        VAL: [
                                          eventId,
                                          sourceId,
                                          change.VAL
                                        ]
                                      };
                              } else {
                                return {
                                        NAME: "IncludeDestinationInEventSource",
                                        VAL: [
                                          eventId,
                                          sourceId,
                                          change.VAL
                                        ]
                                      };
                              }
                            }));
              }));
}

function diffEventId(fromId, toEvent, toGlobalRequirements) {
  var match = toEvent.globalRequirementsMetadata;
  if (toGlobalRequirements === undefined) {
    return /* [] */0;
  }
  if (match === undefined) {
    return /* [] */0;
  }
  if (fromId !== match.fromLocalItemId) {
    return /* [] */0;
  }
  var globalWorkspaceId = match.globalWorkspaceId;
  var metadata = toGlobalRequirements.metadata;
  var globalToEvent = Belt_List.getByU(toGlobalRequirements.events, (function ($$event) {
          return $$event.id === toEvent.id;
        }));
  return Belt_Option.mapWithDefault(globalToEvent, /* [] */0, (function (globalToEvent) {
                return {
                        hd: {
                          NAME: "ConvertEventToGlobalEventV2",
                          VAL: [
                            fromId,
                            toEvent.id,
                            globalWorkspaceId,
                            metadata,
                            globalToEvent
                          ]
                        },
                        tl: /* [] */0
                      };
              }));
}

function diffEventName(fromName, toName, eventId) {
  if (Caml_obj.notequal(fromName, toName)) {
    return {
            hd: {
              NAME: "UpdateEventName",
              VAL: [
                eventId,
                toName
              ]
            },
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function diffEventUniqueName(fromName, toName, eventId) {
  if (Caml_obj.notequal(fromName, toName)) {
    return {
            hd: {
              NAME: "UpdateEventUniqueNameV2",
              VAL: [
                eventId,
                Belt_Option.getWithDefault(toName, "")
              ]
            },
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function diffEventDescription(fromDescription, toDescription, eventId) {
  if (Caml_obj.notequal(fromDescription, toDescription)) {
    return {
            hd: {
              NAME: "UpdateEventDescription",
              VAL: [
                eventId,
                toDescription
              ]
            },
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function diffEventTriggerDescription(fromDescription, toDescription, eventId, variantId, triggerId) {
  if (Caml_obj.notequal(fromDescription, toDescription)) {
    return {
            hd: Belt_Option.mapWithDefault(variantId, {
                  NAME: "UpdateTriggerDescription",
                  VAL: [
                    eventId,
                    triggerId,
                    toDescription
                  ]
                }, (function (variantId) {
                    return {
                            NAME: "UpdateEventVariantTriggerDescription",
                            VAL: [
                              {
                                baseEventId: eventId,
                                variantId: variantId
                              },
                              triggerId,
                              toDescription
                            ]
                          };
                  })),
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function diffEventTriggerSources(fromSources, toSources, eventId, variantId, triggerId) {
  if (fromSources) {
    if (toSources) {
      var toIds = toSources._0;
      var fromIds = fromSources._0;
      if (Caml_obj.notequal(fromIds, toIds)) {
        return Belt_List.fromArray(Belt_Array.concat(Belt_Array.map(Belt_Array.keep(fromIds, (function (fromId) {
                                  return !toIds.includes(fromId);
                                })), (function (id) {
                              return Belt_Option.mapWithDefault(variantId, {
                                          NAME: "RemoveTriggerSource",
                                          VAL: [
                                            eventId,
                                            triggerId,
                                            id
                                          ]
                                        }, (function (variantId) {
                                            return {
                                                    NAME: "RemoveEventVariantTriggerSource",
                                                    VAL: [
                                                      {
                                                        baseEventId: eventId,
                                                        variantId: variantId
                                                      },
                                                      triggerId,
                                                      id
                                                    ]
                                                  };
                                          }));
                            })), Belt_Array.map(Belt_Array.keep(toIds, (function (fromId) {
                                  return !fromIds.includes(fromId);
                                })), (function (id) {
                              return Belt_Option.mapWithDefault(variantId, {
                                          NAME: "AddTriggerSource",
                                          VAL: [
                                            eventId,
                                            triggerId,
                                            id
                                          ]
                                        }, (function (variantId) {
                                            return {
                                                    NAME: "AddEventVariantTriggerSource",
                                                    VAL: [
                                                      {
                                                        baseEventId: eventId,
                                                        variantId: variantId
                                                      },
                                                      triggerId,
                                                      id
                                                    ]
                                                  };
                                          }));
                            }))));
      } else {
        return /* [] */0;
      }
    }
    
  } else if (!toSources) {
    return /* [] */0;
  }
  return {
          hd: Belt_Option.mapWithDefault(variantId, {
                NAME: "UpdateTriggerSources",
                VAL: [
                  eventId,
                  triggerId,
                  toSources
                ]
              }, (function (variantId) {
                  return {
                          NAME: "UpdateEventVariantTriggerSources",
                          VAL: [
                            {
                              baseEventId: eventId,
                              variantId: variantId
                            },
                            triggerId,
                            toSources
                          ]
                        };
                })),
          tl: /* [] */0
        };
}

function diffEventTriggerContent(fromContent, toContent, eventId, variantId, triggerId) {
  if (Caml_obj.notequal(fromContent, toContent)) {
    return {
            hd: Belt_Option.mapWithDefault(variantId, {
                  NAME: "UpdateTriggerContent",
                  VAL: [
                    eventId,
                    triggerId,
                    toContent
                  ]
                }, (function (variantId) {
                    return {
                            NAME: "UpdateEventVariantTriggerContent",
                            VAL: [
                              {
                                baseEventId: eventId,
                                variantId: variantId
                              },
                              triggerId,
                              toContent
                            ]
                          };
                  })),
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function diffEventTrigger(fromTrigger, toTrigger, eventId, variantId) {
  if (Caml_obj.notequal(fromTrigger, toTrigger)) {
    return Belt_List.concatMany([
                diffEventTriggerDescription(fromTrigger.description, toTrigger.description, eventId, variantId, fromTrigger.id),
                diffEventTriggerSources(fromTrigger.sources, toTrigger.sources, eventId, variantId, fromTrigger.id),
                diffEventTriggerContent(fromTrigger.content, toTrigger.content, eventId, variantId, fromTrigger.id)
              ]);
  } else {
    return /* [] */0;
  }
}

function diffEventTriggers(fromTriggers, toTriggers, variantId, eventId) {
  var newAndModified = Belt_List.flatten(Belt_List.map(Belt_List.fromArray(toTriggers), (function (toTrigger) {
              return Belt_Option.mapWithDefault(Belt_Array.getBy(fromTriggers, (function (trigger) {
                                return trigger.id === toTrigger.id;
                              })), {
                          hd: Belt_Option.mapWithDefault(variantId, {
                                NAME: "CreateEventTrigger",
                                VAL: [
                                  eventId,
                                  toTrigger.id,
                                  toTrigger.sources,
                                  toTrigger.description,
                                  toTrigger.content
                                ]
                              }, (function (variantId) {
                                  return {
                                          NAME: "CreateEventVariantTrigger",
                                          VAL: [
                                            {
                                              baseEventId: eventId,
                                              variantId: variantId
                                            },
                                            toTrigger.id,
                                            toTrigger.sources,
                                            toTrigger.description,
                                            toTrigger.content
                                          ]
                                        };
                                })),
                          tl: /* [] */0
                        }, (function (fromTrigger) {
                            return diffEventTrigger(fromTrigger, toTrigger, eventId, variantId);
                          }));
            })));
  var removed = Belt_List.fromArray(Belt_Array.map(Belt_Array.keep(fromTriggers, (function (fromTrigger) {
                  return Belt_Array.every(toTriggers, (function (param) {
                                return param.id !== fromTrigger.id;
                              }));
                })), (function (param) {
              var id = param.id;
              return Belt_Option.mapWithDefault(variantId, {
                          NAME: "RemoveEventTrigger",
                          VAL: [
                            eventId,
                            id
                          ]
                        }, (function (variantId) {
                            return {
                                    NAME: "RemoveEventVariantTrigger",
                                    VAL: [
                                      {
                                        baseEventId: eventId,
                                        variantId: variantId
                                      },
                                      id
                                    ]
                                  };
                          }));
            })));
  return Belt_List.concat(newAndModified, removed);
}

function diffEventVariantName(param, param$1) {
  return /* [] */0;
}

function diffEventVariantDescription(from, to) {
  if (from.description !== to.description) {
    return {
            hd: {
              NAME: "UpdateEventVariantDescription",
              VAL: [
                TrackingPlanModel.EventVariant.makeVariantIdentifier(to),
                to.description
              ]
            },
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function diffEventVariantSourceOverrides(from, to) {
  var sourceOverrideAdded = Belt_Array.mapU(Belt_MapString.keysToArray(Belt_MapString.keepU(to.sourceOverrides, (function (sourceId, _override) {
                  return !Belt_MapString.has(from.sourceOverrides, sourceId);
                }))), (function (sourceId) {
          return {
                  NAME: "SetSourceOverrideOnVariant",
                  VAL: [
                    TrackingPlanModel.EventVariant.makeVariantIdentifier(to),
                    sourceId,
                    /* Removed */0
                  ]
                };
        }));
  var sourceOverrideCleared = Belt_Array.mapU(Belt_MapString.keysToArray(Belt_MapString.keepU(from.sourceOverrides, (function (sourceId, _override) {
                  return !Belt_MapString.has(to.sourceOverrides, sourceId);
                }))), (function (sourceId) {
          return {
                  NAME: "ClearSourceOverrideOnVariant",
                  VAL: [
                    TrackingPlanModel.EventVariant.makeVariantIdentifier(to),
                    sourceId
                  ]
                };
        }));
  return Belt_List.fromArray(Belt_Array.concat(sourceOverrideAdded, sourceOverrideCleared));
}

function diffEventVariantPropertyOverride(from, to, propertyId, variantIdentifier) {
  var match = from.pinnedValue;
  var match$1 = to.pinnedValue;
  var tmp;
  var exit = 0;
  var exit$1 = 0;
  if (match) {
    if (match$1) {
      exit$1 = 2;
    } else {
      tmp = [{
          NAME: "ClearPropertyPinnedValueFromEventVariant",
          VAL: [
            variantIdentifier,
            propertyId
          ]
        }];
    }
  } else if (match$1) {
    exit$1 = 2;
  } else {
    tmp = [];
  }
  if (exit$1 === 2) {
    if (match$1._0 !== undefined) {
      if (match && Caml_obj.equal(match._0, match$1._0)) {
        tmp = [];
      } else {
        exit = 1;
      }
    } else {
      tmp = [];
    }
  }
  if (exit === 1) {
    tmp = [{
        NAME: "UpdatePropertyPinnedValueOnEventVariant",
        VAL: [
          variantIdentifier,
          propertyId,
          match$1._0
        ]
      }];
  }
  var match$2 = from.regexValidation;
  var match$3 = to.regexValidation;
  var tmp$1;
  var exit$2 = 0;
  var to$1;
  if (match$2) {
    if (match$3) {
      var to$2 = match$3._0;
      if (Caml_obj.equal(match$2._0, to$2)) {
        tmp$1 = [];
      } else {
        to$1 = to$2;
        exit$2 = 1;
      }
    } else {
      tmp$1 = [{
          NAME: "ClearPropertyRegexOverrideFromEventVariant",
          VAL: [
            variantIdentifier,
            propertyId
          ]
        }];
    }
  } else if (match$3) {
    to$1 = match$3._0;
    exit$2 = 1;
  } else {
    tmp$1 = [];
  }
  if (exit$2 === 1) {
    tmp$1 = [{
        NAME: "UpdatePropertyRegexOverrideOnEventVariant",
        VAL: [
          variantIdentifier,
          propertyId,
          to$1
        ]
      }];
  }
  var match$4 = from.absence;
  var match$5 = to.absence;
  var tmp$2;
  var exit$3 = 0;
  if (match$4) {
    if (match$5 && match$4._0 === match$5._0) {
      tmp$2 = [];
    } else {
      exit$3 = 1;
    }
  } else if (match$5) {
    exit$3 = 1;
  } else {
    tmp$2 = [];
  }
  if (exit$3 === 1) {
    tmp$2 = [{
        NAME: "SetPropertyAbsenceOnVariant",
        VAL: [
          variantIdentifier,
          propertyId,
          match$5
        ]
      }];
  }
  var match$6 = from.allowedValues;
  var match$7 = to.allowedValues;
  var tmp$3;
  if (match$6) {
    if (match$7) {
      var to$3 = match$7._0;
      var from$1 = match$6._0;
      tmp$3 = Caml_obj.equal(from$1, to$3) ? [] : Belt_Array.map(diffMapString(from$1, to$3), (function (change) {
                var exit = 0;
                var value;
                var value$1;
                var variant = change.NAME;
                if (variant === "Remove") {
                  return {
                          NAME: "ClearEventVariantSpecificPropertyValueOverride",
                          VAL: [
                            variantIdentifier,
                            propertyId,
                            {
                              NAME: "StringLit",
                              VAL: change.VAL[0]
                            }
                          ]
                        };
                }
                if (variant === "Update") {
                  var match = change.VAL;
                  var value$2 = match[0];
                  if (match[2]) {
                    value$1 = value$2;
                    exit = 2;
                  } else {
                    value = value$2;
                    exit = 1;
                  }
                } else {
                  var match$1 = change.VAL;
                  var value$3 = match$1[0];
                  if (match$1[1]) {
                    value$1 = value$3;
                    exit = 2;
                  } else {
                    value = value$3;
                    exit = 1;
                  }
                }
                switch (exit) {
                  case 1 :
                      return {
                              NAME: "OverrideEventVariantSpecificPropertyValueToBeDisallowed",
                              VAL: [
                                variantIdentifier,
                                propertyId,
                                {
                                  NAME: "StringLit",
                                  VAL: value
                                }
                              ]
                            };
                  case 2 :
                      return {
                              NAME: "OverrideEventVariantSpecificPropertyValueToBeAllowedOnAllSources",
                              VAL: [
                                variantIdentifier,
                                propertyId,
                                {
                                  NAME: "StringLit",
                                  VAL: value$1
                                }
                              ]
                            };
                  
                }
              }));
    } else {
      tmp$3 = [];
    }
  } else {
    tmp$3 = match$7 ? Belt_Array.map(Belt_MapString.toArray(match$7._0), (function (param) {
              var value = param[0];
              if (param[1]) {
                return {
                        NAME: "OverrideEventVariantSpecificPropertyValueToBeAllowedOnAllSources",
                        VAL: [
                          variantIdentifier,
                          propertyId,
                          {
                            NAME: "StringLit",
                            VAL: value
                          }
                        ]
                      };
              } else {
                return {
                        NAME: "OverrideEventVariantSpecificPropertyValueToBeDisallowed",
                        VAL: [
                          variantIdentifier,
                          propertyId,
                          {
                            NAME: "StringLit",
                            VAL: value
                          }
                        ]
                      };
              }
            })) : [];
  }
  return Belt_Array.concatMany([
              tmp,
              tmp$1,
              tmp$2,
              tmp$3
            ]);
}

function diffEventVariantPropertyOverrides(from, to) {
  var variantIdentifier = TrackingPlanModel.EventVariant.makeVariantIdentifier(to);
  var added = Belt_Array.concatMany(Belt_MapString.valuesToArray(Belt_MapString.mapWithKeyU(Belt_MapString.keepU(to.propertyOverrides, (function (propertyId, _override) {
                      return !Belt_MapString.has(from.propertyOverrides, propertyId);
                    })), (function (propertyId, override) {
                  if (override) {
                    return Belt_Array.concat([{
                                  NAME: "AddPropertyToEventVariant",
                                  VAL: [
                                    variantIdentifier,
                                    propertyId
                                  ]
                                }], diffEventVariantPropertyOverride({
                                    pinnedValue: /* NoneOverride */0,
                                    absence: /* NoneOverride */0,
                                    allowedValues: /* NoneOverride */0,
                                    regexValidation: /* NoneOverride */0
                                  }, override._0, propertyId, variantIdentifier));
                  } else {
                    return [{
                              NAME: "RemovePropertyFromEventVariant",
                              VAL: [
                                variantIdentifier,
                                propertyId
                              ]
                            }];
                  }
                }))));
  var changed = Belt_Array.concatMany(Belt_Array.mapU(Belt_Array.keepMapU(Belt_MapString.valuesToArray(Belt_MapString.mapWithKeyU(to.propertyOverrides, (function (propertyId, toOverride) {
                          return [
                                  propertyId,
                                  Belt_MapString.get(from.propertyOverrides, propertyId),
                                  toOverride
                                ];
                        }))), (function (param) {
                  var toOverride = param[2];
                  var fromOverride = param[1];
                  if (fromOverride !== undefined && Caml_obj.notequal(fromOverride, toOverride)) {
                    return [
                            param[0],
                            fromOverride,
                            toOverride
                          ];
                  }
                  
                })), (function (param) {
              var toOverride = param[2];
              var fromOverride = param[1];
              var propertyId = param[0];
              if (!toOverride) {
                return [{
                          NAME: "RemovePropertyFromEventVariant",
                          VAL: [
                            variantIdentifier,
                            propertyId
                          ]
                        }];
              }
              if (fromOverride) {
                return diffEventVariantPropertyOverride(fromOverride._0, toOverride._0, propertyId, variantIdentifier);
              }
              var toOverride$1 = toOverride._0;
              var match = toOverride$1.pinnedValue;
              var tmp;
              if (match) {
                var literal = match._0;
                tmp = literal !== undefined ? [{
                      NAME: "UpdatePropertyPinnedValueOnEventVariant",
                      VAL: [
                        variantIdentifier,
                        propertyId,
                        literal
                      ]
                    }] : [];
              } else {
                tmp = [];
              }
              var regexValidation = toOverride$1.regexValidation;
              var override = toOverride$1.allowedValues;
              var absenceOverride = toOverride$1.absence;
              return Belt_Array.concat(Belt_Array.concat(Belt_Array.concat(Belt_Array.concat([
                                      {
                                        NAME: "ClearEventVariantPropertyOverride",
                                        VAL: [
                                          variantIdentifier,
                                          propertyId
                                        ]
                                      },
                                      {
                                        NAME: "AddPropertyToEventVariant",
                                        VAL: [
                                          variantIdentifier,
                                          propertyId
                                        ]
                                      }
                                    ], tmp), regexValidation ? [{
                                      NAME: "UpdatePropertyRegexOverrideOnEventVariant",
                                      VAL: [
                                        variantIdentifier,
                                        propertyId,
                                        regexValidation._0
                                      ]
                                    }] : []), override ? Belt_Array.map(Belt_MapString.toArray(override._0), (function (param) {
                                      var value = param[0];
                                      if (param[1]) {
                                        return {
                                                NAME: "OverrideEventVariantSpecificPropertyValueToBeAllowedOnAllSources",
                                                VAL: [
                                                  variantIdentifier,
                                                  propertyId,
                                                  {
                                                    NAME: "StringLit",
                                                    VAL: value
                                                  }
                                                ]
                                              };
                                      } else {
                                        return {
                                                NAME: "OverrideEventVariantSpecificPropertyValueToBeDisallowed",
                                                VAL: [
                                                  variantIdentifier,
                                                  propertyId,
                                                  {
                                                    NAME: "StringLit",
                                                    VAL: value
                                                  }
                                                ]
                                              };
                                      }
                                    })) : []), absenceOverride ? [{
                              NAME: "SetPropertyAbsenceOnVariant",
                              VAL: [
                                variantIdentifier,
                                propertyId,
                                /* SomeOverride */{
                                  _0: absenceOverride._0
                                }
                              ]
                            }] : []);
            })));
  var removed = Belt_Array.mapU(Belt_MapString.keysToArray(Belt_MapString.keepU(from.propertyOverrides, (function (propertyId, _override) {
                  return !Belt_MapString.has(to.propertyOverrides, propertyId);
                }))), (function (propertyId) {
          return {
                  NAME: "ClearEventVariantPropertyOverride",
                  VAL: [
                    variantIdentifier,
                    propertyId
                  ]
                };
        }));
  return Belt_List.fromArray(Belt_Array.concatMany([
                  added,
                  changed,
                  removed
                ]));
}

function diffEventVariant(from, to) {
  return Belt_List.concatMany([
              /* [] */0,
              diffEventVariantDescription(from, to),
              diffEventVariantSourceOverrides(from, to),
              diffEventVariantPropertyOverrides(from, to),
              diffEventTriggers(from.triggers, to.triggers, to.id, to.baseEventId)
            ]);
}

function diffEventVariants(fromVariants, toVariants) {
  var newAndModified = Belt_List.flatten(Belt_List.map(Belt_List.fromArray(toVariants), (function (to) {
              return Belt_Option.mapWithDefault(Belt_Array.getBy(fromVariants, (function (variant) {
                                return variant.id === to.id;
                              })), Belt_List.concatMany([
                              {
                                hd: {
                                  NAME: "CreateEventVariant",
                                  VAL: [
                                    TrackingPlanModel.EventVariant.makeVariantIdentifier(to),
                                    to.nameSuffix
                                  ]
                                },
                                tl: /* [] */0
                              },
                              diffEventVariant(TrackingPlanModel.emptyEventVariant(TrackingPlanModel.EventVariant.makeVariantIdentifier(to), to.nameSuffix), to)
                            ]), (function (from) {
                            return diffEventVariant(from, to);
                          }));
            })));
  var removed = Belt_List.fromArray(Belt_Array.mapU(Belt_Array.keep(fromVariants, (function (fromVariant) {
                  return Belt_Array.every(toVariants, (function (param) {
                                return param.id !== fromVariant.id;
                              }));
                })), (function (from) {
              return {
                      NAME: "Archive",
                      VAL: {
                        NAME: "EventVariant",
                        VAL: TrackingPlanModel.EventVariant.makeVariantIdentifier(from)
                      }
                    };
            })));
  return Belt_List.concat(newAndModified, removed);
}

function diffEvent(fromEvent, toEvent, toMappedProperties, toMappedPropertyBundles, toGlobalRequirements) {
  var eventId = toEvent.id;
  return Belt_List.concatMany([
              diffEventId(fromEvent.id, toEvent, toGlobalRequirements),
              diffEventName(fromEvent.name, toEvent.name, eventId),
              diffEventDescription(fromEvent.description, toEvent.description, eventId),
              diffEventUniqueName(fromEvent.uniqueName, toEvent.uniqueName, eventId),
              diffEventTypes(fromEvent.types, toEvent.types, eventId),
              diffEventTags(fromEvent.tags, toEvent.tags, eventId),
              diffEventSources(fromEvent.includeSources, toEvent.includeSources, toEvent.includeDestinations, eventId),
              diffEventDestinations(fromEvent.includeDestinations, toEvent.includeDestinations, eventId),
              diffEventProperties(fromEvent.directPropertyRefs, toEvent.directPropertyRefs, toMappedProperties, eventId),
              diffEventPropertyGroups(fromEvent.propertyBundles, toEvent.propertyBundles, toMappedPropertyBundles, eventId),
              diffEventPropertyWhitelist(fromEvent.propertyWhitelist, toEvent.propertyWhitelist, eventId),
              diffEventGroupTypeIds(fromEvent.eventGroupTypeIdsWithArchive, toEvent.eventGroupTypeIdsWithArchive, eventId),
              diffUserGroupTypeIds(fromEvent.userGroupTypeIdsWithArchive, toEvent.userGroupTypeIdsWithArchive, eventId),
              diffEventTriggers(fromEvent.triggers, toEvent.triggers, undefined, eventId),
              diffEventVariants(fromEvent.variants, toEvent.variants)
            ]);
}

function getNewAndUpdatedEventActions(fromMappedEvents, fromMappedEventsArchive, toMappedProperties, toMappedPropertyBundles, toGlobalRequirements, toEvent) {
  var $$event = Curry._2(TrackingPlanMappedModel.Events.get, fromMappedEvents, toEvent.id);
  var maybeFromEvent;
  if ($$event !== undefined) {
    maybeFromEvent = $$event;
  } else {
    var match = toEvent.globalRequirementsMetadata;
    maybeFromEvent = match !== undefined ? Curry._2(TrackingPlanMappedModel.Events.get, fromMappedEvents, match.fromLocalItemId) : undefined;
  }
  var maybeArchivedEvent = Curry._2(TrackingPlanMappedModel.Events.get, fromMappedEventsArchive, toEvent.id);
  if (maybeFromEvent !== undefined) {
    return diffEvent(maybeFromEvent, toEvent, toMappedProperties, toMappedPropertyBundles, toGlobalRequirements);
  } else if (maybeArchivedEvent !== undefined) {
    return Belt_List.concatMany([
                {
                  hd: {
                    NAME: "Unarchive",
                    VAL: {
                      NAME: "Event",
                      VAL: toEvent.id
                    }
                  },
                  tl: /* [] */0
                },
                diffEvent(maybeArchivedEvent, toEvent, toMappedProperties, toMappedPropertyBundles, toGlobalRequirements)
              ]);
  } else {
    return Belt_List.concatMany([
                {
                  hd: {
                    NAME: "AddEvent",
                    VAL: [
                      toEvent.id,
                      toEvent.name
                    ]
                  },
                  tl: /* [] */0
                },
                diffEvent(TrackingPlanModel.emptyEvent(toEvent.id, ""), toEvent, toMappedProperties, toMappedPropertyBundles, toGlobalRequirements)
              ]);
  }
}

function diffEvents(fromMappedEvents, fromMappedEventsArchive, toMappedEvents, toMappedEventsKeyedByLocalId, toMappedEventsArchiveKeyedByLocalId, toMappedEventsArchive, toGlobalRequirements, toMappedProperties, toMappedPropertyBundles, onError) {
  var getRemovedActions = function (fromEvent) {
    var $$event = Curry._2(TrackingPlanMappedModel.Events.get, toMappedEvents, fromEvent.id);
    var maybeToEvent = $$event !== undefined ? $$event : Js_dict.get(toMappedEventsKeyedByLocalId, fromEvent.id);
    var archivedEvent = Curry._2(TrackingPlanMappedModel.Events.get, toMappedEventsArchive, fromEvent.id);
    var maybeArchivedEvent = archivedEvent !== undefined ? archivedEvent : Js_dict.get(toMappedEventsArchiveKeyedByLocalId, fromEvent.id);
    if (maybeToEvent !== undefined) {
      return /* [] */0;
    } else if (maybeArchivedEvent !== undefined) {
      return Belt_List.concatMany([
                  diffEvent(fromEvent, maybeArchivedEvent, toMappedProperties, toMappedPropertyBundles, toGlobalRequirements),
                  {
                    hd: {
                      NAME: "Archive",
                      VAL: {
                        NAME: "Event",
                        VAL: fromEvent.id
                      }
                    },
                    tl: /* [] */0
                  }
                ]);
    } else {
      Curry._1(onError, "Illegal state in ActionDiff. Couldn't find an archived event: " + fromEvent.name + " (" + fromEvent.id + ")");
      return {
              hd: {
                NAME: "Archive",
                VAL: {
                  NAME: "Event",
                  VAL: fromEvent.id
                }
              },
              tl: /* [] */0
            };
    }
  };
  var newAndModified = Belt_List.flatten(Belt_List.fromArray(Curry._2(TrackingPlanMappedModel.Events.mapToArray, toMappedEvents, (function (param) {
                  return getNewAndUpdatedEventActions(fromMappedEvents, fromMappedEventsArchive, toMappedProperties, toMappedPropertyBundles, toGlobalRequirements, param);
                }))));
  var removed = Belt_List.flatten(Belt_List.fromArray(Curry._2(TrackingPlanMappedModel.Events.mapToArray, fromMappedEvents, getRemovedActions)));
  return Belt_List.concat(newAndModified, removed);
}

function diffPropertyId(fromId, toProperty, globalRequirementsMetadata) {
  var match = toProperty.globalRequirementsMetadata;
  if (globalRequirementsMetadata !== undefined && match !== undefined && fromId === match.fromLocalItemId) {
    return {
            hd: {
              NAME: "ConvertPropertyToGlobalPropertyV2",
              VAL: [
                fromId,
                toProperty.id,
                match.globalWorkspaceId,
                toProperty,
                globalRequirementsMetadata
              ]
            },
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function diffPropertyName(fromName, toName, propertyId) {
  if (Caml_obj.notequal(fromName, toName)) {
    return {
            hd: {
              NAME: "UpdatePropertyName",
              VAL: [
                propertyId,
                toName
              ]
            },
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function diffPropertyUniqueName(fromName, toName, propertyId) {
  if (Caml_obj.notequal(fromName, toName)) {
    return {
            hd: {
              NAME: "UpdatePropertyUniqueName",
              VAL: [
                propertyId,
                Belt_Option.getWithDefault(toName, "")
              ]
            },
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function diffPropertyDescription(fromDescription, toDescription, propertyId) {
  if (Caml_obj.notequal(fromDescription, toDescription)) {
    return {
            hd: {
              NAME: "UpdatePropertyDescription",
              VAL: [
                propertyId,
                toDescription
              ]
            },
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function diffPropertyOptional(fromOptional, toOptional, propertyId) {
  if (Caml_obj.notequal(fromOptional, toOptional)) {
    return {
            hd: {
              NAME: "UpdatePropertyOptional",
              VAL: [
                propertyId,
                toOptional
              ]
            },
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function diffPropertyOptionalWhenInObject(fromOptional, toOptional, propertyId) {
  if (Caml_obj.notequal(fromOptional, toOptional)) {
    return {
            hd: {
              NAME: "UpdatePropertyOptionalWhenInObject",
              VAL: [
                propertyId,
                toOptional
              ]
            },
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function diffPropertyAbsence(fromAbsence, toAbsence, propertyId, fromModel, toModel) {
  var isPropertyIncludedOnEventOrVariantInToModel = function (eventId, propertyId) {
    var property = ModelUtils.resolvePropertyById(propertyId, toModel);
    if (property !== undefined) {
      return ModelUtils.isPropertyOnEventOrEventVariant(eventId, toModel.events, property, toModel.propertyBundles);
    } else {
      return false;
    }
  };
  var getActionsFromSourceAbsenceComparedToAlwaysSent = function (eventId, sourceAbsence) {
    if (!isPropertyIncludedOnEventOrVariantInToModel(eventId, propertyId)) {
      return /* [] */0;
    }
    if (sourceAbsence.TAG !== /* AllSources */0) {
      return Belt_List.fromArray(Belt_Array.map(Belt_MapString.toArray(sourceAbsence._0), (function (param) {
                        var eventSourceAbsence = param[1];
                        var sourceId = param[0];
                        if (eventSourceAbsence) {
                          return {
                                  NAME: "UpdatePropertyAbsence",
                                  VAL: [
                                    propertyId,
                                    {
                                      TAG: /* EventSource */2,
                                      _0: eventId,
                                      _1: sourceId
                                    },
                                    /* SometimesSent */{
                                      _0: eventSourceAbsence._0
                                    }
                                  ]
                                };
                        } else {
                          return {
                                  NAME: "UpdatePropertyAbsence",
                                  VAL: [
                                    propertyId,
                                    {
                                      TAG: /* EventSource */2,
                                      _0: eventId,
                                      _1: sourceId
                                    },
                                    /* NeverSent */0
                                  ]
                                };
                        }
                      })));
    }
    var toDescription = sourceAbsence._0;
    if (toDescription) {
      return {
              hd: {
                NAME: "UpdatePropertyAbsence",
                VAL: [
                  propertyId,
                  {
                    TAG: /* Event */0,
                    _0: eventId
                  },
                  /* SometimesSent */{
                    _0: toDescription._0
                  }
                ]
              },
              tl: /* [] */0
            };
    } else {
      return {
              hd: {
                NAME: "UpdatePropertyAbsence",
                VAL: [
                  propertyId,
                  {
                    TAG: /* Event */0,
                    _0: eventId
                  },
                  /* NeverSent */0
                ]
              },
              tl: /* [] */0
            };
    }
  };
  var exit = 0;
  var toDescription;
  var toAbsence$1;
  if (fromAbsence !== undefined) {
    if (typeof fromAbsence === "number") {
      if (toAbsence === undefined) {
        return /* [] */0;
      }
      if (typeof toAbsence === "number") {
        return /* [] */0;
      }
      if (toAbsence.TAG === /* SometimesSent */0) {
        toDescription = toAbsence._0;
        exit = 2;
      } else {
        toAbsence$1 = toAbsence._0;
        exit = 3;
      }
    } else if (fromAbsence.TAG === /* SometimesSent */0) {
      if (toAbsence === undefined) {
        return /* [] */0;
      }
      var fromDescription = fromAbsence._0;
      if (typeof toAbsence === "number") {
        exit = 1;
      } else {
        if (toAbsence.TAG === /* SometimesSent */0) {
          var toDescription$1 = toAbsence._0;
          if (fromDescription === toDescription$1) {
            return /* [] */0;
          } else {
            return {
                    hd: {
                      NAME: "UpdatePropertyAbsence",
                      VAL: [
                        propertyId,
                        /* All */0,
                        /* SometimesSent */{
                          _0: toDescription$1
                        }
                      ]
                    },
                    tl: /* [] */0
                  };
          }
        }
        var toAbsence$2 = toAbsence._0;
        var eventsSendingPropertyInFromModel = ModelUtils.eventsSendingPropertyFromSources(fromModel, undefined, propertyId);
        var mixedFromAbsence = Belt_MapString.fromArray(Belt_List.toArray(Belt_List.map(eventsSendingPropertyInFromModel, (function ($$event) {
                        var allSources = {
                          TAG: /* AllSources */0,
                          _0: /* SometimesSent */{
                            _0: fromDescription
                          }
                        };
                        return [
                                $$event.id,
                                allSources
                              ];
                      }))));
        var mixedDiff = diffPropertyAbsence({
              TAG: /* Mixed */1,
              _0: mixedFromAbsence
            }, {
              TAG: /* Mixed */1,
              _0: toAbsence$2
            }, propertyId, fromModel, toModel);
        var eventsSendingPropertyInFromModel$1 = Belt_SetString.fromArray(Belt_List.toArray(Belt_List.map(eventsSendingPropertyInFromModel, (function ($$event) {
                        return $$event.id;
                      }))));
        var eventsSendingPropertyInToModel = Belt_SetString.fromArray(Belt_List.toArray(Belt_List.map(ModelUtils.eventsSendingPropertyFromSources(toModel, undefined, propertyId), (function ($$event) {
                        return $$event.id;
                      }))));
        var eventsInToAbsence = Belt_SetString.fromArray(Belt_MapString.keysToArray(toAbsence$2));
        var eventsInAbsence = Belt_SetString.union(eventsSendingPropertyInFromModel$1, eventsInToAbsence);
        var newAlwaysSentEvents = Belt_SetString.toArray(Belt_SetString.diff(eventsSendingPropertyInToModel, eventsInAbsence));
        var newEventsDiff = Belt_List.fromArray(Belt_Array.map(newAlwaysSentEvents, (function (eventId) {
                    return {
                            NAME: "UpdatePropertyAbsence",
                            VAL: [
                              propertyId,
                              {
                                TAG: /* Event */0,
                                _0: eventId
                              },
                              undefined
                            ]
                          };
                  })));
        return Belt_List.concat(mixedDiff, newEventsDiff);
      }
    } else {
      if (toAbsence === undefined) {
        return /* [] */0;
      }
      var eventAbsencesMap = fromAbsence._0;
      if (typeof toAbsence === "number") {
        var wasAlwaysSent = Belt_Array.every(Belt_MapString.toArray(eventAbsencesMap), (function (param) {
                var sourceAbsenceMap = param[1];
                if (sourceAbsenceMap.TAG === /* AllSources */0) {
                  return false;
                } else {
                  return Belt_MapString.isEmpty(sourceAbsenceMap._0);
                }
              }));
        if (wasAlwaysSent) {
          return /* [] */0;
        } else {
          return {
                  hd: {
                    NAME: "UpdatePropertyAbsence",
                    VAL: [
                      propertyId,
                      /* All */0,
                      undefined
                    ]
                  },
                  tl: /* [] */0
                };
        }
      }
      if (toAbsence.TAG === /* SometimesSent */0) {
        toDescription = toAbsence._0;
        exit = 2;
      } else {
        var toAbsence$3 = toAbsence._0;
        if (Belt_MapString.isEmpty(eventAbsencesMap) && Belt_MapString.isEmpty(toAbsence$3)) {
          return /* [] */0;
        }
        if (toAbsence$3 === undefined) {
          return {
                  hd: {
                    NAME: "UpdatePropertyAbsence",
                    VAL: [
                      propertyId,
                      /* All */0,
                      undefined
                    ]
                  },
                  tl: /* [] */0
                };
        }
        var fromEventIds = Belt_MapString.keysToArray(eventAbsencesMap);
        var toEventIds = Belt_MapString.keysToArray(toAbsence$3);
        var newEventIds = Belt_Array.keep(toEventIds, (function (toEventId) {
                return !fromEventIds.includes(toEventId);
              }));
        var removedEventIds = Belt_Array.keep(fromEventIds, (function (fromEventId) {
                return !toEventIds.includes(fromEventId);
              }));
        var changedEventIds = Belt_Array.keep(toEventIds, (function (eventId) {
                if (fromEventIds.includes(eventId)) {
                  return Caml_obj.notequal(Belt_MapString.get(eventAbsencesMap, eventId), Belt_MapString.get(toAbsence$3, eventId));
                } else {
                  return false;
                }
              }));
        var absencesAdded = Belt_List.flatten(Belt_List.fromArray(Belt_Array.keepMap(newEventIds, (function (newEventId) {
                        return Belt_Option.map(Belt_MapString.get(toAbsence$3, newEventId), (function (sourceAbsence) {
                                      return getActionsFromSourceAbsenceComparedToAlwaysSent(newEventId, sourceAbsence);
                                    }));
                      }))));
        var absencesRemoved = Belt_List.fromArray(Belt_Array.keepMap(removedEventIds, (function (removedEventId) {
                    if (isPropertyIncludedOnEventOrVariantInToModel(removedEventId, propertyId)) {
                      return {
                              NAME: "UpdatePropertyAbsence",
                              VAL: [
                                propertyId,
                                {
                                  TAG: /* Event */0,
                                  _0: removedEventId
                                },
                                undefined
                              ]
                            };
                    }
                    
                  })));
        var absencesChanged = Belt_List.flatten(Belt_List.fromArray(Belt_Array.map(Belt_Array.keepMap(changedEventIds, (function (eventId) {
                            var match = Belt_MapString.get(eventAbsencesMap, eventId);
                            var match$1 = Belt_MapString.get(toAbsence$3, eventId);
                            if (match !== undefined && match$1 !== undefined) {
                              return [
                                      eventId,
                                      match,
                                      match$1
                                    ];
                            }
                            
                          })), (function (param) {
                        var toEventAbsence = param[2];
                        var fromEventAbsence = param[1];
                        var eventId = param[0];
                        if (!isPropertyIncludedOnEventOrVariantInToModel(eventId, propertyId)) {
                          return /* [] */0;
                        }
                        if (fromEventAbsence.TAG === /* AllSources */0) {
                          var fromEventAbsence$1 = fromEventAbsence._0;
                          if (toEventAbsence.TAG === /* AllSources */0) {
                            var toEventAbsence$1 = toEventAbsence._0;
                            if (Caml_obj.notequal(fromEventAbsence$1, toEventAbsence$1)) {
                              return {
                                      hd: {
                                        NAME: "UpdatePropertyAbsence",
                                        VAL: [
                                          propertyId,
                                          {
                                            TAG: /* Event */0,
                                            _0: eventId
                                          },
                                          toEventAbsence$1
                                        ]
                                      },
                                      tl: /* [] */0
                                    };
                            } else {
                              return /* [] */0;
                            }
                          }
                          var toEventAbsence$2 = toEventAbsence._0;
                          var sourcesSendingEvent = ModelUtils.getEventSourceIdsByEventId(toModel, eventId);
                          var newAlwaysSent = Belt_List.toArray(Belt_List.map(Belt_List.keep(sourcesSendingEvent, (function (sourceId) {
                                          return Belt_Option.isNone(Belt_MapString.get(toEventAbsence$2, sourceId));
                                        })), (function (sourceId) {
                                      return {
                                              NAME: "UpdatePropertyAbsence",
                                              VAL: [
                                                propertyId,
                                                {
                                                  TAG: /* EventSource */2,
                                                  _0: eventId,
                                                  _1: sourceId
                                                },
                                                undefined
                                              ]
                                            };
                                    })));
                          var addedOrChanged = Belt_Array.map(Belt_Array.keep(Belt_MapString.toArray(toEventAbsence$2), (function (param) {
                                      return Caml_obj.notequal(param[1], fromEventAbsence$1);
                                    })), (function (param) {
                                  return {
                                          NAME: "UpdatePropertyAbsence",
                                          VAL: [
                                            propertyId,
                                            {
                                              TAG: /* EventSource */2,
                                              _0: eventId,
                                              _1: param[0]
                                            },
                                            param[1]
                                          ]
                                        };
                                }));
                          return Belt_List.fromArray(Belt_Array.concat(addedOrChanged, newAlwaysSent));
                        }
                        var fromSourceAbsence = fromEventAbsence._0;
                        if (toEventAbsence.TAG === /* AllSources */0) {
                          return {
                                  hd: {
                                    NAME: "UpdatePropertyAbsence",
                                    VAL: [
                                      propertyId,
                                      {
                                        TAG: /* Event */0,
                                        _0: eventId
                                      },
                                      toEventAbsence._0
                                    ]
                                  },
                                  tl: /* [] */0
                                };
                        }
                        var toSourceAbsence = toEventAbsence._0;
                        var removed = Belt_Array.map(Belt_Array.keep(Belt_MapString.toArray(fromSourceAbsence), (function (param) {
                                    return Belt_Option.isNone(Belt_MapString.get(toSourceAbsence, param[0]));
                                  })), (function (param) {
                                return {
                                        NAME: "UpdatePropertyAbsence",
                                        VAL: [
                                          propertyId,
                                          {
                                            TAG: /* EventSource */2,
                                            _0: eventId,
                                            _1: param[0]
                                          },
                                          undefined
                                        ]
                                      };
                              }));
                        var addedOrChanged$1 = Belt_Array.map(Belt_Array.keep(Belt_MapString.toArray(toSourceAbsence), (function (param) {
                                    var toSourceAbsence = param[1];
                                    return Belt_Option.mapWithDefault(Belt_MapString.get(fromSourceAbsence, param[0]), true, (function (fromSourceAbsence) {
                                                  return Caml_obj.notequal(fromSourceAbsence, toSourceAbsence);
                                                }));
                                  })), (function (param) {
                                return {
                                        NAME: "UpdatePropertyAbsence",
                                        VAL: [
                                          propertyId,
                                          {
                                            TAG: /* EventSource */2,
                                            _0: eventId,
                                            _1: param[0]
                                          },
                                          param[1]
                                        ]
                                      };
                              }));
                        return Belt_List.fromArray(Belt_Array.concat(removed, addedOrChanged$1));
                      }))));
        return Belt_List.concatMany([
                    absencesAdded,
                    absencesRemoved,
                    absencesChanged
                  ]);
      }
    }
  } else {
    if (toAbsence === undefined) {
      return /* [] */0;
    }
    if (typeof toAbsence === "number") {
      exit = 1;
    } else if (toAbsence.TAG === /* SometimesSent */0) {
      toDescription = toAbsence._0;
      exit = 2;
    } else {
      toAbsence$1 = toAbsence._0;
      exit = 3;
    }
  }
  switch (exit) {
    case 1 :
        return {
                hd: {
                  NAME: "UpdatePropertyAbsence",
                  VAL: [
                    propertyId,
                    /* All */0,
                    undefined
                  ]
                },
                tl: /* [] */0
              };
    case 2 :
        return {
                hd: {
                  NAME: "UpdatePropertyAbsence",
                  VAL: [
                    propertyId,
                    /* All */0,
                    /* SometimesSent */{
                      _0: toDescription
                    }
                  ]
                },
                tl: /* [] */0
              };
    case 3 :
        return Belt_List.flatten(Belt_List.map(Belt_MapString.toList(toAbsence$1), (function (param) {
                          return getActionsFromSourceAbsenceComparedToAlwaysSent(param[0], param[1]);
                        })));
    
  }
}

function diffPropertyOperation(fromOperation, toOperation, propertyId) {
  if (Caml_obj.notequal(fromOperation, toOperation)) {
    return {
            hd: {
              NAME: "UpdatePropertyOperation",
              VAL: [
                propertyId,
                toOperation
              ]
            },
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function diffPropertyList(fromList, toList, propertyId) {
  if (Caml_obj.notequal(fromList, toList)) {
    return {
            hd: {
              NAME: "UpdatePropertyList",
              VAL: [
                propertyId,
                toList
              ]
            },
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function diffPropertyType(fromType, toType, propertyId) {
  if (Caml_obj.notequal(fromType, toType)) {
    return {
            hd: {
              NAME: "UpdatePropertyType",
              VAL: [
                propertyId,
                toType
              ]
            },
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function diffPropertyValidations(fromValidations, toValidations, hasMigratedToEventSpecificAllowedValues, propertyId) {
  var getMin = function (validations) {
    return Belt_List.getBy(validations, (function (validation) {
                  if (typeof validation === "object") {
                    return validation.NAME === "Min";
                  } else {
                    return false;
                  }
                }));
  };
  var fromMin = getMin(fromValidations);
  var toMin = getMin(toValidations);
  var minDiff = fromMin !== undefined ? (
      toMin !== undefined ? (
          Caml_obj.notequal(fromMin, toMin) ? ({
                hd: {
                  NAME: "RemovePropertyValidation",
                  VAL: [
                    propertyId,
                    fromMin
                  ]
                },
                tl: {
                  hd: {
                    NAME: "AddPropertyValidation",
                    VAL: [
                      propertyId,
                      toMin
                    ]
                  },
                  tl: /* [] */0
                }
              }) : /* [] */0
        ) : ({
            hd: {
              NAME: "RemovePropertyValidation",
              VAL: [
                propertyId,
                fromMin
              ]
            },
            tl: /* [] */0
          })
    ) : (
      toMin !== undefined ? ({
            hd: {
              NAME: "AddPropertyValidation",
              VAL: [
                propertyId,
                toMin
              ]
            },
            tl: /* [] */0
          }) : /* [] */0
    );
  var getMax = function (validations) {
    return Belt_List.getBy(validations, (function (validation) {
                  if (typeof validation === "object") {
                    return validation.NAME === "Max";
                  } else {
                    return false;
                  }
                }));
  };
  var fromMax = getMax(fromValidations);
  var toMax = getMax(toValidations);
  var maxDiff = fromMax !== undefined ? (
      toMax !== undefined ? (
          Caml_obj.notequal(fromMax, toMax) ? ({
                hd: {
                  NAME: "RemovePropertyValidation",
                  VAL: [
                    propertyId,
                    fromMax
                  ]
                },
                tl: {
                  hd: {
                    NAME: "AddPropertyValidation",
                    VAL: [
                      propertyId,
                      toMax
                    ]
                  },
                  tl: /* [] */0
                }
              }) : /* [] */0
        ) : ({
            hd: {
              NAME: "RemovePropertyValidation",
              VAL: [
                propertyId,
                fromMax
              ]
            },
            tl: /* [] */0
          })
    ) : (
      toMax !== undefined ? ({
            hd: {
              NAME: "AddPropertyValidation",
              VAL: [
                propertyId,
                toMax
              ]
            },
            tl: /* [] */0
          }) : /* [] */0
    );
  var matchDiff;
  if (hasMigratedToEventSpecificAllowedValues) {
    matchDiff = /* [] */0;
  } else {
    var getMatches = function (validations) {
      return Belt_List.getBy(validations, (function (validation) {
                    if (typeof validation === "object") {
                      return validation.NAME === "Matches";
                    } else {
                      return false;
                    }
                  }));
    };
    var fromMatch = getMatches(fromValidations);
    var toMatch = getMatches(toValidations);
    var matchDiff$1;
    if (fromMatch !== undefined) {
      var exit = 0;
      if (typeof fromMatch === "object" && fromMatch.NAME === "Matches" && toMatch !== undefined) {
        matchDiff$1 = typeof toMatch === "object" && toMatch.NAME === "Matches" ? Belt_List.map(diffList(fromMatch.VAL, toMatch.VAL, (function (from, to_) {
                      return Caml_obj.equal(from[0], to_[0]);
                    })), (function (change) {
                  if (change.NAME === "Remove") {
                    return {
                            NAME: "RemovePropertyValidation",
                            VAL: [
                              propertyId,
                              {
                                NAME: "Matches",
                                VAL: {
                                  hd: change.VAL,
                                  tl: /* [] */0
                                }
                              }
                            ]
                          };
                  } else {
                    return {
                            NAME: "AddPropertyValidation",
                            VAL: [
                              propertyId,
                              {
                                NAME: "Matches",
                                VAL: {
                                  hd: change.VAL,
                                  tl: /* [] */0
                                }
                              }
                            ]
                          };
                  }
                })) : /* [] */0;
      } else {
        exit = 1;
      }
      if (exit === 1) {
        matchDiff$1 = toMatch !== undefined ? /* [] */0 : ({
              hd: {
                NAME: "RemovePropertyValidation",
                VAL: [
                  propertyId,
                  {
                    NAME: "Matches",
                    VAL: /* [] */0
                  }
                ]
              },
              tl: /* [] */0
            });
      }
      
    } else {
      matchDiff$1 = toMatch !== undefined ? ({
            hd: {
              NAME: "AddPropertyValidation",
              VAL: [
                propertyId,
                toMatch
              ]
            },
            tl: /* [] */0
          }) : /* [] */0;
    }
    var matchSourceToggleDiff;
    if (fromMatch !== undefined && typeof fromMatch === "object" && fromMatch.NAME === "Matches" && toMatch !== undefined && typeof toMatch === "object" && toMatch.NAME === "Matches") {
      var fromMatchList = fromMatch.VAL;
      matchSourceToggleDiff = BeltListExtensions.flatMap(toMatch.VAL, (function (param) {
              var toMatchExp = param[0];
              var maybeFromMatch = Belt_List.getBy(fromMatchList, (function (param) {
                      return Caml_obj.equal(param[0], toMatchExp);
                    }));
              if (maybeFromMatch === undefined) {
                return /* [] */0;
              }
              var match = maybeFromMatch[0].VAL;
              if (typeof match !== "object") {
                return /* [] */0;
              }
              if (match.NAME !== "StringLit") {
                return /* [] */0;
              }
              var stringMatch = match.VAL;
              var diffSourceExclusion = diffList(maybeFromMatch[1], param[1], (function (prim0, prim1) {
                      return prim0 === prim1;
                    }));
              return Belt_List.map(diffSourceExclusion, (function (change) {
                            if (change.NAME === "Remove") {
                              return {
                                      NAME: "TogglePropertyValidationMatchSource",
                                      VAL: [
                                        propertyId,
                                        stringMatch,
                                        change.VAL,
                                        true
                                      ]
                                    };
                            } else {
                              return {
                                      NAME: "TogglePropertyValidationMatchSource",
                                      VAL: [
                                        propertyId,
                                        stringMatch,
                                        change.VAL,
                                        false
                                      ]
                                    };
                            }
                          }));
            }));
    } else {
      matchSourceToggleDiff = /* [] */0;
    }
    matchDiff = Belt_List.concat(matchDiff$1, matchSourceToggleDiff);
  }
  var getShapes = function (validations) {
    return Belt_List.getBy(validations, (function (validation) {
                  if (typeof validation === "object") {
                    return validation.NAME === "Shape";
                  } else {
                    return false;
                  }
                }));
  };
  var fromShape = getShapes(fromValidations);
  var toShape = getShapes(toValidations);
  var shapeDiff;
  if (fromShape !== undefined) {
    var exit$1 = 0;
    if (typeof fromShape === "object" && fromShape.NAME === "Shape" && toShape !== undefined) {
      shapeDiff = typeof toShape === "object" && toShape.NAME === "Shape" ? Belt_List.map(diffList(fromShape.VAL, toShape.VAL, (function (a, b) {
                    return a.key === b.key;
                  })), (function (change) {
                if (change.NAME === "Remove") {
                  return {
                          NAME: "RemovePropertyValidation",
                          VAL: [
                            propertyId,
                            {
                              NAME: "Shape",
                              VAL: {
                                hd: change.VAL,
                                tl: /* [] */0
                              }
                            }
                          ]
                        };
                } else {
                  return {
                          NAME: "AddPropertyValidation",
                          VAL: [
                            propertyId,
                            {
                              NAME: "Shape",
                              VAL: {
                                hd: change.VAL,
                                tl: /* [] */0
                              }
                            }
                          ]
                        };
                }
              })) : /* [] */0;
    } else {
      exit$1 = 1;
    }
    if (exit$1 === 1) {
      shapeDiff = toShape !== undefined ? /* [] */0 : ({
            hd: {
              NAME: "RemovePropertyValidation",
              VAL: [
                propertyId,
                {
                  NAME: "Shape",
                  VAL: /* [] */0
                }
              ]
            },
            tl: /* [] */0
          });
    }
    
  } else {
    shapeDiff = toShape !== undefined ? ({
          hd: {
            NAME: "AddPropertyValidation",
            VAL: [
              propertyId,
              toShape
            ]
          },
          tl: /* [] */0
        }) : /* [] */0;
  }
  var getNestedProperty = function (validations) {
    return Belt_List.getBy(validations, (function (validation) {
                  if (typeof validation === "object") {
                    return validation.NAME === "NestedProperty";
                  } else {
                    return false;
                  }
                }));
  };
  var fromNestedProperty = getNestedProperty(fromValidations);
  var toNestedProperty = getNestedProperty(toValidations);
  var nestedPropertyDiff;
  if (fromNestedProperty !== undefined) {
    var exit$2 = 0;
    if (typeof fromNestedProperty === "object" && fromNestedProperty.NAME === "NestedProperty" && toNestedProperty !== undefined) {
      nestedPropertyDiff = typeof toNestedProperty === "object" && toNestedProperty.NAME === "NestedProperty" ? BeltListExtensions.flatMap(diffList(fromNestedProperty.VAL, toNestedProperty.VAL, Caml_obj.equal), (function (change) {
                if (change.NAME === "Remove") {
                  return {
                          hd: {
                            NAME: "RemovePropertyValidation",
                            VAL: [
                              propertyId,
                              {
                                NAME: "NestedProperty",
                                VAL: {
                                  hd: change.VAL,
                                  tl: /* [] */0
                                }
                              }
                            ]
                          },
                          tl: /* [] */0
                        };
                }
                var propertyRef = change.VAL;
                var pinnedValue = propertyRef.pinnedValue;
                return Belt_List.concat({
                            hd: {
                              NAME: "AddPropertyValidation",
                              VAL: [
                                propertyId,
                                {
                                  NAME: "NestedProperty",
                                  VAL: {
                                    hd: propertyRef,
                                    tl: /* [] */0
                                  }
                                }
                              ]
                            },
                            tl: /* [] */0
                          }, pinnedValue !== undefined ? ({
                                hd: {
                                  NAME: "UpdateNestedPropertyPinnedValue",
                                  VAL: [
                                    propertyId,
                                    propertyRef.id,
                                    pinnedValue
                                  ]
                                },
                                tl: /* [] */0
                              }) : ({
                                hd: {
                                  NAME: "RemoveNestedPropertyPinnedValue",
                                  VAL: [
                                    propertyId,
                                    propertyRef.id
                                  ]
                                },
                                tl: /* [] */0
                              }));
              })) : /* [] */0;
    } else {
      exit$2 = 1;
    }
    if (exit$2 === 1) {
      nestedPropertyDiff = toNestedProperty !== undefined ? /* [] */0 : ({
            hd: {
              NAME: "RemovePropertyValidation",
              VAL: [
                propertyId,
                {
                  NAME: "NestedProperty",
                  VAL: /* [] */0
                }
              ]
            },
            tl: /* [] */0
          });
    }
    
  } else {
    nestedPropertyDiff = toNestedProperty !== undefined ? ({
          hd: {
            NAME: "AddPropertyValidation",
            VAL: [
              propertyId,
              toNestedProperty
            ]
          },
          tl: /* [] */0
        }) : /* [] */0;
  }
  var getRegex = function (validations) {
    return Belt_List.head(Belt_List.keepMapU(validations, (function (validation) {
                      if (typeof validation === "object" && validation.NAME === "Regex") {
                        return Caml_option.some(validation.VAL);
                      }
                      
                    })));
  };
  var fromRegex = getRegex(fromValidations);
  var toRegex = getRegex(toValidations);
  var regexDiff;
  if (fromRegex !== undefined) {
    if (toRegex !== undefined) {
      if (Caml_obj.notequal(fromRegex, toRegex)) {
        var match = fromRegex.propertyRule;
        var match$1 = toRegex.propertyRule;
        var propertyRuleDiff = match !== undefined ? (
            match$1 !== undefined ? (
                Caml_obj.notequal(match, match$1) ? ({
                      hd: {
                        NAME: "UpdatePropertyRegexValidation",
                        VAL: [
                          propertyId,
                          undefined,
                          match$1
                        ]
                      },
                      tl: /* [] */0
                    }) : /* [] */0
              ) : ({
                  hd: {
                    NAME: "UpdatePropertyRegexValidation",
                    VAL: [
                      propertyId,
                      undefined,
                      undefined
                    ]
                  },
                  tl: /* [] */0
                })
          ) : (
            match$1 !== undefined ? ({
                  hd: {
                    NAME: "UpdatePropertyRegexValidation",
                    VAL: [
                      propertyId,
                      undefined,
                      match$1
                    ]
                  },
                  tl: /* [] */0
                }) : /* [] */0
          );
        var eventOverridesDiff = Belt_List.fromArray(Belt_Array.map(diffMapString(fromRegex.eventOverrides, toRegex.eventOverrides), (function (change) {
                    var variant = change.NAME;
                    if (variant === "Remove") {
                      return {
                              NAME: "UpdatePropertyRegexValidation",
                              VAL: [
                                propertyId,
                                change.VAL[0],
                                undefined
                              ]
                            };
                    }
                    if (variant === "Update") {
                      var match = change.VAL;
                      return {
                              NAME: "UpdatePropertyRegexValidation",
                              VAL: [
                                propertyId,
                                match[0],
                                match[2]
                              ]
                            };
                    }
                    var match$1 = change.VAL;
                    return {
                            NAME: "UpdatePropertyRegexValidation",
                            VAL: [
                              propertyId,
                              match$1[0],
                              match$1[1]
                            ]
                          };
                  })));
        regexDiff = Belt_List.concat(propertyRuleDiff, eventOverridesDiff);
      } else {
        regexDiff = /* [] */0;
      }
    } else {
      regexDiff = {
        hd: {
          NAME: "RemovePropertyValidation",
          VAL: [
            propertyId,
            {
              NAME: "Regex",
              VAL: fromRegex
            }
          ]
        },
        tl: /* [] */0
      };
    }
  } else {
    regexDiff = toRegex !== undefined ? ({
          hd: {
            NAME: "AddPropertyValidation",
            VAL: [
              propertyId,
              {
                NAME: "Regex",
                VAL: toRegex
              }
            ]
          },
          tl: /* [] */0
        }) : /* [] */0;
  }
  return Belt_List.concatMany([
              minDiff,
              maxDiff,
              matchDiff,
              shapeDiff,
              nestedPropertyDiff,
              regexDiff
            ]);
}

function diffLiteralsFast(fromLiterals, toLiterals) {
  var cmp = function (param, param$1) {
    return Caml_obj.compare(param[0], param$1[0]);
  };
  var LiteralComparator = Belt_Id.MakeComparable({
        cmp: cmp
      });
  var fromValuesSet = Belt_Set.fromArray(fromLiterals, LiteralComparator);
  var toValuesSet = Belt_Set.fromArray(toLiterals, LiteralComparator);
  var addedValues = Belt_Array.map(Belt_Set.toArray(Belt_Set.diff(toValuesSet, fromValuesSet)), (function (value) {
          return {
                  NAME: "Add",
                  VAL: value
                };
        }));
  var removedValues = Belt_Array.map(Belt_Set.toArray(Belt_Set.diff(fromValuesSet, toValuesSet)), (function (value) {
          return {
                  NAME: "Remove",
                  VAL: value
                };
        }));
  return Belt_Array.concat(addedValues, removedValues);
}

function diffDisallowedEventsFast(fromEvents, toEvents) {
  var cmp = function (param, param$1) {
    return Caml_obj.compare(param[0], param$1[0]);
  };
  var DisallowedEventsComparator = Belt_Id.MakeComparable({
        cmp: cmp
      });
  var fromValuesSet = Belt_Set.fromArray(fromEvents, DisallowedEventsComparator);
  var toValuesSet = Belt_Set.fromArray(toEvents, DisallowedEventsComparator);
  var addedValues = Belt_Array.map(Belt_Set.toArray(Belt_Set.diff(toValuesSet, fromValuesSet)), (function (value) {
          return {
                  NAME: "Add",
                  VAL: value
                };
        }));
  var removedValues = Belt_Array.map(Belt_Set.toArray(Belt_Set.diff(fromValuesSet, toValuesSet)), (function (value) {
          return {
                  NAME: "Remove",
                  VAL: value
                };
        }));
  return Belt_Array.concat(addedValues, removedValues);
}

function diffPropertyEventSpecificAllowedPropertyValues(fromEventSpecificAllowedValues, toEventSpecificAllowedValues, toMappedEvents, toModel, propertyId) {
  if (fromEventSpecificAllowedValues.length > 1 && Caml_obj.equal(toEventSpecificAllowedValues, [])) {
    return {
            hd: {
              NAME: "ClearAllEventSpecificPropertyValuesFromProperty",
              VAL: propertyId
            },
            tl: /* [] */0
          };
  }
  var toEventsIncludedSourcesMap = Curry._2(TrackingPlanMappedModel.Events.mapToMap, toMappedEvents, (function ($$event) {
          return Belt_SetString.fromArray(Belt_Array.mapU(Belt_List.toArray($$event.includeSources), (function (source) {
                            return source.id;
                          })));
        }));
  var getEventIncludeSourceSet = function (eventId) {
    if (eventId === TrackingPlanModel.propertyAbsenceAllEventsKey) {
      return Belt_SetString.fromArray(Belt_Array.map(Belt_List.toArray(toModel.sources), (function (source) {
                        return source.id;
                      })));
    } else {
      return Belt_Option.getWithDefault(Belt_MapString.get(toEventsIncludedSourcesMap, eventId), undefined);
    }
  };
  var diffLiteralArray = function (fromAllowedValues, toAllowedValues) {
    return Belt_List.fromArray(Belt_Array.concatMany(Belt_Array.map(diffLiteralsFast(fromAllowedValues, toAllowedValues), (function (change) {
                          if (change.NAME === "Remove") {
                            return [{
                                      NAME: "RemoveEventSpecificPropertyValueForAllEvents",
                                      VAL: [
                                        propertyId,
                                        change.VAL[0]
                                      ]
                                    }];
                          }
                          var match = change.VAL;
                          var propertyValueDisallowedEvents = match[1];
                          var literal = match[0];
                          if (Belt_MapString.isEmpty(propertyValueDisallowedEvents)) {
                            return [{
                                      NAME: "AddEventSpecificPropertyValueForAllEvents",
                                      VAL: [
                                        propertyId,
                                        literal
                                      ]
                                    }];
                          } else {
                            return [{
                                      NAME: "AddEventSpecificPropertyValueForSomeEvents",
                                      VAL: [
                                        propertyId,
                                        literal,
                                        propertyValueDisallowedEvents
                                      ]
                                    }];
                          }
                        }))));
  };
  var addedAndRemovedLiteralActions = diffLiteralArray(fromEventSpecificAllowedValues, toEventSpecificAllowedValues);
  var fromEventSpecificAllowedValuesMap = Belt_MapString.fromArray(Belt_Array.mapU(fromEventSpecificAllowedValues, (function (param) {
              var literal = param[0];
              if (typeof literal === "object" && literal.NAME === "StringLit") {
                return [
                        literal.VAL,
                        param[1]
                      ];
              } else {
                return Pervasives.failwith("Only string literals are supported for event specific allowed values");
              }
            })));
  var diffDisallowedEventsMap = function (literal, fromPropertyValueDisallowedEvents, toPropertyValueDisallowedEvents) {
    var addedAndRemovedEventActions = Belt_Array.concatMany(Belt_Array.map(diffDisallowedEventsFast(Belt_MapString.toArray(fromPropertyValueDisallowedEvents), Belt_MapString.toArray(toPropertyValueDisallowedEvents)), (function (change) {
                if (change.NAME === "Remove") {
                  var match = change.VAL;
                  var disallowedSourceSet = match[1];
                  var eventId = match[0];
                  if (!disallowedSourceSet) {
                    return [{
                              NAME: "AddEventSpecificPropertyValue",
                              VAL: [
                                eventId,
                                propertyId,
                                literal,
                                getEventIncludeSourceSet(eventId)
                              ]
                            }];
                  }
                  var eventSourceSet = getEventIncludeSourceSet(eventId);
                  var disallowedSourceSet$1 = Belt_SetString.intersect(disallowedSourceSet._0, eventSourceSet);
                  if (Belt_SetString.eq(disallowedSourceSet$1, eventSourceSet)) {
                    return [{
                              NAME: "AddEventSpecificPropertyValue",
                              VAL: [
                                eventId,
                                propertyId,
                                literal,
                                eventSourceSet
                              ]
                            }];
                  } else if (Belt_SetString.isEmpty(disallowedSourceSet$1)) {
                    return [];
                  } else {
                    return [{
                              NAME: "SetEventSpecificPropertyValueSources",
                              VAL: [
                                eventId,
                                propertyId,
                                literal,
                                disallowedSourceSet$1,
                                undefined
                              ]
                            }];
                  }
                }
                var match$1 = change.VAL;
                var disallowedState = match$1[1];
                var eventId$1 = match$1[0];
                var eventSourceSet$1 = getEventIncludeSourceSet(eventId$1);
                var disallowedSourcesSet = disallowedState ? Belt_SetString.intersect(disallowedState._0, eventSourceSet$1) : eventSourceSet$1;
                if (Belt_SetString.eq(disallowedSourcesSet, eventSourceSet$1)) {
                  return [{
                            NAME: "RemoveEventSpecificPropertyValue",
                            VAL: [
                              eventId$1,
                              propertyId,
                              literal,
                              disallowedSourcesSet
                            ]
                          }];
                } else if (Belt_SetString.isEmpty(disallowedSourcesSet)) {
                  return [];
                } else {
                  return [{
                            NAME: "SetEventSpecificPropertyValueSources",
                            VAL: [
                              eventId$1,
                              propertyId,
                              literal,
                              undefined,
                              disallowedSourcesSet
                            ]
                          }];
                }
              })));
    var getSourceSetFromDisallowedState = function (disallowedState, eventId) {
      var eventSourceSet = getEventIncludeSourceSet(eventId);
      if (disallowedState) {
        return Belt_SetString.intersect(disallowedState._0, eventSourceSet);
      } else {
        return eventSourceSet;
      }
    };
    var updatedEventActions = Belt_Array.concatMany(Belt_Array.keepMapU(Belt_MapString.toArray(toPropertyValueDisallowedEvents), (function (param) {
                var toPropertyValueDisallowedEventState = param[1];
                var eventId = param[0];
                return Belt_Option.map(Belt_MapString.get(fromPropertyValueDisallowedEvents, eventId), (function (fromPropertyValueDisallowedEventState) {
                              if (fromPropertyValueDisallowedEventState) {
                                if (toPropertyValueDisallowedEventState && Belt_SetString.eq(fromPropertyValueDisallowedEventState._0, toPropertyValueDisallowedEventState._0)) {
                                  return [];
                                }
                                
                              } else if (!toPropertyValueDisallowedEventState) {
                                return [];
                              }
                              var fromPropertyValueDisallowedEventSources = getSourceSetFromDisallowedState(fromPropertyValueDisallowedEventState, eventId);
                              var toPropertyValueDisallowedEventSources = getSourceSetFromDisallowedState(toPropertyValueDisallowedEventState, eventId);
                              var removedAllowedSourceIds = Belt_SetString.diff(toPropertyValueDisallowedEventSources, fromPropertyValueDisallowedEventSources);
                              var addedAllowedSourceIds = Belt_SetString.diff(fromPropertyValueDisallowedEventSources, toPropertyValueDisallowedEventSources);
                              if (Belt_SetString.isEmpty(removedAllowedSourceIds) && Belt_SetString.isEmpty(addedAllowedSourceIds)) {
                                return [];
                              } else if (Belt_SetString.eq(toPropertyValueDisallowedEventSources, getEventIncludeSourceSet(eventId))) {
                                return [{
                                          NAME: "RemoveEventSpecificPropertyValue",
                                          VAL: [
                                            eventId,
                                            propertyId,
                                            literal,
                                            removedAllowedSourceIds
                                          ]
                                        }];
                              } else {
                                return [{
                                          NAME: "SetEventSpecificPropertyValueSources",
                                          VAL: [
                                            eventId,
                                            propertyId,
                                            literal,
                                            addedAllowedSourceIds,
                                            removedAllowedSourceIds
                                          ]
                                        }];
                              }
                            }));
              })));
    return Belt_Array.concat(addedAndRemovedEventActions, updatedEventActions);
  };
  var updatedLiterals = Belt_Array.keepMap(toEventSpecificAllowedValues, (function (param) {
          var toDisallowedEvents = param[1];
          var literal = param[0];
          if (typeof literal === "object" && literal.NAME === "StringLit") {
            return Belt_Option.map(Belt_MapString.get(fromEventSpecificAllowedValuesMap, literal.VAL), (function (fromDisallowedEvents) {
                          return [
                                  literal,
                                  fromDisallowedEvents,
                                  toDisallowedEvents
                                ];
                        }));
          }
          
        }));
  var hasAnyLiteralChanges = Belt_Array.someU(updatedLiterals, (function (param) {
          return diffDisallowedEventsMap(param[0], param[1], param[2]).length !== 0;
        }));
  var isOnlyAllowingAllValuesOnAllEventsAndSources = hasAnyLiteralChanges && Belt_Array.everyU(toEventSpecificAllowedValues, (function (param) {
          return Belt_MapString.isEmpty(param[1]);
        }));
  var updatedLiteralActions = isOnlyAllowingAllValuesOnAllEventsAndSources ? ({
        hd: {
          NAME: "AllowAllEventSpecificPropertyValuesOnAllEventsAndSources",
          VAL: propertyId
        },
        tl: /* [] */0
      }) : Belt_List.fromArray(Belt_Array.concatMany(Belt_Array.mapU(updatedLiterals, (function (param) {
                    return diffDisallowedEventsMap(param[0], param[1], param[2]);
                  }))));
  return Belt_List.concat(addedAndRemovedLiteralActions, updatedLiteralActions);
}

function diffPropertyExcludedSources(fromExcludedSources, toExcludedSources, propertyId) {
  if (!fromExcludedSources && !toExcludedSources) {
    return /* [] */0;
  }
  if (fromExcludedSources === toExcludedSources) {
    return /* [] */0;
  } else if (toExcludedSources) {
    return Belt_List.map(diffList(fromExcludedSources, toExcludedSources, Caml_obj.equal), (function (change) {
                  if (change.NAME === "Remove") {
                    return {
                            NAME: "TogglePropertyExcludedSource",
                            VAL: [
                              propertyId,
                              change.VAL,
                              true
                            ]
                          };
                  } else {
                    return {
                            NAME: "TogglePropertyExcludedSource",
                            VAL: [
                              propertyId,
                              change.VAL,
                              false
                            ]
                          };
                  }
                }));
  } else {
    return {
            hd: {
              NAME: "ClearPropertyExcludedSources",
              VAL: propertyId
            },
            tl: /* [] */0
          };
  }
}

function diffProperty(fromProperty, toProperty, fromModel, toModel, toMappedEvents, hasMigratedToEventSpecificAllowedValues) {
  var propertyId = toProperty.id;
  var match = toProperty.absence;
  return Belt_List.concatMany([
              diffPropertyId(fromProperty.id, toProperty, Belt_Option.map(toModel.globalRequirements, (function (param) {
                          return param.metadata;
                        }))),
              diffPropertyName(fromProperty.name, toProperty.name, propertyId),
              diffPropertyUniqueName(fromProperty.uniqueName, toProperty.uniqueName, propertyId),
              diffPropertyDescription(fromProperty.description, toProperty.description, propertyId),
              match !== undefined ? Belt_List.flatten({
                      hd: diffPropertyAbsence(fromProperty.absence, toProperty.absence, propertyId, fromModel, toModel),
                      tl: {
                        hd: diffPropertyOptionalWhenInObject(fromProperty.optionalWhenInObject, toProperty.optionalWhenInObject, propertyId),
                        tl: /* [] */0
                      }
                    }) : Belt_List.flatten({
                      hd: diffPropertyOptional(fromProperty.optionalDeprecated, toProperty.optionalDeprecated, propertyId),
                      tl: {
                        hd: diffPropertyExcludedSources(fromProperty.excludedSourcesDeprecated, toProperty.excludedSourcesDeprecated, propertyId),
                        tl: /* [] */0
                      }
                    }),
              diffPropertyOperation(fromProperty.operation, toProperty.operation, propertyId),
              diffPropertyList(fromProperty.list, toProperty.list, propertyId),
              diffPropertyType(fromProperty.type_, toProperty.type_, propertyId),
              diffPropertyValidations(fromProperty.validations, toProperty.validations, hasMigratedToEventSpecificAllowedValues, propertyId),
              diffPropertyEventSpecificAllowedPropertyValues(fromProperty.eventSpecificAllowedPropertyValues, toProperty.eventSpecificAllowedPropertyValues, toMappedEvents, toModel, propertyId)
            ]);
}

function getNewAndUpdatedPropertyActions(fromMappedProperties, fromMappedPropertiesArchive, fromModel, toModel, toMappedEvents, hasMigratedToEventSpecificAllowedValues, toProperty) {
  var property = Curry._2(TrackingPlanMappedModel.Properties.get, fromMappedProperties, toProperty.id);
  var maybeFromProperty;
  if (property !== undefined) {
    maybeFromProperty = property;
  } else {
    var match = toProperty.globalRequirementsMetadata;
    maybeFromProperty = match !== undefined ? Curry._2(TrackingPlanMappedModel.Properties.get, fromMappedProperties, match.fromLocalItemId) : undefined;
  }
  var maybeArchivedProperty = Curry._2(TrackingPlanMappedModel.Properties.get, fromMappedPropertiesArchive, toProperty.id);
  if (maybeFromProperty !== undefined) {
    return diffProperty(maybeFromProperty, toProperty, fromModel, toModel, toMappedEvents, hasMigratedToEventSpecificAllowedValues);
  } else if (maybeArchivedProperty !== undefined) {
    return Belt_List.concatMany([
                {
                  hd: {
                    NAME: "Unarchive",
                    VAL: {
                      NAME: "Property",
                      VAL: toProperty.id
                    }
                  },
                  tl: /* [] */0
                },
                diffProperty(maybeArchivedProperty, toProperty, fromModel, toModel, toMappedEvents, hasMigratedToEventSpecificAllowedValues)
              ]);
  } else if (toProperty.sendAs === /* SystemProperty */0) {
    return Belt_List.concatMany([
                {
                  hd: {
                    NAME: "AddSystemProperty",
                    VAL: [
                      toProperty.id,
                      toProperty.name
                    ]
                  },
                  tl: /* [] */0
                },
                diffProperty(TrackingPlanModel.emptyProperty(toProperty.id, toProperty.name, toProperty.sendAs, "string"), toProperty, fromModel, toModel, toMappedEvents, hasMigratedToEventSpecificAllowedValues)
              ]);
  } else {
    return Belt_List.concatMany([
                {
                  hd: {
                    NAME: "CreateProperty",
                    VAL: [
                      toProperty.id,
                      toProperty.name,
                      toProperty.sendAs
                    ]
                  },
                  tl: /* [] */0
                },
                diffProperty(TrackingPlanModel.emptyProperty(toProperty.id, toProperty.name, toProperty.sendAs, "string"), toProperty, fromModel, toModel, toMappedEvents, hasMigratedToEventSpecificAllowedValues)
              ]);
  }
}

function diffProperties(fromMappedProperties, toMappedProperties, toMappedPropertiesKeyedByLocalId, fromMappedPropertiesArchive, toMappedPropertiesArchive, toMappedEvents, onError, fromModel, toModel, hasMigratedToEventSpecificAllowedValues) {
  var getRemovedActions = function (fromProperty) {
    var property = Curry._2(TrackingPlanMappedModel.Properties.get, toMappedProperties, fromProperty.id);
    var maybeToProperty = property !== undefined ? property : Js_dict.get(toMappedPropertiesKeyedByLocalId, fromProperty.id);
    var maybeArchivedProperty = Curry._2(TrackingPlanMappedModel.Properties.get, toMappedPropertiesArchive, fromProperty.id);
    if (maybeToProperty !== undefined) {
      return /* [] */0;
    } else if (maybeArchivedProperty !== undefined) {
      return Belt_List.concatMany([
                  diffProperty(fromProperty, maybeArchivedProperty, fromModel, toModel, toMappedEvents, hasMigratedToEventSpecificAllowedValues),
                  {
                    hd: {
                      NAME: "Archive",
                      VAL: {
                        NAME: "Property",
                        VAL: fromProperty.id
                      }
                    },
                    tl: /* [] */0
                  }
                ]);
    } else {
      Curry._1(onError, "Illegal state in ActionDiff. Couldn't find an archived property: " + fromProperty.name + " (" + fromProperty.id + ")");
      return {
              hd: {
                NAME: "Archive",
                VAL: {
                  NAME: "Property",
                  VAL: fromProperty.id
                }
              },
              tl: /* [] */0
            };
    }
  };
  var getNewAndUpdatedActions = function (param) {
    return getNewAndUpdatedPropertyActions(fromMappedProperties, fromMappedPropertiesArchive, fromModel, toModel, toMappedEvents, hasMigratedToEventSpecificAllowedValues, param);
  };
  var newAndUpdated = Belt_List.flatten(Belt_List.fromArray(Curry._2(TrackingPlanMappedModel.Properties.mapToArray, toMappedProperties, getNewAndUpdatedActions)));
  var removed = Belt_List.flatten(Belt_List.fromArray(Curry._2(TrackingPlanMappedModel.Properties.mapToArray, fromMappedProperties, getRemovedActions)));
  return Belt_List.concat(newAndUpdated, removed);
}

function diffSourceName(fromName, toName, sourceId) {
  return Belt_List.map(diffOption(fromName, toName), (function (change) {
                return {
                        NAME: "UpdateSourceName",
                        VAL: [
                          sourceId,
                          change.VAL
                        ]
                      };
              }));
}

function diffSourceLanguage(fromLanguage, toLanguage, sourceId) {
  var toLang;
  if (fromLanguage !== undefined) {
    if (toLanguage === undefined) {
      return /* [] */0;
    }
    var toLang$1 = Caml_option.valFromOption(toLanguage);
    if (Caml_obj.equal(Caml_option.valFromOption(fromLanguage), toLang$1)) {
      return /* [] */0;
    }
    toLang = toLang$1;
  } else {
    if (toLanguage === undefined) {
      return /* [] */0;
    }
    toLang = Caml_option.valFromOption(toLanguage);
  }
  return {
          hd: {
            NAME: "UpdateProgrammingLanguage",
            VAL: [
              sourceId,
              toLang
            ]
          },
          tl: /* [] */0
        };
}

function diffSourcePlatform(fromPlatform, toPlatform, sourceId) {
  var toPlatform$1;
  if (fromPlatform !== undefined) {
    if (toPlatform === undefined) {
      return /* [] */0;
    }
    var toPlatform$2 = Caml_option.valFromOption(toPlatform);
    if (Caml_obj.equal(Caml_option.valFromOption(fromPlatform), toPlatform$2)) {
      return /* [] */0;
    }
    toPlatform$1 = toPlatform$2;
  } else {
    if (toPlatform === undefined) {
      return /* [] */0;
    }
    toPlatform$1 = Caml_option.valFromOption(toPlatform);
  }
  return {
          hd: {
            NAME: "UpdateSourcePlatform",
            VAL: [
              sourceId,
              toPlatform$1
            ]
          },
          tl: /* [] */0
        };
}

function diffSourceDestinations(fromDestinations, toDestinations, sourceId) {
  var newAndUpdated = BeltListExtensions.flatMap(toDestinations, (function (toDestination) {
          var destinationId = toDestination.destinationId;
          var maybeFromSourceDestination = Belt_List.getBy(fromDestinations, (function (fromDestination) {
                  return fromDestination.destinationId === destinationId;
                }));
          if (maybeFromSourceDestination !== undefined) {
            if (maybeFromSourceDestination.destinationMode !== toDestination.destinationMode) {
              return {
                      hd: {
                        NAME: "UpdateSourceDestinationMode",
                        VAL: [
                          sourceId,
                          destinationId,
                          toDestination.destinationMode
                        ]
                      },
                      tl: /* [] */0
                    };
            } else {
              return /* [] */0;
            }
          }
          var destinationMode = toDestination.destinationMode;
          return Belt_List.concat({
                      hd: {
                        NAME: "IncludeDestinationInSourceV2",
                        VAL: [
                          sourceId,
                          destinationId,
                          /* [] */0
                        ]
                      },
                      tl: /* [] */0
                    }, destinationMode !== 1 ? ({
                          hd: {
                            NAME: "UpdateSourceDestinationMode",
                            VAL: [
                              sourceId,
                              destinationId,
                              destinationMode
                            ]
                          },
                          tl: /* [] */0
                        }) : /* [] */0);
        }));
  var removed = Belt_List.keepMap(fromDestinations, (function (fromDestination) {
          var destinationId = fromDestination.destinationId;
          var maybeToDestination = Belt_List.getBy(toDestinations, (function (destination) {
                  return destination.destinationId === destinationId;
                }));
          if (maybeToDestination !== undefined) {
            return ;
          } else {
            return {
                    NAME: "ExcludeDestinationFromSource",
                    VAL: [
                      sourceId,
                      destinationId
                    ]
                  };
          }
        }));
  return Belt_List.concat(newAndUpdated, removed);
}

function diffSourceFilename(fromFilename, toFilename, sourceId) {
  if (Caml_obj.notequal(fromFilename, toFilename)) {
    return {
            hd: {
              NAME: "UpdateLibraryName",
              VAL: [
                sourceId,
                toFilename
              ]
            },
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function diffSourcePath(fromPath, toPath, sourceId) {
  if (Caml_obj.notequal(fromPath, toPath)) {
    return {
            hd: {
              NAME: "UpdateLibraryDestination",
              VAL: [
                sourceId,
                toPath
              ]
            },
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function diffSourceSegmentIntegrations(fromIntegrations, toIntegrations, sourceId) {
  return Belt_List.map(diffStringList(fromIntegrations, toIntegrations), (function (change) {
                if (change.NAME === "Remove") {
                  return {
                          NAME: "RemoveSourceSegmentIntegration",
                          VAL: [
                            sourceId,
                            change.VAL[0]
                          ]
                        };
                }
                var match = change.VAL;
                return {
                        NAME: "AddSourceSegmentIntegration",
                        VAL: [
                          sourceId,
                          match[0],
                          match[1]
                        ]
                      };
              }));
}

function diffSourceConfig(fromSourceConfig, toSourceConfig, sourceId) {
  return Belt_List.map(diffStringList(fromSourceConfig, toSourceConfig), (function (change) {
                if (change.NAME === "Remove") {
                  return {
                          NAME: "RemoveSourceConfig",
                          VAL: [
                            sourceId,
                            change.VAL
                          ]
                        };
                } else {
                  return {
                          NAME: "AddSourceConfig",
                          VAL: [
                            sourceId,
                            change.VAL
                          ]
                        };
                }
              }));
}

function diffSource(fromSource, toSource) {
  var sourceId = toSource.id;
  return Belt_List.concatMany([
              diffSourceName(fromSource.name, toSource.name, sourceId),
              diffSourceLanguage(fromSource.language, toSource.language, sourceId),
              diffSourcePlatform(fromSource.platform, toSource.platform, sourceId),
              diffSourceDestinations(fromSource.destinations, toSource.destinations, sourceId),
              diffSourceFilename(fromSource.filename, toSource.filename, sourceId),
              diffSourcePath(fromSource.path, toSource.path, sourceId),
              diffSourceSegmentIntegrations(fromSource.segmentDestinationOptions, toSource.segmentDestinationOptions, sourceId),
              diffSourceConfig(fromSource.config, toSource.config, sourceId)
            ]);
}

function diffSources(fromSources, toSources, fromSourcesArchive, toSourcesArchive, onError) {
  var newAndUpdated = BeltListExtensions.flatMap(toSources, (function (toSource) {
          var sourceId = toSource.id;
          var maybeFromSource = Belt_List.getByU(fromSources, (function (fromSource) {
                  return fromSource.id === sourceId;
                }));
          var maybeArchivedSource = Belt_List.getByU(fromSourcesArchive, (function (fromSource) {
                  return fromSource.id === sourceId;
                }));
          if (maybeFromSource !== undefined) {
            return diffSource(maybeFromSource, toSource);
          } else if (maybeArchivedSource !== undefined) {
            return Belt_List.concat({
                        hd: {
                          NAME: "Unarchive",
                          VAL: {
                            NAME: "Source",
                            VAL: sourceId
                          }
                        },
                        tl: /* [] */0
                      }, diffSource(maybeArchivedSource, toSource));
          } else {
            return Belt_List.concat({
                        hd: {
                          NAME: "CreateSource",
                          VAL: sourceId
                        },
                        tl: /* [] */0
                      }, diffSource(TrackingPlanModel.emptySource(undefined, sourceId, AvoConfig.defaultFilename, AvoConfig.defaultPath), toSource));
          }
        }));
  var removed = Belt_List.flatten(Belt_List.map(fromSources, (function (fromSource) {
              var sourceId = fromSource.id;
              var maybeToSource = Belt_List.getBy(toSources, (function (source) {
                      return source.id === sourceId;
                    }));
              var maybeArchivedSource = Belt_List.getByU(toSourcesArchive, (function (source) {
                      return source.id === sourceId;
                    }));
              if (maybeToSource !== undefined) {
                return /* [] */0;
              } else if (maybeArchivedSource !== undefined) {
                return Belt_List.concat(diffSource(fromSource, maybeArchivedSource), {
                            hd: {
                              NAME: "Archive",
                              VAL: {
                                NAME: "Source",
                                VAL: sourceId
                              }
                            },
                            tl: /* [] */0
                          });
              } else {
                Curry._1(onError, "Illegal state in ActionDiff. Couldn't find an archived source: " + Belt_Option.getWithDefault(fromSource.name, "N/A") + " (" + fromSource.id + ")");
                return {
                        hd: {
                          NAME: "Archive",
                          VAL: {
                            NAME: "Source",
                            VAL: sourceId
                          }
                        },
                        tl: /* [] */0
                      };
              }
            })));
  return Belt_List.concat(newAndUpdated, removed);
}

function diffDestinationName(fromName, toName, destinationId) {
  return Belt_List.map(diffOption(fromName, toName), (function (change) {
                return {
                        NAME: "UpdateDestinationName",
                        VAL: [
                          destinationId,
                          change.VAL
                        ]
                      };
              }));
}

function diffDestinationType(fromType, toType, destinationId) {
  return Belt_List.map(diffOption(fromType, toType), (function (change) {
                return {
                        NAME: "UpdateDestinationAnalyticsTool",
                        VAL: [
                          destinationId,
                          change.VAL
                        ]
                      };
              }));
}

function diffDestinationApiKeys(fromKeys, toKeys, destinationId) {
  return Belt_List.concatMany([
              Belt_List.map(diffOption(fromKeys.dev, toKeys.dev), (function (change) {
                      return {
                              NAME: "UpdateDestinationDevApiKey",
                              VAL: [
                                destinationId,
                                change.VAL
                              ]
                            };
                    })),
              Belt_List.map(diffOption(fromKeys.prod, toKeys.prod), (function (change) {
                      return {
                              NAME: "UpdateDestinationProdApiKey",
                              VAL: [
                                destinationId,
                                change.VAL
                              ]
                            };
                    })),
              Belt_List.map(diffStringList(fromKeys.other, toKeys.other), (function (change) {
                      if (change.NAME === "Remove") {
                        return {
                                NAME: "RemoveDestinationApiKey",
                                VAL: [
                                  destinationId,
                                  change.VAL[0]
                                ]
                              };
                      }
                      var match = change.VAL;
                      return {
                              NAME: "UpdateDestinationApiKey",
                              VAL: [
                                destinationId,
                                match[0],
                                match[1]
                              ]
                            };
                    }))
            ]);
}

function diffDestinationIncludeUserPropsWithEventProps(fromInclude, toInclude, destinationId) {
  if (Caml_obj.notequal(fromInclude, toInclude)) {
    return {
            hd: {
              NAME: "UpdateDestinationIncludeUserPropsWithEventProps",
              VAL: [
                destinationId,
                toInclude
              ]
            },
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function diffDestinationDisabledByDefault(fromDisabledByDefault, toDisabledByDefault, destinationId) {
  if (Caml_obj.notequal(fromDisabledByDefault, toDisabledByDefault)) {
    return {
            hd: {
              NAME: "UpdateDestinationDisabledByDefault",
              VAL: [
                destinationId,
                toDisabledByDefault
              ]
            },
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function diffDestination(fromDestination, toDestination) {
  var destinationId = toDestination.id;
  return Belt_List.concatMany([
              diffDestinationName(fromDestination.name, toDestination.name, destinationId),
              diffDestinationType(fromDestination.type_, toDestination.type_, destinationId),
              diffDestinationApiKeys(fromDestination.apiKeys, toDestination.apiKeys, destinationId),
              diffDestinationIncludeUserPropsWithEventProps(fromDestination.includeUserPropsWithEventProps, toDestination.includeUserPropsWithEventProps, destinationId),
              diffDestinationDisabledByDefault(fromDestination.disabledByDefault, toDestination.disabledByDefault, destinationId)
            ]);
}

function diffDestinations(fromDestinations, toDestinations, fromDestinationsArchive, toDestinationsArchive, onError) {
  var newAndUpdated = BeltListExtensions.flatMap(toDestinations, (function (toDestination) {
          var destinationId = toDestination.id;
          var maybeFromDestination = Belt_List.getByU(fromDestinations, (function (fromDestination) {
                  return fromDestination.id === destinationId;
                }));
          var maybeArchivedDestination = Belt_List.getByU(fromDestinationsArchive, (function (fromDestination) {
                  return fromDestination.id === destinationId;
                }));
          if (maybeFromDestination !== undefined) {
            return diffDestination(maybeFromDestination, toDestination);
          } else if (maybeArchivedDestination !== undefined) {
            return Belt_List.concat({
                        hd: {
                          NAME: "Unarchive",
                          VAL: {
                            NAME: "Destination",
                            VAL: destinationId
                          }
                        },
                        tl: /* [] */0
                      }, diffDestination(maybeArchivedDestination, toDestination));
          } else {
            return Belt_List.concat({
                        hd: {
                          NAME: "CreateDestination",
                          VAL: destinationId
                        },
                        tl: /* [] */0
                      }, diffDestination(TrackingPlanModel.emptyDestination(destinationId), toDestination));
          }
        }));
  var removed = Belt_List.flatten(Belt_List.map(fromDestinations, (function (fromDestination) {
              var destinationId = fromDestination.id;
              var maybeToDestination = Belt_List.getBy(toDestinations, (function (destination) {
                      return destination.id === destinationId;
                    }));
              var maybeArchivedDestination = Belt_List.getByU(toDestinationsArchive, (function (destination) {
                      return destination.id === destinationId;
                    }));
              if (maybeToDestination !== undefined) {
                return /* [] */0;
              } else if (maybeArchivedDestination !== undefined) {
                return Belt_List.concat(diffDestination(fromDestination, maybeArchivedDestination), {
                            hd: {
                              NAME: "Archive",
                              VAL: {
                                NAME: "Destination",
                                VAL: destinationId
                              }
                            },
                            tl: /* [] */0
                          });
              } else {
                Curry._1(onError, "Illegal state in ActionDiff. Couldn't find an archived destination: " + Belt_Option.getWithDefault(fromDestination.name, "N/A") + " (" + fromDestination.id + ")");
                return {
                        hd: {
                          NAME: "Archive",
                          VAL: {
                            NAME: "Destination",
                            VAL: destinationId
                          }
                        },
                        tl: /* [] */0
                      };
              }
            })));
  return Belt_List.concat(newAndUpdated, removed);
}

function diffMetricName(fromName, toName, metricId) {
  if (Caml_obj.notequal(fromName, toName)) {
    return {
            hd: {
              NAME: "UpdateMetricName",
              VAL: [
                metricId,
                toName
              ]
            },
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function diffMetricType(fromType, toType, metricId) {
  if (Caml_obj.notequal(fromType, toType)) {
    return {
            hd: {
              NAME: "UpdateMetricType",
              VAL: [
                metricId,
                toType
              ]
            },
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function diffMetricDescription(fromDescription, toDescription, metricId) {
  if (Caml_obj.notequal(fromDescription, toDescription)) {
    return {
            hd: {
              NAME: "UpdateMetricDescription",
              VAL: [
                metricId,
                toDescription
              ]
            },
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function diffMetricItems(fromItems, toItems, toMappedEvents, metricId) {
  var listDiff = BeltListExtensions.flatMap(diffList(fromItems, toItems, (function (a, b) {
              var b$1 = b.VAL;
              var a$1 = a.VAL;
              var eventWithOldIdA = Belt_Option.map(Belt_Option.flatMap(Curry._2(TrackingPlanMappedModel.Events.get, toMappedEvents, a$1.eventId), (function ($$event) {
                          return Belt_Option.map($$event.globalRequirementsMetadata, (function (param) {
                                        return param.fromLocalItemId;
                                      }));
                        })), (function (oldId) {
                      return {
                              id: a$1.id,
                              eventId: oldId,
                              where: a$1.where,
                              groupBy: a$1.groupBy
                            };
                    }));
              var eventWithOldIdB = Belt_Option.map(Belt_Option.flatMap(Curry._2(TrackingPlanMappedModel.Events.get, toMappedEvents, b$1.eventId), (function ($$event) {
                          return Belt_Option.map($$event.globalRequirementsMetadata, (function (param) {
                                        return param.fromLocalItemId;
                                      }));
                        })), (function (oldId) {
                      return {
                              id: b$1.id,
                              eventId: oldId,
                              where: b$1.where,
                              groupBy: b$1.groupBy
                            };
                    }));
              if (Caml_obj.equal(a$1, b$1) || Belt_Option.mapWithDefault(eventWithOldIdA, false, (function (metricEvent) {
                        return Caml_obj.equal(metricEvent, b$1);
                      }))) {
                return true;
              } else {
                return Belt_Option.mapWithDefault(eventWithOldIdB, false, (function (metricEvent) {
                              return Caml_obj.equal(a$1, metricEvent);
                            }));
              }
            })), (function (change) {
          if (change.NAME === "Remove") {
            var match = change.VAL.VAL;
            return {
                    hd: {
                      NAME: "RemoveEventFromMetric",
                      VAL: [
                        metricId,
                        match.id,
                        match.eventId
                      ]
                    },
                    tl: /* [] */0
                  };
          }
          var match$1 = change.VAL.VAL;
          var eventId = match$1.eventId;
          var id = match$1.id;
          return Belt_List.concatMany([
                      {
                        hd: {
                          NAME: "AddEventToMetric",
                          VAL: [
                            metricId,
                            id,
                            eventId
                          ]
                        },
                        tl: /* [] */0
                      },
                      Belt_List.map(match$1.where, (function (param) {
                              return {
                                      NAME: "SetMetricWhereV2",
                                      VAL: [
                                        metricId,
                                        id,
                                        eventId,
                                        param.id,
                                        param.propertyId,
                                        param.binOp,
                                        param.literals
                                      ]
                                    };
                            })),
                      Belt_List.map(match$1.groupBy, (function (param) {
                              return {
                                      NAME: "SetMetricGroupByV2",
                                      VAL: [
                                        metricId,
                                        id,
                                        eventId,
                                        param.id,
                                        param.propertyId
                                      ]
                                    };
                            }))
                    ]);
        }));
  var orderDiff = Belt_List.map(diffListOrder(fromItems, toItems, (function (prevPredecessor, nextPredecessor) {
              if (prevPredecessor !== undefined) {
                if (nextPredecessor !== undefined) {
                  return prevPredecessor.VAL.id === nextPredecessor.VAL.id;
                } else {
                  return false;
                }
              } else {
                return nextPredecessor === undefined;
              }
            }), (function (list, item) {
              var eventItem = item.VAL;
              return Belt_List.get(Belt_List.keepMap(list, (function (param) {
                                if (param[1].VAL.id === eventItem.id) {
                                  return param[0];
                                }
                                
                              })), 0);
            }), undefined), (function (change) {
          var match = change.VAL;
          var match$1 = match[1];
          var id = match[0].VAL.id;
          if (match$1 !== undefined) {
            return {
                    NAME: "ReorderEventsInMetricV2",
                    VAL: [
                      metricId,
                      id,
                      match$1.VAL.id
                    ]
                  };
          } else {
            return {
                    NAME: "ReorderEventsInMetricV2",
                    VAL: [
                      metricId,
                      id,
                      undefined
                    ]
                  };
          }
        }));
  return Belt_List.concat(listDiff, orderDiff);
}

function diffMetric(fromMetric, toMetric, toMappedEvents) {
  var metricId = toMetric.id;
  return Belt_List.concatMany([
              diffMetricName(fromMetric.name, toMetric.name, metricId),
              diffMetricDescription(fromMetric.description, toMetric.description, metricId),
              diffMetricType(fromMetric.type_, toMetric.type_, metricId),
              diffMetricItems(fromMetric.items, toMetric.items, toMappedEvents, metricId)
            ]);
}

function diffMetrics(fromMappedMetrics, toMappedMetrics, fromMappedMetricsArchive, toMappedMetricsArchive, toMappedEvents, onError) {
  var newAndUpdated = BeltListExtensions.flatMap(Curry._1(TrackingPlanMappedModel.Metrics.toList, toMappedMetrics), (function (toMetric) {
          var metricId = toMetric.id;
          var maybeFromMetric = Curry._2(TrackingPlanMappedModel.Metrics.get, fromMappedMetrics, metricId);
          var maybeArchivedMetric = Curry._2(TrackingPlanMappedModel.Metrics.get, fromMappedMetricsArchive, metricId);
          if (maybeFromMetric !== undefined) {
            return diffMetric(maybeFromMetric, toMetric, toMappedEvents);
          } else if (maybeArchivedMetric !== undefined) {
            return Belt_List.concat({
                        hd: {
                          NAME: "Unarchive",
                          VAL: {
                            NAME: "Metric",
                            VAL: toMetric.id
                          }
                        },
                        tl: /* [] */0
                      }, diffMetric(maybeArchivedMetric, toMetric, toMappedEvents));
          } else {
            return Belt_List.concat({
                        hd: {
                          NAME: "AddMetric",
                          VAL: toMetric.id
                        },
                        tl: /* [] */0
                      }, diffMetric(TrackingPlanModel.emptyMetric(toMetric.id), toMetric, toMappedEvents));
          }
        }));
  var removed = Belt_List.flatten(Belt_List.map(Curry._1(TrackingPlanMappedModel.Metrics.toList, fromMappedMetrics), (function (fromMetric) {
              var metricId = fromMetric.id;
              var maybeToMetric = Curry._2(TrackingPlanMappedModel.Metrics.get, toMappedMetrics, metricId);
              var maybeArchivedMetric = Curry._2(TrackingPlanMappedModel.Metrics.get, toMappedMetricsArchive, metricId);
              if (maybeToMetric !== undefined) {
                return /* [] */0;
              } else if (maybeArchivedMetric !== undefined) {
                return Belt_List.concat(diffMetric(fromMetric, maybeArchivedMetric, toMappedEvents), {
                            hd: {
                              NAME: "Archive",
                              VAL: {
                                NAME: "Metric",
                                VAL: metricId
                              }
                            },
                            tl: /* [] */0
                          });
              } else {
                Curry._1(onError, "Illegal state in ActionDiff. Couldn't find an archived metric: " + fromMetric.name + " (" + fromMetric.id + ")");
                return {
                        hd: {
                          NAME: "Archive",
                          VAL: {
                            NAME: "Metric",
                            VAL: metricId
                          }
                        },
                        tl: /* [] */0
                      };
              }
            })));
  return Belt_List.concat(newAndUpdated, removed);
}

function diffGoalName(fromName, toName, goalId) {
  if (Caml_obj.notequal(fromName, toName)) {
    return {
            hd: {
              NAME: "UpdateGoalName",
              VAL: [
                goalId,
                toName
              ]
            },
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function diffGoalDescription(fromDescription, toDescription, goalId) {
  if (Caml_obj.notequal(fromDescription, toDescription)) {
    return {
            hd: {
              NAME: "UpdateGoalDescription",
              VAL: [
                goalId,
                toDescription
              ]
            },
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function diffGoalMetrics(fromMetrics, toMetrics, goalId) {
  var listDiff = Belt_List.map(diffStringList(fromMetrics, toMetrics), (function (change) {
          if (change.NAME === "Remove") {
            return {
                    NAME: "RemoveMetricFromGoal",
                    VAL: [
                      goalId,
                      change.VAL
                    ]
                  };
          } else {
            return {
                    NAME: "AddMetricToGoal",
                    VAL: [
                      goalId,
                      change.VAL
                    ]
                  };
          }
        }));
  var orderDiff = Belt_List.map(diffListOrder(fromMetrics, toMetrics, undefined, (function (list, metricId) {
              return Belt_List.get(Belt_List.keepMap(list, (function (param) {
                                if (Caml_obj.equal(param[1], metricId)) {
                                  return param[0];
                                }
                                
                              })), 0);
            }), undefined), (function (change) {
          var match = change.VAL;
          return {
                  NAME: "ReorderMetricsInGoalV2",
                  VAL: [
                    goalId,
                    match[0],
                    match[1]
                  ]
                };
        }));
  return Belt_List.concat(listDiff, orderDiff);
}

function diffGoalEvents(fromEvents, toEvents, toMappedEvents, goalId) {
  var toEventIds = Belt_List.map(toEvents, (function (eventId) {
          var oldId = Belt_Option.flatMap(Curry._2(TrackingPlanMappedModel.Events.get, toMappedEvents, eventId), (function ($$event) {
                  return Belt_Option.map($$event.globalRequirementsMetadata, (function (globalRequirementsMetadata) {
                                return globalRequirementsMetadata.fromLocalItemId;
                              }));
                }));
          return {
                  id: eventId,
                  oldId: oldId
                };
        }));
  var listDiff = Belt_List.map(diffStringListWithListOfItemsWithOldIds(fromEvents, toEventIds), (function (change) {
          if (change.NAME === "Remove") {
            return {
                    NAME: "RemoveEventFromGoal",
                    VAL: [
                      goalId,
                      change.VAL
                    ]
                  };
          } else {
            return {
                    NAME: "AddEventToGoal",
                    VAL: [
                      goalId,
                      change.VAL
                    ]
                  };
          }
        }));
  var orderDiff = Belt_List.map(diffListOrderWithOldIds(fromEvents, toEventIds, (function (list, item) {
              return Belt_List.get(Belt_List.keepMap(list, (function (param) {
                                var prevItem = param[1];
                                if (prevItem === item.id || Belt_Option.mapWithDefault(item.oldId, false, (function (oldId) {
                                          return prevItem === oldId;
                                        }))) {
                                  return param[0];
                                }
                                
                              })), 0);
            })), (function (change) {
          var match = change.VAL;
          return {
                  NAME: "ReorderEventsInGoalV2",
                  VAL: [
                    goalId,
                    match[0].id,
                    match[1]
                  ]
                };
        }));
  return Belt_List.concat(listDiff, orderDiff);
}

function diffGoal(fromGoal, toGoal, toMappedEvents) {
  var goalId = toGoal.id;
  return Belt_List.concatMany([
              diffGoalName(fromGoal.name, toGoal.name, goalId),
              diffGoalDescription(fromGoal.description, toGoal.description, goalId),
              diffGoalMetrics(fromGoal.metrics, toGoal.metrics, goalId),
              diffGoalEvents(fromGoal.events, toGoal.events, toMappedEvents, goalId)
            ]);
}

function diffGoals(fromGoals, toGoals, fromGoalsArchive, toGoalsArchive, toMappedEvents, onError) {
  var newAndUpdated = BeltListExtensions.flatMap(toGoals, (function (toGoal) {
          var goalId = toGoal.id;
          var maybeFromGoal = Belt_List.getByU(fromGoals, (function (fromGoal) {
                  return fromGoal.id === goalId;
                }));
          var maybeArchivedGoal = Belt_List.getByU(fromGoalsArchive, (function (fromGoal) {
                  return fromGoal.id === goalId;
                }));
          if (maybeFromGoal !== undefined) {
            return diffGoal(maybeFromGoal, toGoal, toMappedEvents);
          } else if (maybeArchivedGoal !== undefined) {
            return Belt_List.concat({
                        hd: {
                          NAME: "Unarchive",
                          VAL: {
                            NAME: "Goal",
                            VAL: goalId
                          }
                        },
                        tl: /* [] */0
                      }, diffGoal(maybeArchivedGoal, toGoal, toMappedEvents));
          } else {
            return Belt_List.concat({
                        hd: {
                          NAME: "AddGoal",
                          VAL: goalId
                        },
                        tl: /* [] */0
                      }, diffGoal(TrackingPlanModel.emptyGoal(goalId), toGoal, toMappedEvents));
          }
        }));
  var removed = Belt_List.flatten(Belt_List.map(fromGoals, (function (fromGoal) {
              var goalId = fromGoal.id;
              var maybeToGoal = Belt_List.getBy(toGoals, (function (goal) {
                      return goal.id === goalId;
                    }));
              var maybeArchivedGoal = Belt_List.getByU(toGoalsArchive, (function (goal) {
                      return goal.id === goalId;
                    }));
              if (maybeToGoal !== undefined) {
                return /* [] */0;
              } else if (maybeArchivedGoal !== undefined) {
                return Belt_List.concat(diffGoal(fromGoal, maybeArchivedGoal, toMappedEvents), {
                            hd: {
                              NAME: "Archive",
                              VAL: {
                                NAME: "Goal",
                                VAL: goalId
                              }
                            },
                            tl: /* [] */0
                          });
              } else {
                Curry._1(onError, "Illegal state in ActionDiff. Couldn't find an archived goal: " + fromGoal.name + " (" + fromGoal.id + ")");
                return {
                        hd: {
                          NAME: "Archive",
                          VAL: {
                            NAME: "Goal",
                            VAL: goalId
                          }
                        },
                        tl: /* [] */0
                      };
              }
            })));
  var reorders = Belt_List.map(diffListOrder(fromGoals, toGoals, undefined, (function (goals, goal) {
              return Belt_List.get(Belt_List.keepMap(goals, (function (param) {
                                if (param[1].id === goal.id) {
                                  return param[0];
                                }
                                
                              })), 0);
            }), undefined), (function (change) {
          var match = change.VAL;
          var match$1 = match[1];
          var goal = match[0];
          if (match$1 !== undefined) {
            return {
                    NAME: "ReorderGoalsV2",
                    VAL: [
                      goal.id,
                      match$1.id
                    ]
                  };
          } else {
            return {
                    NAME: "ReorderGoalsV2",
                    VAL: [
                      goal.id,
                      undefined
                    ]
                  };
          }
        }));
  return Belt_List.concatMany([
              newAndUpdated,
              reorders,
              removed
            ]);
}

function diffGroupId(fromGroupId, toGroupId, toGlobalRequirements) {
  if (toGlobalRequirements === undefined) {
    return /* [] */0;
  }
  if (fromGroupId === toGroupId) {
    return /* [] */0;
  }
  var metadata = toGlobalRequirements.metadata;
  var schemaId = metadata.schemaId;
  var toGlobalPropertyBundle = Belt_List.getByU(toGlobalRequirements.propertyBundles, (function (propertyBundle) {
          return propertyBundle.id === toGroupId;
        }));
  return Belt_Option.mapWithDefault(toGlobalPropertyBundle, /* [] */0, (function (toGlobalPropertyBundle) {
                return {
                        hd: {
                          NAME: "ConvertPropertyBundleToGlobalPropertyBundle",
                          VAL: [
                            fromGroupId,
                            toGroupId,
                            schemaId,
                            metadata,
                            toGlobalPropertyBundle
                          ]
                        },
                        tl: /* [] */0
                      };
              }));
}

function diffGroupName(fromName, toName, groupId) {
  if (Caml_obj.notequal(fromName, toName)) {
    return {
            hd: {
              NAME: "UpdatePropertyGroupName",
              VAL: [
                groupId,
                toName
              ]
            },
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function diffGroupDescription(fromDescription, toDescription, groupId) {
  if (Caml_obj.notequal(fromDescription, toDescription)) {
    return {
            hd: {
              NAME: "UpdatePropertyGroupDescription",
              VAL: [
                groupId,
                toDescription
              ]
            },
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function diffGroupProperties(fromProperties, toProperties, toMappedProperties, groupId) {
  var toPropertyIds = Belt_List.map(toProperties, (function (propertyId) {
          var fromLocalId = Belt_Option.flatMap(Curry._2(TrackingPlanMappedModel.Properties.get, toMappedProperties, propertyId), (function (property) {
                  return Belt_Option.map(property.globalRequirementsMetadata, (function (param) {
                                return param.fromLocalItemId;
                              }));
                }));
          return {
                  id: propertyId,
                  oldId: fromLocalId
                };
        }));
  return Belt_List.map(diffStringListWithListOfItemsWithOldIds(fromProperties, toPropertyIds), (function (change) {
                if (change.NAME === "Remove") {
                  return {
                          NAME: "RemovePropertyFromGroup",
                          VAL: [
                            groupId,
                            change.VAL
                          ]
                        };
                } else {
                  return {
                          NAME: "AddPropertyToGroup",
                          VAL: [
                            groupId,
                            change.VAL
                          ]
                        };
                }
              }));
}

function diffGroup(fromGroup, toGroup, toGlobalRequirements, toMappedProperties) {
  var groupId = toGroup.id;
  return Belt_List.concatMany([
              diffGroupId(fromGroup.id, toGroup.id, toGlobalRequirements),
              diffGroupName(fromGroup.name, toGroup.name, groupId),
              diffGroupDescription(fromGroup.description, toGroup.description, groupId),
              diffGroupProperties(fromGroup.properties, toGroup.properties, toMappedProperties, groupId)
            ]);
}

function diffPropertyGroups(fromGroups, toGroups, fromGroupsArchive, toGroupsArchive, toMappedPropertyBundles, toGlobalRequirements, toMappedProperties) {
  var newAndUpdated = BeltListExtensions.flatMap(toGroups, (function (toGroup) {
          var groupId = toGroup.id;
          var maybeFromGroup = Belt_List.getByU(fromGroups, (function (fromGroup) {
                  var match = Curry._2(TrackingPlanMappedModel.PropertyBundles.get, toMappedPropertyBundles, groupId);
                  if (match === undefined) {
                    return fromGroup.id === groupId;
                  }
                  var match$1 = match.globalRequirementsMetadata;
                  if (match$1 !== undefined && fromGroup.id === match$1.fromLocalItemId) {
                    return true;
                  } else {
                    return fromGroup.id === groupId;
                  }
                }));
          var maybeArchivedGroup = Belt_List.getByU(fromGroupsArchive, (function (fromGroup) {
                  return fromGroup.id === groupId;
                }));
          if (maybeFromGroup !== undefined) {
            return diffGroup(maybeFromGroup, toGroup, toGlobalRequirements, toMappedProperties);
          } else if (maybeArchivedGroup !== undefined) {
            return Belt_List.concat({
                        hd: {
                          NAME: "Unarchive",
                          VAL: {
                            NAME: "PropertyGroup",
                            VAL: groupId
                          }
                        },
                        tl: /* [] */0
                      }, diffGroup(maybeArchivedGroup, toGroup, toGlobalRequirements, toMappedProperties));
          } else {
            return Belt_List.concat({
                        hd: {
                          NAME: "CreatePropertyGroup",
                          VAL: [
                            groupId,
                            toGroup.name
                          ]
                        },
                        tl: /* [] */0
                      }, diffGroup(TrackingPlanModel.emptyPropertyBundle(groupId), toGroup, toGlobalRequirements, toMappedProperties));
          }
        }));
  var removed = Belt_List.flatten(Belt_List.map(fromGroups, (function (fromGroup) {
              var groupId = fromGroup.id;
              var maybeToGroup = Belt_List.getBy(toGroups, (function (group) {
                      if (group.id === groupId) {
                        return true;
                      } else {
                        return Belt_Option.mapWithDefault(group.globalRequirementsMetadata, false, (function (param) {
                                      return param.fromLocalItemId === groupId;
                                    }));
                      }
                    }));
              var maybeArchivedGroup = Belt_List.getByU(toGroupsArchive, (function (group) {
                      return group.id === groupId;
                    }));
              if (maybeToGroup !== undefined) {
                return /* [] */0;
              } else if (maybeArchivedGroup !== undefined) {
                return Belt_List.concat(diffGroup(fromGroup, maybeArchivedGroup, toGlobalRequirements, toMappedProperties), {
                            hd: {
                              NAME: "Archive",
                              VAL: {
                                NAME: "PropertyGroup",
                                VAL: groupId
                              }
                            },
                            tl: /* [] */0
                          });
              } else {
                console.error("Illegal state in ActionDiff. Couldn't find an archived group: " + fromGroup.name + " (" + fromGroup.id + ")");
                return {
                        hd: {
                          NAME: "Archive",
                          VAL: {
                            NAME: "PropertyGroup",
                            VAL: groupId
                          }
                        },
                        tl: /* [] */0
                      };
              }
            })));
  return Belt_List.concat(newAndUpdated, removed);
}

function diffRule(fromRule, toRule) {
  if (Caml_obj.notequal(fromRule, toRule)) {
    return {
            hd: {
              NAME: "UpdateRule",
              VAL: [
                toRule.id,
                toRule.item,
                toRule.definition
              ]
            },
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function diffRules(fromRules, toRules, toModel) {
  var newAndUpdated = BeltListExtensions.flatMap(toRules, (function (toRule) {
          var ruleId = toRule.id;
          var maybeFromRule = Belt_List.getBy(fromRules, (function (fromRule) {
                  return fromRule.id === ruleId;
                }));
          if (maybeFromRule !== undefined) {
            return diffRule(maybeFromRule, toRule);
          } else {
            return {
                    hd: {
                      NAME: "AddRule",
                      VAL: [
                        toRule.id,
                        toRule.item,
                        toRule.definition
                      ]
                    },
                    tl: /* [] */0
                  };
          }
        }));
  var removed = Belt_List.keepMap(fromRules, (function (fromRule) {
          var ruleId = fromRule.id;
          var maybeToRule = Belt_List.getBy(toRules, (function (rule) {
                  return rule.id === ruleId;
                }));
          if (maybeToRule !== undefined) {
            return ;
          } else {
            return {
                    NAME: "RemoveRule",
                    VAL: [
                      fromRule.id,
                      fromRule.item
                    ]
                  };
          }
        }));
  var actions = Belt_List.concat(newAndUpdated, removed);
  return Belt_List.keepU(actions, (function (action) {
                var eventId;
                var propertyId;
                var nestedPropertyId;
                if (typeof action !== "object") {
                  return true;
                }
                var variant = action.NAME;
                if (variant === "AddRule") {
                  var match = action.VAL[1];
                  var variant$1 = match.NAME;
                  if (variant$1 === "PropertyRef") {
                    var match$1 = match.VAL;
                    return Belt_Option.isSome(ModelUtils.getPropertyRefById(match$1[0], match$1[1], toModel));
                  }
                  if (variant$1 === "Property") {
                    return Belt_Option.isSome(ModelUtils.getPropertyById(match.VAL, toModel));
                  }
                  if (variant$1 === "Event") {
                    return Belt_Option.isSome(ModelUtils.getEventById(match.VAL, toModel));
                  }
                  var match$2 = match.VAL;
                  eventId = match$2[0];
                  propertyId = match$2[1];
                  nestedPropertyId = match$2[2];
                } else {
                  if (variant !== "UpdateRule") {
                    return true;
                  }
                  var match$3 = action.VAL[1];
                  var variant$2 = match$3.NAME;
                  if (variant$2 === "PropertyRef") {
                    var match$4 = match$3.VAL;
                    return Belt_Option.isSome(ModelUtils.getPropertyRefById(match$4[0], match$4[1], toModel));
                  }
                  if (variant$2 === "Property") {
                    return Belt_Option.isSome(ModelUtils.getPropertyById(match$3.VAL, toModel));
                  }
                  if (variant$2 === "Event") {
                    return Belt_Option.isSome(ModelUtils.getEventById(match$3.VAL, toModel));
                  }
                  var match$5 = match$3.VAL;
                  eventId = match$5[0];
                  propertyId = match$5[1];
                  nestedPropertyId = match$5[2];
                }
                if (Belt_Option.isSome(ModelUtils.getPropertyRefById(eventId, propertyId, toModel))) {
                  return Belt_Option.isSome(ModelUtils.getNestedPropertyRefById(propertyId, nestedPropertyId, toModel));
                } else {
                  return false;
                }
              }));
}

function diffIntegration(fromIntegration, toIntegration) {
  if (!Caml_obj.notequal(fromIntegration, toIntegration)) {
    return /* [] */0;
  }
  var match = fromIntegration.name;
  var match$1 = toIntegration.name;
  var match$2 = fromIntegration.config;
  var match$3 = toIntegration.config;
  return Belt_List.concatMany([
              match !== undefined ? (
                  match$1 !== undefined && match !== match$1 ? ({
                        hd: {
                          NAME: "UpdateIntegrationName",
                          VAL: [
                            toIntegration.id,
                            match$1
                          ]
                        },
                        tl: /* [] */0
                      }) : /* [] */0
                ) : (
                  match$1 !== undefined ? ({
                        hd: {
                          NAME: "UpdateIntegrationName",
                          VAL: [
                            toIntegration.id,
                            match$1
                          ]
                        },
                        tl: /* [] */0
                      }) : /* [] */0
                ),
              Caml_obj.notequal(fromIntegration.filters, toIntegration.filters) ? ({
                    hd: {
                      NAME: "UpdateIntegrationFilters",
                      VAL: [
                        toIntegration.id,
                        toIntegration.filters
                      ]
                    },
                    tl: /* [] */0
                  }) : /* [] */0,
              match$2 !== undefined ? (
                  match$3 !== undefined && Caml_obj.notequal(match$2, match$3) ? ({
                        hd: {
                          NAME: "UpdateIntegrationConfig",
                          VAL: [
                            toIntegration.id,
                            match$3
                          ]
                        },
                        tl: /* [] */0
                      }) : /* [] */0
                ) : (
                  match$3 !== undefined ? ({
                        hd: {
                          NAME: "UpdateIntegrationConfig",
                          VAL: [
                            toIntegration.id,
                            match$3
                          ]
                        },
                        tl: /* [] */0
                      }) : /* [] */0
                ),
              fromIntegration.autoPublish !== toIntegration.autoPublish ? ({
                    hd: {
                      NAME: "UpdateIntegrationAutoPublish",
                      VAL: [
                        toIntegration.id,
                        toIntegration.autoPublish
                      ]
                    },
                    tl: /* [] */0
                  }) : /* [] */0
            ]);
}

function diffIntegrations(fromIntegrations, toIntegrations) {
  var newAndUpdated = BeltListExtensions.flatMap(toIntegrations, (function (toIntegration) {
          var integrationId = toIntegration.id;
          var maybeFromIntegration = Belt_List.getBy(fromIntegrations, (function (fromIntegration) {
                  return fromIntegration.id === integrationId;
                }));
          if (maybeFromIntegration !== undefined) {
            return diffIntegration(maybeFromIntegration, toIntegration);
          } else {
            return Belt_List.concatMany([
                        {
                          hd: {
                            NAME: "CreateIntegration",
                            VAL: toIntegration.id
                          },
                          tl: {
                            hd: {
                              NAME: "UpdateIntegrationFilters",
                              VAL: [
                                toIntegration.id,
                                toIntegration.filters
                              ]
                            },
                            tl: {
                              hd: {
                                NAME: "UpdateIntegrationAutoPublish",
                                VAL: [
                                  toIntegration.id,
                                  toIntegration.autoPublish
                                ]
                              },
                              tl: /* [] */0
                            }
                          }
                        },
                        Belt_Option.mapWithDefault(toIntegration.name, /* [] */0, (function (name) {
                                return {
                                        hd: {
                                          NAME: "UpdateIntegrationName",
                                          VAL: [
                                            toIntegration.id,
                                            name
                                          ]
                                        },
                                        tl: /* [] */0
                                      };
                              })),
                        Belt_Option.mapWithDefault(toIntegration.config, /* [] */0, (function (config) {
                                return {
                                        hd: {
                                          NAME: "UpdateIntegrationConfig",
                                          VAL: [
                                            toIntegration.id,
                                            config
                                          ]
                                        },
                                        tl: /* [] */0
                                      };
                              }))
                      ]);
          }
        }));
  var removed = Belt_List.keepMap(fromIntegrations, (function (fromIntegration) {
          var integrationId = fromIntegration.id;
          var maybeToIntegration = Belt_List.getBy(toIntegrations, (function (integration) {
                  return integration.id === integrationId;
                }));
          if (maybeToIntegration !== undefined) {
            return ;
          } else {
            return {
                    NAME: "RemoveIntegration",
                    VAL: integrationId
                  };
          }
        }));
  return Belt_List.concat(newAndUpdated, removed);
}

function diffGroupType(fromGroupType, toGroupType) {
  if (Caml_obj.notequal(fromGroupType, toGroupType)) {
    return Belt_List.concatMany([
                fromGroupType.name !== toGroupType.name ? ({
                      hd: {
                        NAME: "UpdateGroupTypeName",
                        VAL: [
                          toGroupType.id,
                          toGroupType.name
                        ]
                      },
                      tl: /* [] */0
                    }) : /* [] */0,
                /* [] */0
              ]);
  } else {
    return /* [] */0;
  }
}

function diffGroupTypes(fromGroupTypes, toGroupTypes, fromGroupTypesArchive, toGroupTypesArchive, onError) {
  var newAndUpdated = Belt_List.concatMany(Belt_Array.map(toGroupTypes, (function (toGroupType) {
              var groupTypeId = toGroupType.id;
              var maybeFromGroupType = Belt_Array.getBy(fromGroupTypes, (function (fromGroupType) {
                      return fromGroupType.id === groupTypeId;
                    }));
              var maybeArchivedGroupType = Belt_Array.getBy(fromGroupTypesArchive, (function (fromGroupType) {
                      return fromGroupType.id === groupTypeId;
                    }));
              if (maybeFromGroupType !== undefined) {
                return diffGroupType(maybeFromGroupType, toGroupType);
              } else if (maybeArchivedGroupType !== undefined) {
                return Belt_List.concat({
                            hd: {
                              NAME: "Unarchive",
                              VAL: {
                                NAME: "GroupType",
                                VAL: groupTypeId
                              }
                            },
                            tl: /* [] */0
                          }, diffGroupType(maybeArchivedGroupType, toGroupType));
              } else {
                return {
                        hd: {
                          NAME: "CreateGroupType",
                          VAL: [
                            groupTypeId,
                            toGroupType.name,
                            toGroupType.description
                          ]
                        },
                        tl: /* [] */0
                      };
              }
            })));
  var removed = Belt_List.flatten(Belt_List.fromArray(Belt_Array.map(fromGroupTypes, (function (fromGroupType) {
                  var groupTypeId = fromGroupType.id;
                  var maybeToGroupType = Belt_Array.getBy(toGroupTypes, (function (toGroupType) {
                          return toGroupType.id === groupTypeId;
                        }));
                  var maybeArchivedGroupType = Belt_Array.getByU(toGroupTypesArchive, (function (groupType) {
                          return groupType.id === groupTypeId;
                        }));
                  if (maybeToGroupType !== undefined) {
                    return /* [] */0;
                  } else if (maybeArchivedGroupType !== undefined) {
                    return Belt_List.concat(diffGroupType(fromGroupType, maybeArchivedGroupType), {
                                hd: {
                                  NAME: "Archive",
                                  VAL: {
                                    NAME: "GroupType",
                                    VAL: groupTypeId
                                  }
                                },
                                tl: /* [] */0
                              });
                  } else {
                    Curry._1(onError, "Illegal state in ActionDiff. Couldn't find an archived group type: " + fromGroupType.name + " (" + fromGroupType.id + ")");
                    return {
                            hd: {
                              NAME: "Archive",
                              VAL: {
                                NAME: "GroupType",
                                VAL: groupTypeId
                              }
                            },
                            tl: /* [] */0
                          };
                  }
                }))));
  return Belt_List.concat(newAndUpdated, removed);
}

function diffGlobalRequirements(fromGlobalRequirements, toGlobalRequirements, param) {
  if (fromGlobalRequirements === undefined) {
    if (toGlobalRequirements !== undefined) {
      return {
              hd: {
                NAME: "UpdateGlobalRequirements",
                VAL: toGlobalRequirements
              },
              tl: /* [] */0
            };
    } else {
      return /* [] */0;
    }
  }
  var metadata = fromGlobalRequirements.metadata;
  if (toGlobalRequirements !== undefined) {
    if (Caml_obj.notequal(metadata, toGlobalRequirements.metadata)) {
      return {
              hd: {
                NAME: "UpdateGlobalRequirements",
                VAL: toGlobalRequirements
              },
              tl: /* [] */0
            };
    } else {
      return /* [] */0;
    }
  } else {
    return {
            hd: {
              NAME: "UpdateGlobalRequirements",
              VAL: TrackingPlanModel.GlobalRequirements.empty({
                    schemaId: metadata.schemaId,
                    actionId: metadata.actionId,
                    branchId: metadata.branchId,
                    lastUpdatedAt: new Date()
                  })
            },
            tl: /* [] */0
          };
  }
}

function diff(onErrorOpt, fromModel, toModel, param) {
  var onError = onErrorOpt !== undefined ? onErrorOpt : (function (param) {
        
      });
  var match = ModelUtils.hasMigrated(fromModel, "PropertyOptionalAndExcludedSourcesToAbsence");
  var match$1 = ModelUtils.hasMigrated(toModel, "PropertyOptionalAndExcludedSourcesToAbsence");
  var match$2 = match ? (
      match$1 ? [
          fromModel,
          toModel
        ] : [
          fromModel,
          ActionsReducer.reduce(toModel, "MigratePropertyOptionalAndExcludedSourcesToAbsence")
        ]
    ) : (
      match$1 ? [
          ActionsReducer.reduce(fromModel, "MigratePropertyOptionalAndExcludedSourcesToAbsence"),
          toModel
        ] : [
          fromModel,
          toModel
        ]
    );
  var toModel$1 = match$2[1];
  var fromModel$1 = match$2[0];
  var match$3 = ModelUtils.hasMigrated(fromModel$1, "SkipWebFnTagToIncludeInCodegen");
  var match$4 = ModelUtils.hasMigrated(toModel$1, "SkipWebFnTagToIncludeInCodegen");
  var match$5 = match$3 ? (
      match$4 ? [
          fromModel$1,
          toModel$1
        ] : [
          fromModel$1,
          ActionsReducer.reduce(toModel$1, "MigrateSkipWebFnTagToIncludeInCodegen")
        ]
    ) : (
      match$4 ? [
          ActionsReducer.reduce(fromModel$1, "MigrateSkipWebFnTagToIncludeInCodegen"),
          toModel$1
        ] : [
          fromModel$1,
          toModel$1
        ]
    );
  var toModel$2 = match$5[1];
  var fromModel$2 = match$5[0];
  var match$6 = ModelUtils.hasMigrated(fromModel$2, "RemoveUnarchivedItemsFromArchive");
  var match$7 = ModelUtils.hasMigrated(toModel$2, "RemoveUnarchivedItemsFromArchive");
  var match$8 = match$6 ? (
      match$7 ? [
          fromModel$2,
          toModel$2
        ] : [
          fromModel$2,
          ActionsReducer.reduce(toModel$2, "MigrateRemoveUnarchivedItemsFromArchive")
        ]
    ) : (
      match$7 ? [
          ActionsReducer.reduce(fromModel$2, "MigrateRemoveUnarchivedItemsFromArchive"),
          toModel$2
        ] : [
          fromModel$2,
          toModel$2
        ]
    );
  var toModel$3 = match$8[1];
  var fromModel$3 = match$8[0];
  var match$9 = ModelUtils.hasMigrated(fromModel$3, "EventSpecificAllowedPropertyValues");
  var match$10 = ModelUtils.hasMigrated(toModel$3, "EventSpecificAllowedPropertyValues");
  var match$11 = match$9 ? (
      match$10 ? [
          fromModel$3,
          toModel$3
        ] : [
          fromModel$3,
          ActionsReducer.reduce(toModel$3, "MigrateToEventSpecificAllowedPropertyValues")
        ]
    ) : (
      match$10 ? [
          ActionsReducer.reduce(fromModel$3, "MigrateToEventSpecificAllowedPropertyValues"),
          toModel$3
        ] : [
          fromModel$3,
          toModel$3
        ]
    );
  var toModel$4 = match$11[1];
  var fromModel$4 = match$11[0];
  var hasMigratedToEventSpecificAllowedValues = ModelUtils.hasMigrated(toModel$4, "EventSpecificAllowedPropertyValues");
  var fromMappedEvents = Curry._1(TrackingPlanMappedModel.Events.fromList, fromModel$4.events);
  var toMappedEvents = Curry._1(TrackingPlanMappedModel.Events.fromList, toModel$4.events);
  var toMappedEventsKeyedByLocalId = Curry._1(TrackingPlanMappedModel.Events.Utils.getDictKeyedByLocalItemId, toMappedEvents);
  var fromMappedEventsArchive = Curry._1(TrackingPlanMappedModel.Events.fromList, fromModel$4.archive.events);
  var toMappedEventsArchive = Curry._1(TrackingPlanMappedModel.Events.fromList, toModel$4.archive.events);
  var toMappedEventsArchiveKeyedByLocalId = Curry._1(TrackingPlanMappedModel.Events.Utils.getDictKeyedByLocalItemId, toMappedEventsArchive);
  var fromMappedProperties = Curry._1(TrackingPlanMappedModel.Properties.Utils.fromOld, fromModel$4.properties);
  var toMappedProperties = Curry._1(TrackingPlanMappedModel.Properties.Utils.fromOld, toModel$4.properties);
  var toMappedPropertiesKeyedByLocalId = Curry._1(TrackingPlanMappedModel.Properties.Utils.getDictKeyedByLocalItemId, toMappedProperties);
  var fromMappedPropertiesArchive = Curry._1(TrackingPlanMappedModel.Properties.Utils.fromOld, fromModel$4.archive.properties);
  var toMappedPropertiesArchive = Curry._1(TrackingPlanMappedModel.Properties.Utils.fromOld, toModel$4.archive.properties);
  var toMappedPropertyBundles = Curry._1(TrackingPlanMappedModel.PropertyBundles.fromList, toModel$4.propertyBundles);
  var fromMappedMetrics = Curry._1(TrackingPlanMappedModel.Metrics.fromList, fromModel$4.metrics);
  var toMappedMetrics = Curry._1(TrackingPlanMappedModel.Metrics.fromList, toModel$4.metrics);
  var fromMappedMetricsArchive = Curry._1(TrackingPlanMappedModel.Metrics.fromList, fromModel$4.archive.metrics);
  var toMappedMetricsArchive = Curry._1(TrackingPlanMappedModel.Metrics.fromList, toModel$4.archive.metrics);
  var actions = Belt_List.concatMany([
        diffProperties(fromMappedProperties, toMappedProperties, toMappedPropertiesKeyedByLocalId, fromMappedPropertiesArchive, toMappedPropertiesArchive, toMappedEvents, onError, fromModel$4, toModel$4, hasMigratedToEventSpecificAllowedValues),
        diffEvents(fromMappedEvents, fromMappedEventsArchive, toMappedEvents, toMappedEventsKeyedByLocalId, toMappedEventsArchiveKeyedByLocalId, toMappedEventsArchive, toModel$4.globalRequirements, toMappedProperties, toMappedPropertyBundles, onError),
        diffSources(fromModel$4.sources, toModel$4.sources, fromModel$4.archive.sources, toModel$4.archive.sources, onError),
        diffDestinations(fromModel$4.destinations, toModel$4.destinations, fromModel$4.archive.destinations, toModel$4.archive.destinations, onError),
        Belt_List.keepU(diffMetrics(fromMappedMetrics, toMappedMetrics, fromMappedMetricsArchive, toMappedMetricsArchive, toMappedEvents, onError), (function (action) {
                if (typeof action !== "object") {
                  return true;
                }
                if (action.NAME !== "AddEventToMetric") {
                  return true;
                }
                var eventId = action.VAL[2];
                return Belt_List.someU(toModel$4.events, (function ($$event) {
                              return $$event.id === eventId;
                            }));
              })),
        diffGoals(fromModel$4.goals, toModel$4.goals, fromModel$4.archive.goals, toModel$4.archive.goals, toMappedEvents, onError),
        diffPropertyGroups(fromModel$4.propertyBundles, toModel$4.propertyBundles, fromModel$4.archive.propertyBundles, toModel$4.archive.propertyBundles, toMappedPropertyBundles, toModel$4.globalRequirements, toMappedProperties),
        diffRules(fromModel$4.rules, toModel$4.rules, toModel$4),
        diffIntegrations(fromModel$4.integrations, toModel$4.integrations),
        diffGroupTypes(fromModel$4.groupTypes, toModel$4.groupTypes, fromModel$4.archive.groupTypes, toModel$4.archive.groupTypes, onError),
        diffGlobalRequirements(fromModel$4.globalRequirements, toModel$4.globalRequirements, onError)
      ]);
  var match$12 = Belt_List.partitionU(actions, (function (action) {
          if (typeof action !== "object") {
            return false;
          }
          var variant = action.NAME;
          if (variant === "Unarchive" || variant === "CreateProperty" || variant === "CreatePropertyGroup" || variant === "CreateSource" || variant === "CreateEventVariant" || variant === "DuplicateEvent" || variant === "ExcludeEventFromSource" || variant === "CreateDestination" || variant === "IncludeEventInSource" || variant === "IncludeEventInSourceV2" || variant === "AddMetric" || variant === "CreateGroupType" || variant === "ExcludeEventFromSourceV2" || variant === "AddRule" || variant === "AddGoal") {
            return true;
          } else {
            return variant === "AddEvent";
          }
        }));
  return Belt_List.concat(match$12[0], match$12[1]);
}

export {
  diffList ,
  diffArray ,
  diffStringArray ,
  diffListOrder ,
  diffListOrderWithOldIds ,
  diffStringList ,
  diffListWithListOfItemsWithOldIds ,
  diffStringListWithListOfItemsWithOldIds ,
  diffOption ,
  diffMapString ,
  diffEventProperties ,
  diffEventPropertyGroups ,
  diffEventPropertyWhitelist ,
  diffEventGroupTypeIds ,
  diffUserGroupTypeIds ,
  diffEventTypes ,
  diffEventTags ,
  diffEventSources ,
  diffEventDestinations ,
  diffEventId ,
  diffEventName ,
  diffEventUniqueName ,
  diffEventDescription ,
  diffEventTriggerDescription ,
  diffEventTriggerSources ,
  diffEventTriggerContent ,
  diffEventTrigger ,
  diffEventTriggers ,
  diffEventVariantName ,
  diffEventVariantDescription ,
  diffEventVariantSourceOverrides ,
  diffEventVariantPropertyOverride ,
  diffEventVariantPropertyOverrides ,
  diffEventVariant ,
  diffEventVariants ,
  diffEvent ,
  getNewAndUpdatedEventActions ,
  diffEvents ,
  diffPropertyId ,
  diffPropertyName ,
  diffPropertyUniqueName ,
  diffPropertyDescription ,
  diffPropertyOptional ,
  diffPropertyOptionalWhenInObject ,
  diffPropertyAbsence ,
  diffPropertyOperation ,
  diffPropertyList ,
  diffPropertyType ,
  diffPropertyValidations ,
  diffLiteralsFast ,
  diffDisallowedEventsFast ,
  diffPropertyEventSpecificAllowedPropertyValues ,
  diffPropertyExcludedSources ,
  diffProperty ,
  getNewAndUpdatedPropertyActions ,
  diffProperties ,
  diffSourceName ,
  diffSourceLanguage ,
  diffSourcePlatform ,
  diffSourceDestinations ,
  diffSourceFilename ,
  diffSourcePath ,
  diffSourceSegmentIntegrations ,
  diffSourceConfig ,
  diffSource ,
  diffSources ,
  diffDestinationName ,
  diffDestinationType ,
  diffDestinationApiKeys ,
  diffDestinationIncludeUserPropsWithEventProps ,
  diffDestinationDisabledByDefault ,
  diffDestination ,
  diffDestinations ,
  diffMetricName ,
  diffMetricType ,
  diffMetricDescription ,
  diffMetricItems ,
  diffMetric ,
  diffMetrics ,
  diffGoalName ,
  diffGoalDescription ,
  diffGoalMetrics ,
  diffGoalEvents ,
  diffGoal ,
  diffGoals ,
  diffGroupId ,
  diffGroupName ,
  diffGroupDescription ,
  diffGroupProperties ,
  diffGroup ,
  diffPropertyGroups ,
  diffRule ,
  diffRules ,
  diffIntegration ,
  diffIntegrations ,
  diffGroupType ,
  diffGroupTypes ,
  diffGlobalRequirements ,
  diff ,
}
/* AvoConfig Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as JsDiff from "../externals/JsDiff.mjs";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import * as Belt_Set from "rescript/lib/es6/belt_Set.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "../ModelStore.mjs";
import * as ModelUtils from "../ModelUtils.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as IconChevron from "../IconChevron.mjs";
import * as ImportUtils from "../ImportUtils.mjs";
import * as PropertyUtils from "../PropertyUtils.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as IconCheckmarkCircle from "../IconCheckmarkCircle.mjs";
import * as ImportParserFiltering from "./ImportParserFiltering.mjs";
import * as GetAllPropertyValuesUseCase from "../../../model/src/eventSpecificPropertyValues/GetAllPropertyValuesUseCase.mjs";

var rootStyles = Curry._1(Css.style, {
      hd: Css.cursor("pointer"),
      tl: {
        hd: Css.hover({
              hd: Css.backgroundColor(Styles.Color.light01),
              tl: /* [] */0
            }),
        tl: {
          hd: Css.transition({
                NAME: "ms",
                VAL: Styles.Duration.$$short
              }, undefined, undefined, "background-color"),
          tl: {
            hd: Css.selector(" td", {
                  hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
                  tl: /* [] */0
                }),
            tl: /* [] */0
          }
        }
      }
    });

var checkboxStyles = Curry._1(Css.style, {
      hd: Css.margin(Css.px(0)),
      tl: {
        hd: Css.cursor("pointer"),
        tl: {
          hd: Css.display("block"),
          tl: /* [] */0
        }
      }
    });

var propertyInfoStyles = Curry._1(Css.style, {
      hd: Css.minWidth(Css.px(0)),
      tl: /* [] */0
    });

var propertyTopStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.marginBottom(Css.px(2)),
          tl: /* [] */0
        }
      }
    });

var propertyTypeStyles = Curry._1(Css.style, {
      hd: Css.margin(Css.px(0)),
      tl: {
        hd: Css.fontFamily(Styles.FontFamily.polar),
        tl: {
          hd: Css.fontWeight(Styles.FontWeight.medium),
          tl: {
            hd: Css.color(Styles.Color.light10),
            tl: {
              hd: Css.fontSize(Styles.FontSize.tiny),
              tl: {
                hd: Css.backgroundColor(Styles.Color.light02),
                tl: {
                  hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                  tl: {
                    hd: Css.borderRadius(Css.px(5)),
                    tl: {
                      hd: Css.padding4(Css.px(2), Css.px(4), Css.px(2), Css.px(4)),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var propertyMatchesStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignSelf("stretch"),
        tl: {
          hd: Css.flexDirection("row"),
          tl: {
            hd: Css.alignItems("center"),
            tl: {
              hd: Css.flexShrink(10000.0),
              tl: {
                hd: Css.minWidth(Css.px(1)),
                tl: {
                  hd: Css.maxWidth(Css.px(500)),
                  tl: {
                    hd: Css.marginTop(Css.px(8)),
                    tl: {
                      hd: Css.marginBottom(Css.px(8)),
                      tl: {
                        hd: Css.marginLeft(Css.px(12)),
                        tl: {
                          hd: Css.marginRight(Css.px(8)),
                          tl: {
                            hd: Css.paddingLeft(Css.px(12)),
                            tl: {
                              hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.light04),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var propertyMatchStyles = Curry._1(Css.style, {
      hd: Css.fontFamily(Styles.FontFamily.polar),
      tl: {
        hd: Css.fontWeight(Styles.FontWeight.medium),
        tl: /* [] */0
      }
    });

var cellStyles = Curry._1(Css.style, {
      hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.light04),
      tl: {
        hd: Css.paddingRight(Css.px(12)),
        tl: {
          hd: Css.paddingLeft(Css.px(12)),
          tl: /* [] */0
        }
      }
    });

function ImportReviewRow$NoProperties(Props) {
  return React.createElement("tr", {
              className: Curry._1(Css.style, {
                    hd: Css.backgroundColor(Styles.Color.light02),
                    tl: {
                      hd: Css.selector(" td", {
                            hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
                            tl: /* [] */0
                          }),
                      tl: /* [] */0
                    }
                  })
            }, React.createElement("td", {
                  className: Curry._1(Css.style, {
                        hd: Css.padding(Css.px(12)),
                        tl: /* [] */0
                      })
                }, React.createElement("input", {
                      className: Curry._1(Css.style, {
                            hd: Css.margin(Css.px(0)),
                            tl: {
                              hd: Css.cursor("pointer"),
                              tl: {
                                hd: Css.display("block"),
                                tl: /* [] */0
                              }
                            }
                          }),
                      checked: false,
                      disabled: true,
                      type: "checkbox"
                    })), React.createElement("td", {
                  className: Curry._1(Css.style, {
                        hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.light04),
                        tl: /* [] */0
                      })
                }), React.createElement("td", {
                  colSpan: 2
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: /* [] */0
                            }
                          })
                    }, React.createElement("div", {
                          className: propertyInfoStyles
                        }, React.createElement("div", {
                              className: propertyTopStyles
                            }, React.createElement($$Text.make, {
                                  element: "Em",
                                  size: "Small",
                                  weight: "Regular",
                                  singleLine: true,
                                  color: Styles.Color.light10,
                                  children: "No properties"
                                }))))), React.createElement("td", {
                  className: cellStyles
                }));
}

var NoProperties = {
  make: ImportReviewRow$NoProperties
};

function ImportReviewRow$Property(Props) {
  var eventId = Props.eventId;
  var property = Props.property;
  var propertyIncluded = Props.propertyIncluded;
  var disabled = Props.disabled;
  var onToggle = Props.onToggle;
  var hasCategories = Props.hasCategories;
  var hasTags = Props.hasTags;
  var hasNameMapping = Props.hasNameMapping;
  var sources = Props.sources;
  var existingPropertyOpt = Props.existingProperty;
  var propertyGroup = Props.propertyGroup;
  var pinnedValueOpt = Props.pinnedValue;
  var maybeModelProperty = Props.maybeModelProperty;
  var importMethod = Props.importMethod;
  var existingProperty = existingPropertyOpt !== undefined ? existingPropertyOpt : false;
  var pinnedValue = pinnedValueOpt !== undefined ? Caml_option.valFromOption(pinnedValueOpt) : undefined;
  var model = ModelStore.useModel(undefined);
  var propertyHasUpdates = Belt_Option.mapWithDefault(maybeModelProperty, false, (function (modelProperty) {
          return ImportParserFiltering.Utils.propertyHasUpdates(importMethod, property, modelProperty, model);
        }));
  var tmp;
  var exit = 0;
  if (maybeModelProperty !== undefined && (maybeModelProperty.type_ !== property.type_ || maybeModelProperty.list !== property.list)) {
    tmp = JsDiff.renderStringDiff(undefined, true, true, "" + (
          maybeModelProperty.list ? "list of " : ""
        ) + " " + maybeModelProperty.type_ + "", "" + (
          property.list ? "list of " : ""
        ) + " " + property.type_ + "");
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = "" + (
      property.list ? "list of " : ""
    ) + " " + property.type_ + "";
  }
  var match = Belt_MapString.get(property.presence, eventId);
  var matches = property.matches;
  var tmp$1;
  if (hasNameMapping) {
    var match$1 = property.nameMapping;
    var tmp$2;
    if (match$1 !== undefined) {
      var maybeModelDestination = ImportUtils.getDestinationForNameMapping(model, match$1[1]);
      tmp$2 = React.createElement($$Text.make, {
            size: "Tiny",
            singleLine: true,
            color: Styles.Color.light10,
            children: "Send as \"" + match$1[0] + "\" to " + Belt_Option.mapWithDefault(maybeModelDestination, "Unknown Destination", ModelUtils.getDestinationName) + ""
          });
    } else {
      tmp$2 = "N/A";
    }
    tmp$1 = React.createElement("td", {
          className: cellStyles
        }, tmp$2);
  } else {
    tmp$1 = null;
  }
  return React.createElement("tr", {
              className: Curry._1(Css.style, {
                    hd: Css.backgroundColor(Styles.Color.light02),
                    tl: {
                      hd: Css.selector(" td", {
                            hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
                            tl: /* [] */0
                          }),
                      tl: /* [] */0
                    }
                  })
            }, React.createElement("td", {
                  className: Curry._1(Css.style, {
                        hd: Css.padding(Css.px(12)),
                        tl: /* [] */0
                      })
                }, React.createElement("input", {
                      className: Curry._1(Css.style, {
                            hd: Css.margin(Css.px(0)),
                            tl: {
                              hd: Css.cursor("pointer"),
                              tl: {
                                hd: Css.display("block"),
                                tl: /* [] */0
                              }
                            }
                          }),
                      checked: propertyIncluded,
                      disabled: disabled || Belt_Option.isSome(propertyGroup),
                      type: "checkbox",
                      onChange: onToggle
                    })), React.createElement("td", {
                  className: Curry._1(Css.style, {
                        hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.light04),
                        tl: /* [] */0
                      })
                }), React.createElement("td", undefined, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: /* [] */0
                            }
                          })
                    }, React.createElement("div", {
                          className: propertyInfoStyles
                        }, React.createElement("div", {
                              className: propertyTopStyles
                            }, propertyGroup !== undefined ? React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                                        size: "Small",
                                        weight: "Semi",
                                        singleLine: true,
                                        color: Styles.Color.light10,
                                        children: "[" + propertyGroup.name + "]"
                                      }), React.createElement(Spacer.make, {
                                        width: 8
                                      })) : null, React.createElement($$Text.make, {
                                  size: "Small",
                                  weight: "Semi",
                                  singleLine: true,
                                  color: Styles.Color.light10,
                                  children: property.name
                                }), React.createElement(Spacer.make, {
                                  width: 8
                                }), React.createElement("p", {
                                  className: propertyTypeStyles
                                }, tmp), React.createElement(Spacer.make, {
                                  width: 4
                                }), React.createElement($$Text.make, {
                                  size: "Tiny",
                                  color: Styles.Color.light10,
                                  children: match !== undefined && match ? "Sometimes sent" : "Always sent"
                                }), React.createElement(Spacer.make, {
                                  width: 4
                                }))), pinnedValue !== undefined ? React.createElement("div", {
                            className: propertyMatchesStyles
                          }, React.createElement($$Text.make, {
                                size: "Tiny",
                                singleLine: true,
                                color: Styles.Color.light10,
                                children: null
                              }, "pinned to ", React.createElement("span", {
                                    className: propertyMatchStyles
                                  }, PropertyUtils.printPinnedValue(pinnedValue)))) : null, matches.length !== 0 ? React.createElement("div", {
                            className: propertyMatchesStyles
                          }, React.createElement($$Text.make, {
                                size: "Tiny",
                                singleLine: true,
                                color: Styles.Color.light10,
                                children: null
                              }, "matches ", Belt_Array.mapWithIndexU(matches, (function (index, match_) {
                                      var match = (matches.length - index | 0) - 1 | 0;
                                      return React.createElement(React.Fragment, {
                                                  children: null,
                                                  key: match_
                                                }, React.createElement("span", {
                                                      className: propertyMatchStyles
                                                    }, match_), match !== 0 ? (
                                                    match !== 1 ? ", " : " or "
                                                  ) : null);
                                    })))) : null, propertyHasUpdates ? React.createElement("div", {
                            className: propertyMatchesStyles
                          }, React.createElement($$Text.make, {
                                size: "Tiny",
                                weight: "Medium",
                                singleLine: true,
                                color: Styles.Color.orange,
                                children: "Updated"
                              })) : (
                        existingProperty ? React.createElement("div", {
                                className: propertyMatchesStyles
                              }, React.createElement($$Text.make, {
                                    size: "Tiny",
                                    weight: "Medium",
                                    singleLine: true,
                                    color: Styles.Color.green,
                                    children: "Reused from Tracking Plan "
                                  })) : null
                      ))), tmp$1, React.createElement("td", {
                  className: cellStyles
                }, React.createElement($$Text.make, {
                      size: "Tiny",
                      singleLine: true,
                      color: Styles.Color.light10,
                      children: maybeModelProperty !== undefined && maybeModelProperty.description !== property.description ? JsDiff.renderStringDiff(undefined, true, true, maybeModelProperty.description, property.description) : property.description
                    })), React.createElement("td", {
                  colSpan: ((
                      hasCategories ? 1 : 0
                    ) + (
                      hasTags ? 1 : 0
                    ) | 0) + sources.length | 0
                }), React.createElement("td", {
                  className: cellStyles
                }, Belt_Array.mapU(property.warnings, (function (warning) {
                        if (typeof warning !== "object") {
                          return null;
                        }
                        if (warning.NAME !== "message") {
                          return null;
                        }
                        var warning$1 = warning.VAL;
                        return React.createElement($$Text.make, {
                                    size: "Tiny",
                                    weight: "Semi",
                                    color: Styles.Color.orange,
                                    children: warning$1,
                                    key: warning$1
                                  });
                      }))));
}

var Property = {
  make: ImportReviewRow$Property
};

function ImportReviewRow$Event(Props) {
  var $$event = Props.event;
  var included = Props.included;
  var properties = Props.properties;
  var propertyGroups = Props.propertyGroups;
  var propertyGroupProperties = Props.propertyGroupProperties;
  var onToggle = Props.onToggle;
  var excludedProperties = Props.excludedProperties;
  var sources = Props.sources;
  var hasStakeholderDomains = Props.hasStakeholderDomains;
  var hasCategories = Props.hasCategories;
  var hasTags = Props.hasTags;
  var hasNameMapping = Props.hasNameMapping;
  var updatedEventOpt = Props.updatedEvent;
  var onPropertyToggle = Props.onPropertyToggle;
  var model = Props.model;
  var importMethod = Props.importMethod;
  var updatedEvent = updatedEventOpt !== undefined ? updatedEventOpt : false;
  var match = React.useState(function () {
        return false;
      });
  var setExpanded = match[1];
  var expanded = match[0];
  var propertiesWarningCount = Belt_Array.reduceU(properties, 0, (function (sum, property) {
          return sum + (
                  property.warnings.length !== 0 ? 1 : 0
                ) | 0;
        }));
  var maybeModelEvent = updatedEvent ? ModelUtils.getEventById($$event.id, model) : undefined;
  return React.createElement(React.Fragment, undefined, React.createElement("tr", {
                  className: rootStyles,
                  onClick: (function (param) {
                      Curry._1(setExpanded, (function (expanded) {
                              return !expanded;
                            }));
                    })
                }, React.createElement("td", {
                      className: Curry._1(Css.style, {
                            hd: Css.padding(Css.px(12)),
                            tl: /* [] */0
                          })
                    }, React.createElement("input", {
                          className: checkboxStyles,
                          checked: included,
                          type: "checkbox",
                          onChange: Curry._2(onToggle, $$event.id, $$event.name),
                          onClick: (function (prim) {
                              prim.stopPropagation();
                            })
                        })), React.createElement("td", {
                      className: cellStyles
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.transform(Css.rotate(Css.deg(expanded ? 0.0 : -90.0))),
                                tl: /* [] */0
                              })
                        }, React.createElement(IconChevron.make, {
                              color: Styles.Color.light08
                            }))), React.createElement("td", {
                      className: Curry._1(Css.style, {
                            hd: Css.maxWidth(Css.px(200)),
                            tl: {
                              hd: Css.width(Css.pct(20.0)),
                              tl: {
                                hd: Css.paddingRight(Css.px(12)),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: /* [] */0
                                }
                              })
                        }, React.createElement($$Text.make, {
                              size: "Medium",
                              weight: "Semi",
                              singleLine: true,
                              color: included ? Styles.Color.light12 : Styles.Color.light08,
                              children: $$event.name
                            }), updatedEvent ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                                    width: 8
                                  }), React.createElement($$Text.make, {
                                    size: "Tiny",
                                    weight: "Semi",
                                    singleLine: true,
                                    color: included ? Styles.Color.orange : Styles.Color.light08,
                                    children: "Updated"
                                  })) : null)), hasNameMapping ? React.createElement("td", {
                        className: Curry._1(Css.merge, {
                              hd: cellStyles,
                              tl: {
                                hd: Curry._1(Css.style, {
                                      hd: Css.minWidth(Css.px(250)),
                                      tl: /* [] */0
                                    }),
                                tl: /* [] */0
                              }
                            })
                      }, React.createElement($$Text.make, {
                            size: "Tiny",
                            color: Styles.Color.light11,
                            children: Belt_Array.map($$event.nameMapping, (function (param) {
                                      var maybeModelDestination = ImportUtils.getDestinationForNameMapping(model, param[1]);
                                      return "Send as \"" + param[0] + "\" to " + Belt_Option.mapWithDefault(maybeModelDestination, "Unknown Destination", ModelUtils.getDestinationName) + "";
                                    })).join(", ")
                          })) : null, React.createElement("td", {
                      className: Curry._1(Css.style, {
                            hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.light04),
                            tl: {
                              hd: Css.maxWidth(Css.px(300)),
                              tl: {
                                hd: Css.width(Css.pct(30.0)),
                                tl: {
                                  hd: Css.paddingRight(Css.px(12)),
                                  tl: {
                                    hd: Css.paddingLeft(Css.px(12)),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          })
                    }, React.createElement($$Text.make, {
                          size: "Tiny",
                          singleLine: true,
                          color: Styles.Color.light11,
                          children: maybeModelEvent !== undefined && maybeModelEvent.description !== $$event.description ? JsDiff.renderStringDiff(undefined, true, true, maybeModelEvent.description, $$event.description) : $$event.description
                        })), hasCategories ? React.createElement("td", {
                        className: cellStyles
                      }, React.createElement($$Text.make, {
                            size: "Tiny",
                            color: Styles.Color.light11,
                            children: $$event.categories.join(",")
                          })) : null, hasTags ? React.createElement("td", {
                        className: cellStyles
                      }, React.createElement($$Text.make, {
                            size: "Tiny",
                            color: Styles.Color.light11,
                            children: $$event.tags.join(",")
                          })) : null, hasStakeholderDomains ? React.createElement("td", {
                        className: cellStyles
                      }, React.createElement($$Text.make, {
                            size: "Tiny",
                            color: Styles.Color.light11,
                            children: $$event.stakeholderDomains.join(",")
                          })) : null, Belt_Array.map(sources, (function (source) {
                        return React.createElement("td", {
                                    key: source,
                                    className: cellStyles
                                  }, Belt_Array.some($$event.sources, (function (eventSource) {
                                          return eventSource === source;
                                        })) ? React.createElement("div", {
                                          className: Curry._1(Css.style, {
                                                hd: Css.display("flex"),
                                                tl: {
                                                  hd: Css.alignItems("center"),
                                                  tl: {
                                                    hd: Css.justifyContent("center"),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              })
                                        }, React.createElement(IconCheckmarkCircle.make, {
                                              size: 12,
                                              color: Styles.Color.green
                                            })) : null);
                      })), React.createElement("td", {
                      className: cellStyles
                    }, propertiesWarningCount > 0 ? React.createElement($$Text.make, {
                            size: "Tiny",
                            weight: "Semi",
                            color: Styles.Color.orange,
                            children: propertiesWarningCount !== 1 ? "" + propertiesWarningCount.toString() + " properties with warnings" : "1 property with warnings",
                            key: "propertyWarningCount"
                          }) : null, Belt_Array.mapU($$event.warnings, (function (warning) {
                            return React.createElement($$Text.make, {
                                        size: "Tiny",
                                        weight: "Semi",
                                        color: Styles.Color.orange,
                                        children: warning,
                                        key: warning
                                      });
                          })))), expanded ? (
                $$event.propertyIds.length !== 0 || $$event.propertyBundleIds.length !== 0 || $$event.updatedPropertyIds.length !== 0 ? React.createElement(React.Fragment, undefined, Belt_Array.concatMany(Belt_Array.mapU(propertyGroups, (function (group) {
                                  return Belt_Array.mapU(Belt_Array.keepMapU(group.propertiesUsedOnlyInThisPropertyBundle, (function (property) {
                                                    return Belt_Array.getByU(propertyGroupProperties, (function (groupProperty) {
                                                                  return groupProperty.id === property.id;
                                                                }));
                                                  })), (function (property) {
                                                return React.createElement(ImportReviewRow$Property, {
                                                            eventId: $$event.id,
                                                            property: property,
                                                            propertyIncluded: !Belt_Set.has(excludedProperties, [
                                                                  $$event.id,
                                                                  property.id
                                                                ]),
                                                            disabled: !included,
                                                            onToggle: (function (inputEvent) {
                                                                Curry._5(onPropertyToggle, $$event.id, $$event.name, property.id, property.name, inputEvent);
                                                              }),
                                                            hasCategories: hasCategories,
                                                            hasTags: hasTags,
                                                            hasNameMapping: hasNameMapping,
                                                            sources: sources,
                                                            propertyGroup: group,
                                                            importMethod: importMethod,
                                                            key: property.id
                                                          });
                                              }));
                                }))), Belt_Array.keepMapU(Belt_Array.concat($$event.propertyIds, $$event.updatedPropertyIds), (function (param) {
                              var pinnedValue = param[1];
                              var propertyId = param[0];
                              var maybeImportedProperty = Belt_Array.getByU(properties, (function (property) {
                                      return property.id === propertyId;
                                    }));
                              var maybeModelProperty = ModelUtils.getPropertyById(propertyId, model);
                              var property;
                              if (maybeImportedProperty !== undefined) {
                                if (maybeModelProperty !== undefined) {
                                  if (maybeModelProperty.TAG !== /* PropertyRef */0) {
                                    return Caml_option.some(React.createElement(ImportReviewRow$Property, {
                                                    eventId: $$event.id,
                                                    property: maybeImportedProperty,
                                                    propertyIncluded: !Belt_Set.has(excludedProperties, [
                                                          $$event.id,
                                                          maybeImportedProperty.id
                                                        ]),
                                                    disabled: !included,
                                                    onToggle: (function (inputEvent) {
                                                        Curry._5(onPropertyToggle, $$event.id, $$event.name, maybeImportedProperty.id, maybeImportedProperty.name, inputEvent);
                                                      }),
                                                    hasCategories: hasCategories,
                                                    hasTags: hasTags,
                                                    hasNameMapping: hasNameMapping,
                                                    sources: sources,
                                                    existingProperty: true,
                                                    pinnedValue: pinnedValue,
                                                    maybeModelProperty: maybeModelProperty._0,
                                                    importMethod: importMethod,
                                                    key: maybeImportedProperty.id
                                                  }));
                                  }
                                  property = maybeImportedProperty;
                                } else {
                                  property = maybeImportedProperty;
                                }
                              } else {
                                if (maybeModelProperty === undefined) {
                                  return ;
                                }
                                if (maybeModelProperty.TAG === /* PropertyRef */0) {
                                  return ;
                                }
                                var property$1 = maybeModelProperty._0;
                                var allowedValues = GetAllPropertyValuesUseCase.getWithLegacyFallback(ModelUtils.hasMigrated(model, "EventSpecificAllowedPropertyValues"), property$1);
                                return Caml_option.some(React.createElement(ImportReviewRow$Property, {
                                                eventId: $$event.id,
                                                property: {
                                                  id: property$1.id,
                                                  name: property$1.name,
                                                  uniqueNameIndex: undefined,
                                                  description: property$1.description,
                                                  type_: property$1.type_,
                                                  list: property$1.list,
                                                  matches: allowedValues,
                                                  warnings: [],
                                                  presence: undefined,
                                                  sendAs: property$1.sendAs,
                                                  nameMapping: undefined
                                                },
                                                propertyIncluded: !Belt_Set.has(excludedProperties, [
                                                      $$event.id,
                                                      property$1.id
                                                    ]),
                                                disabled: !included,
                                                onToggle: (function (inputEvent) {
                                                    Curry._5(onPropertyToggle, $$event.id, $$event.name, property$1.id, property$1.name, inputEvent);
                                                  }),
                                                hasCategories: hasCategories,
                                                hasTags: hasTags,
                                                hasNameMapping: hasNameMapping,
                                                sources: sources,
                                                existingProperty: true,
                                                importMethod: importMethod,
                                                key: property$1.id
                                              }));
                              }
                              return Caml_option.some(React.createElement(ImportReviewRow$Property, {
                                              eventId: $$event.id,
                                              property: property,
                                              propertyIncluded: !Belt_Set.has(excludedProperties, [
                                                    $$event.id,
                                                    property.id
                                                  ]),
                                              disabled: !included,
                                              onToggle: (function (inputEvent) {
                                                  Curry._5(onPropertyToggle, $$event.id, $$event.name, property.id, property.name, inputEvent);
                                                }),
                                              hasCategories: hasCategories,
                                              hasTags: hasTags,
                                              hasNameMapping: hasNameMapping,
                                              sources: sources,
                                              pinnedValue: pinnedValue,
                                              importMethod: importMethod,
                                              key: property.id
                                            }));
                            }))) : React.createElement(ImportReviewRow$NoProperties, {})
              ) : null);
}

var $$Event = {
  make: ImportReviewRow$Event
};

function ImportReviewRow$UserOrSystemProperties(Props) {
  var properties = Props.properties;
  var excludedProperties = Props.excludedProperties;
  var onPropertyToggle = Props.onPropertyToggle;
  var onPropertiesIncludedToggle = Props.onPropertiesIncludedToggle;
  var propertiesIncluded = Props.propertiesIncluded;
  var importMethod = Props.importMethod;
  var propertySendAs = Props.propertySendAs;
  var match = React.useState(function () {
        return false;
      });
  var setExpanded = match[1];
  var expanded = match[0];
  var propertySendAsName;
  if (typeof propertySendAs === "number") {
    switch (propertySendAs) {
      case /* SystemProperty */0 :
          propertySendAsName = "System";
          break;
      case /* EventProperty */1 :
          propertySendAsName = "Unsupported";
          break;
      case /* UserProperty */2 :
          propertySendAsName = "User";
          break;
      
    }
  } else {
    propertySendAsName = "Unsupported";
  }
  var propertiesWarningCount = Belt_Array.reduceU(properties, 0, (function (sum, property) {
          return sum + (
                  property.warnings.length !== 0 ? 1 : 0
                ) | 0;
        }));
  var numSelectedUserProperties = propertiesIncluded ? Belt_Array.keep(properties, (function (property) {
            return !Belt_SetString.has(excludedProperties, property.id);
          })).length : 0;
  return React.createElement(React.Fragment, undefined, React.createElement("tr", {
                  className: rootStyles,
                  onClick: (function (param) {
                      Curry._1(setExpanded, (function (expanded) {
                              return !expanded;
                            }));
                    })
                }, React.createElement("td", {
                      className: Curry._1(Css.style, {
                            hd: Css.padding(Css.px(12)),
                            tl: /* [] */0
                          })
                    }, React.createElement("input", {
                          className: checkboxStyles,
                          checked: propertiesIncluded,
                          type: "checkbox",
                          onChange: onPropertiesIncludedToggle,
                          onClick: (function (prim) {
                              prim.stopPropagation();
                            })
                        })), React.createElement("td", {
                      className: cellStyles
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.transform(Css.rotate(Css.deg(expanded ? 0.0 : -90.0))),
                                tl: /* [] */0
                              })
                        }, React.createElement(IconChevron.make, {
                              color: Styles.Color.light08
                            }))), React.createElement("td", {
                      className: Curry._1(Css.style, {
                            hd: Css.maxWidth(Css.px(200)),
                            tl: {
                              hd: Css.width(Css.pct(20.0)),
                              tl: {
                                hd: Css.paddingRight(Css.px(12)),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Semi",
                          singleLine: true,
                          color: propertiesIncluded ? Styles.Color.light12 : Styles.Color.light08,
                          children: "" + propertySendAsName + " Properties (" + numSelectedUserProperties.toString() + ")"
                        })), React.createElement("td", {
                      className: Curry._1(Css.style, {
                            hd: Css.maxWidth(Css.px(300)),
                            tl: {
                              hd: Css.width(Css.pct(30.0)),
                              tl: {
                                hd: Css.paddingRight(Css.px(12)),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, React.createElement($$Text.make, {
                          size: "Tiny",
                          singleLine: true,
                          color: Styles.Color.light11,
                          children: "The following " + propertySendAsName + " properties were found in the import."
                        })), React.createElement("td", {
                      className: cellStyles
                    }, propertiesWarningCount > 0 ? React.createElement($$Text.make, {
                            size: "Tiny",
                            weight: "Semi",
                            color: Styles.Color.orange,
                            children: propertiesWarningCount !== 1 ? "" + propertiesWarningCount.toString() + " properties with warnings" : "1 property with warnings",
                            key: "propertyWarningCount"
                          }) : null)), expanded ? Belt_Array.mapU(properties, (function (property) {
                      return React.createElement(ImportReviewRow$Property, {
                                  eventId: "",
                                  property: property,
                                  propertyIncluded: !Belt_SetString.has(excludedProperties, property.id),
                                  disabled: !propertiesIncluded,
                                  onToggle: (function (inputEvent) {
                                      Curry._3(onPropertyToggle, property.id, property.name, inputEvent);
                                    }),
                                  hasCategories: false,
                                  hasTags: false,
                                  hasNameMapping: false,
                                  sources: [],
                                  importMethod: importMethod,
                                  key: property.id
                                });
                    })) : null);
}

var UserOrSystemProperties = {
  make: ImportReviewRow$UserOrSystemProperties
};

function ImportReviewRow$EventPropertiesWithoutEvents(Props) {
  var properties = Props.properties;
  var importMethod = Props.importMethod;
  var match = React.useState(function () {
        return false;
      });
  var setExpanded = match[1];
  var expanded = match[0];
  var propertiesWarningCount = Belt_Array.reduceU(properties, 0, (function (sum, property) {
          return sum + (
                  property.warnings.length !== 0 ? 1 : 0
                ) | 0;
        }));
  var numSelectedUserProperties = properties.length;
  return React.createElement(React.Fragment, undefined, React.createElement("tr", {
                  className: rootStyles,
                  onClick: (function (param) {
                      Curry._1(setExpanded, (function (expanded) {
                              return !expanded;
                            }));
                    })
                }, React.createElement("td", {
                      className: Curry._1(Css.style, {
                            hd: Css.padding(Css.px(12)),
                            tl: /* [] */0
                          })
                    }, React.createElement("input", {
                          className: checkboxStyles,
                          checked: true,
                          disabled: true,
                          type: "checkbox",
                          onClick: (function (prim) {
                              prim.stopPropagation();
                            })
                        })), React.createElement("td", {
                      className: cellStyles
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.transform(Css.rotate(Css.deg(expanded ? 0.0 : -90.0))),
                                tl: /* [] */0
                              })
                        }, React.createElement(IconChevron.make, {
                              color: Styles.Color.light08
                            }))), React.createElement("td", {
                      className: Curry._1(Css.style, {
                            hd: Css.maxWidth(Css.px(200)),
                            tl: {
                              hd: Css.width(Css.pct(20.0)),
                              tl: {
                                hd: Css.paddingRight(Css.px(12)),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Semi",
                          singleLine: true,
                          color: Styles.Color.light12,
                          children: "Event Properties (" + numSelectedUserProperties.toString() + ")"
                        })), React.createElement("td", {
                      className: Curry._1(Css.style, {
                            hd: Css.maxWidth(Css.px(300)),
                            tl: {
                              hd: Css.width(Css.pct(30.0)),
                              tl: {
                                hd: Css.paddingRight(Css.px(12)),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, React.createElement($$Text.make, {
                          size: "Tiny",
                          singleLine: true,
                          color: Styles.Color.light11,
                          children: "The following event properties were found in the import but are not attached to any events."
                        })), React.createElement("td", {
                      className: cellStyles
                    }, propertiesWarningCount > 0 ? React.createElement($$Text.make, {
                            size: "Tiny",
                            weight: "Semi",
                            color: Styles.Color.orange,
                            children: propertiesWarningCount !== 1 ? "" + propertiesWarningCount.toString() + " properties with warnings" : "1 property with warnings",
                            key: "propertyWarningCount"
                          }) : null)), expanded ? Belt_Array.mapU(properties, (function (property) {
                      return React.createElement(ImportReviewRow$Property, {
                                  eventId: "",
                                  property: property,
                                  propertyIncluded: true,
                                  disabled: true,
                                  onToggle: (function (_inputEvent) {
                                      
                                    }),
                                  hasCategories: false,
                                  hasTags: false,
                                  hasNameMapping: false,
                                  sources: [],
                                  importMethod: importMethod,
                                  key: property.id
                                });
                    })) : null);
}

var EventPropertiesWithoutEvents = {
  make: ImportReviewRow$EventPropertiesWithoutEvents
};

export {
  rootStyles ,
  checkboxStyles ,
  propertyInfoStyles ,
  propertyTopStyles ,
  propertyTypeStyles ,
  propertyMatchesStyles ,
  propertyMatchStyles ,
  cellStyles ,
  NoProperties ,
  Property ,
  $$Event ,
  UserOrSystemProperties ,
  EventPropertiesWithoutEvents ,
}
/* rootStyles Not a pure module */
